import React from "react";
import { Box, HStack, Text, SimpleGrid, Flex, VStack } from "@chakra-ui/react";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiCheckCircle, BiCalendarStar, BiLoaderCircle } from "react-icons/bi";
import { GiTrafficLightsReadyToGo } from "react-icons/gi";
import { CgCalendarDue } from "react-icons/cg";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoAnalyticsOutline } from "react-icons/io5";
import { GiProfit, GiSandsOfTime } from "react-icons/gi";
import { BiUserPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import { DashboardProps } from "../../interfaces";
import BarChart from "./BarChart";
import Card from "./Card";
import CardSkeleton from "./CardSkeleton";
import { CButton, DatePicker } from "..";

export default function Dashboard({
  data,
  isLoading,
  clearFilters,
  endDate,
  startDate,
  onFilterSubmit,
  handleOnDataPicker,
  fetchAllTimeStats,
  stateLoading,
  allTimeLoading,
}: DashboardProps) {
  return (
    <Box>
      <Text
        fontWeight="500"
        mt="20px"
        fontStyle="normal"
        fontSize="30px"
        color="#393330"
      >
        Dashboard
      </Text>
      <Flex w="full" justify="flex-end">
        <DatePicker
          toDate={endDate}
          fromDate={startDate}
          handleOnDataPicker={handleOnDataPicker}
        />
        <CButton
          onClick={() => fetchAllTimeStats()}
          id="filterbutton"
          variant="solid"
          h="34px"
          w="28"
          ml="3px"
          fontSize="16px"
          bgColor="brand.primary.main"
          border="1px solid rgb(255, 130, 76)"
          color="#fff"
          _focus={{
            offset: "0",
          }}
          isLoading={isLoading && allTimeLoading && !stateLoading}
        >
          <Text fontWeight="light">All Time</Text>
        </CButton>
        <CButton
          mx="2"
          onClick={() => clearFilters()}
          id="filterClearbutton"
          variant="solid"
          h="34px"
          w="28"
          fontSize="16px"
          bgColor="#fff"
          border="1px solid rgb(255, 130, 76)"
          color="rgb(255, 130, 76)"
          _focus={{
            offset: "0",
          }}
        >
          <Text fontWeight="light">Clear</Text>
        </CButton>
        <CButton
          onClick={() => onFilterSubmit()}
          id="filterbutton"
          variant="solid"
          h="34px"
          w="28"
          fontSize="16px"
          bgColor="brand.primary.main"
          border="1px solid rgb(255, 130, 76)"
          color="#fff"
          _focus={{
            offset: "0",
          }}
          isLoading={isLoading && stateLoading}
        >
          <Text fontWeight="light">Filter</Text>
        </CButton>
      </Flex>
      {isLoading ? (
        <VStack w="full" spacing="4" py="8">
          <SimpleGrid columns={4} spacing={6} w="full">
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </SimpleGrid>
          <Box w="full">{data && <BarChart {...data} />}</Box>
        </VStack>
      ) : (
        <VStack w="full" spacing="6" py="8">
          <Box w="full">
            <SimpleGrid columns={4} spacing={6}>
              <Card
                icon={<AiOutlineUsergroupAdd size={30} />}
                value={data?.leadTraced}
                title="Total Traced Leads "
              />

              <Link to="/fulfilled-orders">
                <Card
                  icon={<BiCheckCircle size={30} />}
                  value={data?.fulfilledOrders}
                  title="Fulfilled Orders"
                />
              </Link>

              <Card
                icon={<GiTrafficLightsReadyToGo size={30} />}
                value={data?.pendingOrders}
                title="Pending Orders"
              />

              <Card
                icon={<CgCalendarDue size={30} />}
                value={data?.almostDueOrders}
                title="Almost Past Due Orders"
              />

              <Card
                icon={<BiCalendarStar size={28} />}
                value={data?.ordersPastDue}
                title="Orders Past Due"
              />

              <Card
                icon={<AiOutlineExclamationCircle size={28} />}
                value={data?.needAttentionOrders}
                title="Need Attention Orders"
              />

              <Link to="/invoices">
                <Card
                  icon={<IoAnalyticsOutline size={30} />}
                  value={data?.sales}
                  title="Total Sales"
                />
              </Link>

              <Link to="/invoices">
                <Card
                  icon={<GiProfit size={30} />}
                  value={`$${data?.revenueOfSales ? data?.revenueOfSales : 0}`}
                  title="Revenue Generated"
                />
              </Link>

              <Link to="/invoices">
                <Card
                  icon={<GiProfit size={30} />}
                  value={`$${
                    data?.profitGenerated ? data?.profitGenerated : 0
                  }`}
                  title="Profit Generated"
                />
              </Link>

              <Link to="/customers">
                <Card
                  icon={<BiUserPlus size={34} />}
                  value={data?.signups}
                  title="Signups"
                />
              </Link>

              <Card
                icon={<GiProfit size={30} />}
                value={
                  data?.moneyOwedToTracers ? `$${data?.moneyOwedToTracers}` : 0
                }
                title="Money Owed to Tracers"
              />

              <Card
                icon={<BiLoaderCircle size={30} />}
                value={data?.filesBeingProcessed}
                title="Files Being Processed"
              />

              <Link to="/orders-detail">
                <Card
                  icon={<GiSandsOfTime size={34} />}
                  value={`${Math.floor(data?.avgOrdersTime)}h`}
                  title="Average Order Time"
                />
              </Link>
              <Card
                icon={<GiProfit size={30} />}
                value={`%${data?.profitMargin.toFixed(2)}`}
                title="Profit Margin %"
              />
            </SimpleGrid>
          </Box>
          {/* <Box w='sm'> */}
          <Box w="full">{data && <BarChart {...data} />}</Box>
          {/* </Box> */}
        </VStack>
      )}
    </Box>
  );
}
