import {
  Table,
  Tbody,
  Thead,
  Td,
  Tr,
  Th,
  Text,
  Flex,
  Spinner,
  Button,
  HStack,
  Box,
} from "@chakra-ui/react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";
import { Link } from "react-router-dom";
import { DatePicker, CButton } from "..";
import { IAffiliateStats } from "../../interfaces";

const tableHeadings = [
  "Date",
  "Affiliate",
  "Customers Acquired",
  "Link Clicks",
  "Revenue Generated",
  "Profit Generated",
  "Earnings per customer",
  "Commission Owed",
  "Commission Paid",
];

const CAffiliateStats = ({
  stats,
  isLoading,
  endDate,
  handleOnDataPicker,
  onClearFilters,
  startDate,
}: IAffiliateStats) => {
  const formatedData = stats?.map((stat) => {
    return [
      moment(stat.date).format("MM-DD-YYYY"),
      stat.affiliateName,
      stat.customersAcquired,
      stat.linkClicks,
      stat.revenueGenerated,
      stat.profitGenerated,
      stat.earningsPerCustomer,
      stat.commissionOwed,
      stat.commisionPaid,
    ];
  });

  return (
    <div>
      <Text fontWeight="500" fontStyle="normal" fontSize="30px" color="#393330">
        Affiliates Stats
      </Text>
      <HStack pt="5" pb="2" justifyContent="space-between" w="full">
        {formatedData?.length ? (
          <CSVLink
            headers={tableHeadings}
            data={formatedData}
            filename={`affiliate-stats-${moment().format(
              "MMMM Do YYYY, h:mm:ss a"
            )}`}
          >
            <Button
              size="sm"
              rightIcon={<BsDownload />}
              id="downloadBtn"
              colorScheme="orange"
              bgColor="brand.primary.main"
            >
              Download as CSV
            </Button>
          </CSVLink>
        ) : null}
        <HStack justifyContent="flex-end" w="full">
          <DatePicker
            handleOnDataPicker={handleOnDataPicker}
            fromDate={startDate}
            toDate={endDate}
          />
          <CButton
            onClick={onClearFilters}
            id="clearbutton"
            variant="outline"
            size="sm"
            w="28"
            fontSize="16px"
            bgColor="#fff"
            border="1px solid rgb(255, 130, 76)"
            color="rgb(255, 130, 76)"
            _focus={{
              offset: "0",
            }}
          >
            Clear
          </CButton>
        </HStack>
      </HStack>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" minH="44" w="full">
          <Spinner thickness="3px" size="lg" color="brand.primary.main" />
        </Flex>
      ) : (
        <Box overflowX="auto">
          <Table overflow="auto" w="full" mt="30px" mb="30px" variant="simple">
            <Thead>
              <Tr boxShadow="base">
                {tableHeadings.map((heading: string, i: number) => (
                  <Th
                    color="#2F3442"
                    fontWeight="600"
                    textAlign="center"
                    key={i}
                    minWidth="10px"
                  >
                    {heading}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody w="full" mx="auto">
              {stats.map(
                ({
                  affiliateId,
                  date,
                  affiliateName,
                  linkClicks,
                  customersAcquired,
                  revenueGenerated,
                  profitGenerated,
                  earningsPerCustomer,
                  commissionOwed,
                  commisionPaid,
                }) => (
                  <Tr fontSize="14px" color="#737373">
                    <Td textAlign="center" minW="40">
                      {moment(date).format("MM-DD-YYYY")}
                    </Td>
                    <Td textAlign="center" minW="48">
                      {affiliateName}
                    </Td>
                    <Td
                      textAlign="center"
                      color={!affiliateId ? "" : "brand.primary.main"}
                    >
                      {affiliateId ? (
                        <Link to={`/affiliate/${affiliateId}/customers-orders`}>
                          {customersAcquired}
                        </Link>
                      ) : (
                        customersAcquired
                      )}
                    </Td>
                    <Td textAlign="center">{linkClicks}</Td>
                    <Td textAlign="center">${revenueGenerated}</Td>
                    <Td textAlign="center">${profitGenerated}</Td>
                    <Td textAlign="center">${earningsPerCustomer}</Td>
                    <Td textAlign="center">${commissionOwed?.toFixed(2)}</Td>
                    <Td textAlign="center">${commisionPaid?.toFixed(2)}</Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </Box>
      )}
    </div>
  );
};

export default CAffiliateStats;
