import { useQuery, useMutation } from "react-query";
import { API } from "../constants";
import { apiClient } from "../services";

export const useAvgOrdersTime = () => {
  const getAvgOrdersTimes = async () =>
    await apiClient.get(API.ROUTES.ADMIN_AVG_ORDERS_TIME);
  return useQuery("AvgOrdersTime", getAvgOrdersTimes);
};

export const useDisputeOrder = () => {
  const takeOrderSkiptrace = async (body: {
    _id: string;
    isSingle: boolean;
    isResolved: boolean;
  }) => {
    const res = await apiClient.post(API.ROUTES.ADMIN_DISPUTE_ORDER, body);
    return res;
  };
  return useMutation(takeOrderSkiptrace);
};
