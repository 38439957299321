import React from "react";
import moment, { Moment } from "moment";
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spinner,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  IconButton,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { IUser, IPayoutsSkiptracers } from "../../interfaces";
import { MdDelete } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
import { CSVLink } from "react-csv";
import { CButton, DatePicker } from "..";

interface PayoutsSkiptracersProps {
  handleSubmit: Function;
  register: Function;
  errors: any;
  skiptracersData: IUser[];
  payoutsSkiptracersData: IPayoutsSkiptracers[];
  isLoadingPayouts: boolean;
  isLoadingAddPayouts: boolean;
  handleOnDataPicker: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  fromDate: Moment | null;
  toDate: Moment | null;
  onFilterClick: (fromData: Moment | null, toDate: Moment | null) => void;
  onFilterClearClick: () => void;
  deleteLoading: boolean;
  onSetId: (id: string) => void;
  payPeriodStart: Moment | null;
  payPeriodEnd: Moment | null;
  handleOnPayPeriodPicker: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  payoutId: string;
}

const tableHeadings = [
  "Date",
  "Amount",
  "Skiptracer",
  "Actions",
  "INVOICE LINK",
];

const PayoutsSkiptracers = (props: PayoutsSkiptracersProps) => {
  const {
    handleSubmit,
    register,
    errors,
    skiptracersData,
    payoutsSkiptracersData,
    isLoadingPayouts,
    isLoadingAddPayouts,
    handleOnDataPicker,
    fromDate,
    toDate,
    onFilterClick,
    onFilterClearClick,
    onSetId,
    deleteLoading,
    payPeriodStart,
    payPeriodEnd,
    handleOnPayPeriodPicker,
    payoutId,
  } = props;

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    handleSubmit();
  };

  const handleFilterClick = () => {
    onFilterClick(fromDate, toDate);
  };

  const handleClear = () => {
    onFilterClearClick();
  };

  const formatedData = payoutsSkiptracersData?.map((obj) => {
    const data = {
      date: moment(obj.date).format("MM-DD-YYYY"),
      amount: obj.amount,
      skiptracer: obj.skiptracer,
    };
    return Object.values(data);
  });

  return (
    <>
      <Text fontWeight="500" fontStyle="normal" fontSize="30px" color="#393330">
        Payouts Skiptracers
      </Text>

      <Box>
        <HStack w="100%" spacing={10}>
          <VStack w="73%" alignSelf="start" mt="40px">
            <HStack w="full" spacing={2} justifyContent="space-between">
              <Box>
                {formatedData?.length ? (
                  <CSVLink
                    data={formatedData}
                    headers={["Date", "Amount", "Skiptracer"]}
                    filename={`skiptracers-payouts-${moment().format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}`}>
                    <Button
                      size="sm"
                      rightIcon={<BsDownload />}
                      id="downloadBtn"
                      colorScheme="orange"
                      bgColor="brand.primary.main">
                      Download as CSV
                    </Button>
                  </CSVLink>
                ) : null}
              </Box>
              <HStack>
                <DatePicker
                  handleOnDataPicker={handleOnDataPicker}
                  fromDate={fromDate}
                  toDate={toDate}
                />
                <CButton
                  onClick={() => handleClear()}
                  id="filterClearbutton"
                  variant="solid"
                  size="sm"
                  w="28"
                  fontSize="16px"
                  bgColor="#fff"
                  border="1px solid rgb(255, 130, 76)"
                  color="rgb(255, 130, 76)"
                  _focus={{
                    offset: "0",
                  }}>
                  <Text fontWeight="light">Clear</Text>
                </CButton>
                <CButton
                  onClick={handleFilterClick}
                  id="filterbutton"
                  variant="solid"
                  size="sm"
                  w="28"
                  fontSize="16px"
                  bgColor="brand.primary.main"
                  border="1px solid rgb(255, 130, 76)"
                  color="#fff"
                  _focus={{
                    offset: "0",
                  }}
                  isLoading={false}>
                  <Text fontWeight="light">Filter</Text>
                </CButton>
              </HStack>
            </HStack>

            <Box w="100%">
              <Table overflow="auto" my="30px" variant="simple">
                <Thead>
                  <Tr boxShadow="base">
                    {tableHeadings.map((heading, i) => (
                      <Th
                        fontSize="15px"
                        color="#2F3442"
                        fontWeight="600"
                        textAlign="center"
                        key={i}
                        minWidth="10px">
                        {heading}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody w="full">
                  {payoutsSkiptracersData?.map((payouts) => (
                    <Tr fontSize="14px">
                      <Td textAlign="center" color="#737373">
                        {moment(payouts.date).format("MM-DD-YYYY")}
                      </Td>
                      <Td maxW="72" textAlign="center" color="#737373">
                        ${payouts.amount}
                      </Td>
                      <Td maxW="72" textAlign="center" color="#737373">
                        {payouts.skiptracer}
                      </Td>
                      <Td>
                        <HStack justifyContent="center">
                          <Tooltip
                            label="Delete User"
                            bg="red.600"
                            fontSize="xs">
                            <IconButton
                              onClick={() => {
                                onSetId(payouts._id);
                              }}
                              isLoading={
                                deleteLoading && payouts._id === payoutId
                              }
                              id="SLDelete"
                              icon={<MdDelete />}
                              _focus={{
                                offset: "0",
                              }}
                              colorScheme="red"
                              aria-label="reset-icon"
                              fontSize="14px"
                              size="sm"
                            />
                          </Tooltip>
                        </HStack>
                      </Td>
                      <Td textAlign="center">
                        {payouts?.invoiceUrl && (
                          <CButton
                            id="viewOutputFile"
                            variant="solid"
                            fontSize="14px"
                            bgColor="brand.primary.main"
                            border="1px solid rgb(255, 130, 76)"
                            color="#fff"
                            leftIcon={<HiOutlineDocumentDownload />}
                            _focus={{
                              offset: "0",
                            }}>
                            <a
                              href={payouts?.invoiceUrl}
                              target="_blank"
                              rel="noreferrer">
                              <Text fontWeight="light">Download</Text>
                            </a>
                          </CButton>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {isLoadingPayouts && (
                <Flex w="full" justifyContent="center">
                  <Spinner
                    color="brand.primary.main"
                    thickness="2px"
                    size="md"
                  />
                </Flex>
              )}

              {payoutsSkiptracersData?.length === 0 && (
                <Box w="100%" h="300px" color="#737373" fontWeight="400">
                  <Text textAlign="center">No records available</Text>
                </Box>
              )}
            </Box>
          </VStack>
          <HStack w="27%" alignSelf="start">
            <Box w="full" boxShadow="md" rounded="md">
              <form style={{ width: "100%" }} id="formSubmit" onSubmit={submit}>
                <VStack px="4" my="30px" spacing={6}>
                  <FormControl id="amount" isInvalid={errors?.amount}>
                    <FormLabel>Amount</FormLabel>
                    <Input
                      type="number"
                      placeholder="amount"
                      focusBorderColor="brand.primary.light"
                      step=".01"
                      {...register("amount", {
                        required: true,
                      })}
                    />
                    <FormErrorMessage>
                      {errors?.amount && <Text>Amount is required</Text>}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl id="date" isInvalid={errors?.amount}>
                    <FormLabel>Date</FormLabel>
                    <Input
                      type="date"
                      focusBorderColor="brand.primary.light"
                      {...register("date", {
                        required: true,
                      })}
                    />
                    <FormErrorMessage>
                      {errors?.amount && <Text>Amount is required</Text>}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl id="role" isInvalid={errors?.skiptracer}>
                    <FormLabel>Skiptracer</FormLabel>
                    <Select
                      color="gray.400"
                      focusBorderColor="brand.primary.light"
                      py="0.5"
                      placeholder="Select Skiptracer"
                      {...register("skiptracer", {
                        required: true,
                      })}>
                      {skiptracersData?.map((skiptracer: IUser) => (
                        <option value={skiptracer.email}>
                          {skiptracer.email}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {errors?.skiptracer && (
                        <Text>Skiptracer is required</Text>
                      )}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl width={{ lg: "full", sm: "200px" }}>
                    <FormLabel justifyItems="start">Pay Period</FormLabel>

                    <DatePicker
                      handleOnDataPicker={handleOnPayPeriodPicker}
                      fromDate={payPeriodStart}
                      toDate={payPeriodEnd}
                      required={true}
                      noBorder={true}
                    />
                  </FormControl>

                  <CButton
                    variant="solid"
                    id="addTransaction"
                    background="brand.primary.main"
                    border="1px"
                    borderColor="brand.primary.main"
                    color="white"
                    width={{ lg: "full", sm: "200px" }}
                    mt="30px"
                    type="submit"
                    fontSize={14}
                    isLoading={isLoadingAddPayouts}>
                    Add Transaction
                  </CButton>
                </VStack>
              </form>
            </Box>
          </HStack>
        </HStack>
      </Box>
    </>
  );
};

export default PayoutsSkiptracers;
