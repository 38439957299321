import {
  Table,
  Tbody,
  Thead,
  Td,
  Tr,
  Th,
  Text,
  Flex,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { IAffiliateCustomersOrders } from "../../interfaces/affiliate.interface";

const tableHeadings = [
  "name",
  "email",
  "Records",
  "Order Type",
  "Cost",
  "Affiliate Commission",
  "Skiptracer Cost",
];

interface ICAffiliateCustomerOrders {
  isLoading: boolean;
  data: IAffiliateCustomersOrders[];
}

const CAffiliateCustomerOrders = ({
  isLoading,
  data,
}: ICAffiliateCustomerOrders) => {
  return (
    <div>
      <Text fontWeight="500" fontStyle="normal" fontSize="30px" color="#393330">
        Affiliates Customer Orders
      </Text>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" minH="44" w="full">
          <Spinner thickness="3px" size="lg" color="brand.primary.main" />
        </Flex>
      ) : (
        <Box overflowX="auto">
          <Table overflow="auto" w="full" mt="30px" mb="30px" variant="simple">
            <Thead>
              <Tr boxShadow="base">
                {tableHeadings.map((heading: string, i: number) => (
                  <Th
                    color="#2F3442"
                    fontWeight="600"
                    textAlign="center"
                    key={i}
                    minWidth="10px"
                  >
                    {heading}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody w="full" mx="auto">
              {data.map(
                ({
                  cost,
                  affiliateCommission,
                  type,
                  records,
                  skiptracerCost,
                  customer,
                }) => (
                  <Tr fontSize="14px" color="#737373">
                    <Td textAlign="center">{customer[0]?.name}</Td>
                    <Td textAlign="center">{customer[0]?.email}</Td>
                    <Td textAlign="center">{records}</Td>
                    <Td textAlign="center">{type}</Td>
                    <Td textAlign="center">${cost}</Td>
                    <Td textAlign="center">
                      {affiliateCommission ? `$${affiliateCommission}` : "N/A"}
                    </Td>
                    <Td textAlign="center">
                      {skiptracerCost ? `$${skiptracerCost}` : "N/A"}
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </Box>
      )}
    </div>
  );
};

export default CAffiliateCustomerOrders;
