import { createSlice } from "@reduxjs/toolkit";
import { IAdmin } from "../../interfaces";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    currentUser: null,
    statType: "affiliate",
  } as IAdmin,

  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setStatType: (state, action) => {
      state.statType = action.payload;
    },
  },
});

export const { setCurrentUser, setStatType } = adminSlice.actions;

export default adminSlice.reducer;
