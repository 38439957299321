import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Input,
  Box,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { TagsOptionsProps } from "../../../interfaces";
import CButton from "../../Shared/Button";
import CSelect from "../../Shared/Select";

interface TagsModalProps {
  onClose: () => void;
  onSubmit: () => void;
  onTagsChange: (tags: TagsOptionsProps["tags"]) => void;
  handleAddTag: (label: string) => void;
  isOpen: boolean;
  defaultValue: TagsOptionsProps["tags"];
  options: TagsOptionsProps["tags"];
  isLoading: boolean;
  isAdd: boolean;
  tagLoading: boolean;
}

export default function TagsModal({
  isOpen,
  onClose,
  onSubmit,
  defaultValue,
  onTagsChange,
  isLoading,
  isAdd,
  tagLoading,
  handleAddTag,
  options,
}: TagsModalProps) {
  const [label, setLabel] = useState("");

  useEffect(() => {
    if (isOpen) {
      setLabel("");
    }
  }, [isOpen]);

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} id="takeOrderModal" onClose={closeModal} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="brand.primary.main">
          {isAdd ? "Add Tag Here" : "Add OR Remove Tags"}
        </ModalHeader>
        <ModalCloseButton
          _focus={{
            color: "brand.primary.main",
          }}
        />
        <ModalBody pb="25px" w="full">
          {isAdd ? (
            <VStack w="full" alignItems="flex-start" spacing="2">
              <Box
                as="form"
                w="full"
                onSubmit={(e: React.FormEvent) => {
                  e.preventDefault();
                  handleAddTag(label);
                }}
              >
                <FormControl>
                  <FormLabel>Tag Name</FormLabel>
                  <Input
                    required
                    type="text"
                    placeholder="Enter Tag Name"
                    focusBorderColor="brand.primary.light"
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                  />
                </FormControl>
                <HStack pt="4" w="full" justifyContent="flex-end">
                  <CButton
                    type="submit"
                    variant="solid"
                    backgroundColor="brand.primary.main"
                    border="1px solid #FF824C"
                    color="#fff"
                    _focus={{
                      offset: "0",
                    }}
                    isLoading={tagLoading}
                  >
                    Add
                  </CButton>
                </HStack>
              </Box>
            </VStack>
          ) : (
            <VStack w="full" alignItems="flex-start" spacing="14">
              <Box w="full">
                <CSelect
                  onChange={(e) => onTagsChange(e)}
                  defaultValue={defaultValue}
                  options={options}
                />
              </Box>

              <HStack pt="4" w="full" justifyContent="flex-end">
                <CButton
                  onClick={onSubmit}
                  variant="solid"
                  backgroundColor="brand.primary.main"
                  border="1px solid #FF824C"
                  color="#fff"
                  _focus={{
                    offset: "0",
                  }}
                  isLoading={isLoading}
                >
                  Update
                </CButton>
              </HStack>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
