const Button = {
  baseStyle: {
    _focus: {
      outline: "none",
      boxShadow: "0 0 0 2px #FF9466",
    },
    fontWeight: "400",
  },
};

export default Button;
