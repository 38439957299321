import React from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tooltip,
  Select,
  Spinner,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { AiFillUnlock } from "react-icons/ai";
import { FaUserSlash } from "react-icons/fa";
import { FiUserPlus } from "react-icons/fi";
import moment from "moment";
import { IUser } from "../../interfaces";
import { DeleteModal, CUpdatePasswordModal, CButton } from "../../components";
import ActivateUserModal from "./ActivateUserModal";

const tableHeadings = [
  "Email",
  "Role",
  "SkipTracing Cost",
  "Deleted At",
  "Actions",
];

export interface addUsersProps {
  handleSubmit: Function;
  register: Function;
  errors: any;
  isLoading: boolean;
  data: IUser[];
  isloadingData: boolean;
  handleDelete: (id: string, onClose: () => void) => void;
  id: string;
  setId: Function;

  updateUserLoading: boolean;
  handlePasswordSubmitForm: Function;
  updatePasswordRegister: Function;
  updatePasswordErrors: any;
  resetUpdatePassword: () => void;
  handleUpdateUser: () => void;
  setEvent: (event: { name: string; payload: any }) => void;
}

const Users = (props: addUsersProps) => {
  const {
    handleSubmit,
    isLoading,
    register,
    errors,
    data,
    isloadingData,
    handleDelete,
    id,
    setId,
    handlePasswordSubmitForm,
    updateUserLoading,
    updatePasswordRegister,
    updatePasswordErrors,
    resetUpdatePassword,
    handleUpdateUser,
    setEvent,
  } = props;
  const {
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
    isOpen: isDeleteModalOpen,
  } = useDisclosure();
  const {
    onClose: onUpdatePasswordModalClose,
    onOpen: onUpdatePasswordModalOpen,
    isOpen: isUpdatePasswordModalOpen,
  } = useDisclosure();
  const {
    onClose: onActivateUserModalClose,
    isOpen: isActivateUserModalOpen,
    onOpen: onActivateUserModalOpen,
  } = useDisclosure();

  const handleDeleteModal = (id: string) => {
    setId(id);
    onDeleteModalOpen();
  };

  const handleUpdateModal = (id: string) => {
    setId(id);
    onUpdatePasswordModalOpen();
  };

  const handleActivateUserModal = (event: { name: string; payload: any }) => {
    setEvent(event);
    onActivateUserModalOpen();
  };

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    handleSubmit();
  };

  return (
    <>
      <Box>
        <Text
          fontWeight="600"
          fontStyle="normal"
          fontSize="40px"
          color="#393330"
        >
          Users
        </Text>
        <Flex w="100%" justifyContent="space-around">
          <Box w="35%">
            <form id="formSubmit" onSubmit={submit} style={{ width: "100%" }}>
              <VStack mt="30px" spacing={6} align="start" w="full" pr="6">
                <FormControl id="name" isInvalid={errors?.email}>
                  <FormLabel>
                    Email{" "}
                    <Box as="span" color="red">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    type="email"
                    placeholder="Email"
                    focusBorderColor="brand.primary.light"
                    {...register("email", {
                      required: true,
                    })}
                  />
                  <FormErrorMessage>
                    {errors?.email && <Text>Email is required</Text>}
                  </FormErrorMessage>
                </FormControl>

                <FormControl id="email" isInvalid={errors?.password}>
                  <FormLabel>
                    Password{" "}
                    <Box as="span" color="red">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    placeholder="Password"
                    focusBorderColor="brand.primary.light"
                    type="password"
                    {...register("password", {
                      required: true,
                      minLength: 8,
                    })}
                  />
                  <FormErrorMessage>
                    {errors?.password && <Text>Password is required</Text>}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  id="skiptracerCost"
                  isInvalid={errors?.skiptracerCost}
                >
                  <FormLabel>
                    SkipTracer Cost{" "}
                    <Box as="span" color="red">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="Skiptrace Cost"
                    focusBorderColor="brand.primary.light"
                    {...register("skiptracerCost", {
                      required: true,
                    })}
                  />
                </FormControl>
                <FormControl id="role" isInvalid={errors?.role}>
                  <FormLabel>
                    Role{" "}
                    <Box as="span" color="red">
                      *
                    </Box>
                  </FormLabel>
                  <Select
                    color="gray.400"
                    focusBorderColor="brand.primary.light"
                    py="0.5"
                    placeholder="Select Role"
                    {...register("role", {
                      required: true,
                    })}
                  >
                    <option value="Admin">Admin</option>
                    <option value="Skiptracer">Skiptracer</option>
                  </Select>
                  <FormErrorMessage>
                    {errors?.role && <Text>Please select a role</Text>}
                  </FormErrorMessage>
                </FormControl>

                <CButton
                  w="full"
                  variant="solid"
                  id="addUserButton"
                  background="brand.primary.main"
                  border="1px"
                  borderColor="brand.primary.main"
                  color="white"
                  mt="30px"
                  type="submit"
                  fontSize={14}
                  isLoading={isLoading}
                >
                  Add User
                </CButton>
              </VStack>
            </form>
          </Box>
          <Box w="65%" py="7">
            <Table
              overflow="auto"
              mx="auto"
              w="100%"
              variant="simple"
              border="1px"
              borderColor="gray.100"
            >
              <Thead>
                <Tr boxShadow="base">
                  {tableHeadings.map((heading, i) => (
                    <Th
                      fontSize="15px"
                      color="#2F3442"
                      fontWeight="600"
                      textAlign="center"
                      key={i}
                      minWidth="10px"
                    >
                      {heading}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody w="full">
                {data &&
                  data.map((userData) => (
                    <Tr fontSize="14px">
                      <Td textAlign="center" color="#737373">
                        {userData.email}
                      </Td>
                      <Td maxW="72" textAlign="center" color="#737373">
                        {userData.role}
                      </Td>
                      <Td maxW="72" textAlign="center" color="#737373">
                        ${userData.skiptracerCost}
                      </Td>

                      <Td textAlign="center" color="#737373">
                        {userData.deletedAt
                          ? moment(userData.deletedAt).format("MMM DD, YYYY")
                          : "N/A"}
                      </Td>

                      <Td textAlign="center" color="#737373">
                        {userData.deletedAt ? (
                          "N/A"
                        ) : (
                          <HStack mx="auto">
                            <Tooltip
                              label="Reset Password"
                              fontSize="xs"
                              bg="brand.primary.main"
                            >
                              <IconButton
                                onClick={() => handleUpdateModal(userData?._id)}
                                id="resetPassword"
                                icon={<AiFillUnlock />}
                                _focus={{
                                  offset: "0",
                                }}
                                colorScheme="orange"
                                bgColor="brand.primary.main"
                                border="1px"
                                borderColor="brand.primary.main"
                                aria-label="reset-icon"
                                fontSize="14px"
                                size="sm"
                              />
                            </Tooltip>
                            <Tooltip
                              label="Delete User"
                              bg="red.600"
                              fontSize="xs"
                            >
                              <IconButton
                                onClick={() => handleDeleteModal(userData?._id)}
                                id="SLDelete"
                                icon={<MdDelete />}
                                _focus={{
                                  offset: "0",
                                }}
                                colorScheme="red"
                                aria-label="reset-icon"
                                fontSize="14px"
                                size="sm"
                              />
                            </Tooltip>
                            <Tooltip
                              label={
                                userData.active
                                  ? "Deactivate User"
                                  : "Activate User"
                              }
                              bg={userData.active ? "blue.600" : "green.600"}
                              fontSize="xs"
                            >
                              <IconButton
                                onClick={() => {
                                  handleActivateUserModal({
                                    name: userData.active
                                      ? "deactivate"
                                      : "activate",
                                    payload: {
                                      id: userData?._id,
                                    },
                                  });
                                }}
                                id="SLDelete"
                                icon={
                                  userData.active ? (
                                    <FaUserSlash />
                                  ) : (
                                    <FiUserPlus />
                                  )
                                }
                                _focus={{
                                  offset: "0",
                                }}
                                colorScheme={userData.active ? "blue" : "green"}
                                aria-label="reset-icon"
                                fontSize="14px"
                                size="sm"
                              />
                            </Tooltip>
                          </HStack>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {isloadingData && (
              <Flex w="full" justifyContent="center">
                <Spinner color="brand.primary.main" thickness="2px" size="md" />
              </Flex>
            )}

            {data?.length === 0 && (
              <Box
                mt="30px"
                w="100%"
                h="300px"
                color="#737373"
                fontWeight="400"
              >
                <Text textAlign="center">No records available</Text>
              </Box>
            )}
          </Box>
        </Flex>
      </Box>

      <DeleteModal
        onClose={onDeleteModalClose}
        isOpen={isDeleteModalOpen}
        onDelete={handleDelete}
        id={id}
        loading={updateUserLoading}
      />

      <ActivateUserModal
        isOpen={isActivateUserModalOpen}
        onClose={onActivateUserModalClose}
        loading={updateUserLoading}
        onConfirm={() => {
          handleUpdateUser();
        }}
      />

      <CUpdatePasswordModal
        onClose={onUpdatePasswordModalClose}
        isOpen={isUpdatePasswordModalOpen}
        handleUpdatePassword={handlePasswordSubmitForm}
        id={id}
        isLoadingUpdatePassword={updateUserLoading}
        updatePasswordRegister={updatePasswordRegister}
        updatePasswordErrors={updatePasswordErrors}
        resetUpdatePassword={resetUpdatePassword}
      />
    </>
  );
};

export default Users;
