import React from "react";
import { Box, Flex } from "@chakra-ui/react";

interface LayoutProps {
  children?: React.ReactNode;
  Header: JSX.Element;
  Sidebar: JSX.Element;
}

export default function Layout(props: LayoutProps) {
  const { children, Header, Sidebar } = props;
  return (
    <Box pos="relative">
      <Box w="5%" position="fixed" bgColor="#fff" boxShadow="md" id="sidebar">
        {Sidebar}
      </Box>
      <Box pl="74px" w="full">
        <Box w="full">{Header}</Box>
        <Flex w="full" id="header" direction="column" alignItems="flex-start">
          <Box w="full" id="children" px="6">
            {children}
          </Box>
        </Flex>
      </Box>
      /
    </Box>
  );
}

Layout.defaultProps = {
  children: (
    <Box bgColor="red.400">
      <h1>Skip Trace</h1>
    </Box>
  ),
};
