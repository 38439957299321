import { useMutation, useQuery } from "react-query";
import { API } from "../constants";
import { apiClient } from "../services";

export const useAddUsers = () => {
  const addUsers = async (body: {
    email: string;
    password: string;
    role: string;
    skiptracerCost: string;
  }) => {
    const res = await apiClient.post(API.ROUTES.ADMIN_USERS, body);
    return res;
  };
  return useMutation(addUsers);
};

export const useGetUsers = () => {
  const getUsers = async () => {
    try {
      const res = await apiClient.get(API.ROUTES.ADMIN_USERS);
      return res?.data?.users;
    } catch (error) {
      // @ts-ignore
      throw new Error(error);
    }
  };
  return useQuery("GetUsers", getUsers);
};

export const useGetCurrentUser = () => {
  const getCurrentUser = async () => {
    const { data } = await apiClient.get(API.ROUTES.ADMIN_ME);
    return data?.admin;
  };
  return useQuery("currentUser", getCurrentUser);
};

export const useGetCustomers = (
  fromDate?: string,
  toDate?: string,
  affiliateStatus?: string,
  tag?: string,
  name?: string
) => {
  const getCustomers = async () => {
    const { data } = await apiClient.get(
      `${API.ROUTES.ADMIN_CUSTOMERS}?&toDate=${toDate}&fromDate=${fromDate}&affiliateStatus=${affiliateStatus}&tag=${tag}&name=${name}`
    );
    return data?.customers;
  };
  return useQuery(
    ["customers", fromDate, toDate, affiliateStatus, name],
    getCustomers
  );
};

export const useGetInvoices = (
  saleType: string,
  fromDate?: string,
  toDate?: string
) => {
  const getInvoices = async () => {
    const { data } = await apiClient.get(
      `${API.ROUTES.ADMIN_INVOICES}?&toDate=${toDate}&fromDate=${fromDate}&saleType=${saleType}`
    );
    return data;
  };
  return useQuery(["invoices", fromDate, toDate, saleType], getInvoices);
};

export const useDownloadInvoices = () => {
  const downloadInvoices = async (body: {}) => {
    const { data } = await apiClient.post(
      `${API.ROUTES.ADMIN_DOWNLOAD_INVOICES}`,
      null,
      {
        responseType: "blob",
      }
    );

    return data;
  };
  return useMutation(downloadInvoices);
};

export const useDownloadCustomers = () => {
  const downloadCustomers = async (body: {}) => {
    const { data } = await apiClient.post(
      `${API.ROUTES.ADMIN_DOWNLOAD_CUSTOMERS}`,
      null,
      {
        responseType: "blob",
      }
    );

    return data;
  };
  return useMutation(downloadCustomers);
};

export const useUpdateAffiliateStatus = () => {
  const UpdateAffiliate = async (body: { id: string; status: string }) => {
    const res = await apiClient.post(API.ROUTES.AFFILIATE_UPDATE_STATUS, body);
    return res;
  };
  return useMutation(UpdateAffiliate);
};

export const useUpdateUser = () => {
  const updateUser = async (body: {
    event: {
      name: string;
      payload: any;
    };
  }) => {
    const { data } = await apiClient.patch(API.ROUTES.ADMIN_USERS, body);

    return data;
  };

  return useMutation(updateUser);
};

export const useUpdateCustomerTags = () => {
  const UseUpdateCustomerTags = async (body: {
    cid: string;
    tags: { value: string; label: string }[] | [];
  }) => {
    const res = await apiClient.post(
      API.ROUTES.ADMIN_UPDATE_CUSTOMER_TAGS,
      body
    );
    return res;
  };
  return useMutation(UseUpdateCustomerTags);
};

export const useUpdateCustomerCredits = () => {
  const UseUpdateCustomerCredits = async (body: {
    cid: string;
    balance: { value: number };
  }) => {
    console.log(body);
    const res = await apiClient.post(
      API.ROUTES.ADMIN_UPDATE_CUSTOMER_CREDITS,
      body
    );
    return res;
  };
  return useMutation(UseUpdateCustomerCredits);
};

export const useAddTag = () => {
  const UseAddTag = async (body: { label: string }) => {
    const res = await apiClient.post(API.ROUTES.ADMIN_ADD_TAG, body);
    return res;
  };
  return useMutation(UseAddTag);
};

export const useGetTags = () => {
  const UseGetTags = async () => {
    const { data } = await apiClient.get(API.ROUTES.ADMIN_GET_TAGS);
    return data;
  };
  return useQuery("UseGetTags", UseGetTags);
};
