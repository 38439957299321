import { useMutation } from "react-query";
import moment, { Moment } from "moment";
import { API } from "../constants";
import { apiClient } from "../services";

export const useMetrics = () => {
  const getMetrics = async (body: {
    startDate: Moment | null;
    endDate: Moment | null;
    allTime: boolean;
  }) => {
    try {
      const { startDate, endDate, allTime } = body;
      const fromDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
      const toDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

      const { data } = await apiClient.get(
        `${API.ROUTES.ADMIN_APP_METRICS}?fromDate=${fromDate}&toDate=${toDate}&allTime=${allTime}`
      );
      return data;
    } catch (e: any) {
      throw new Error(e.response.data.errorCode);
    }
  };

  return useMutation(getMetrics);
};
