import { Moment } from "moment";
import React from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { AiOutlineCalendar } from "react-icons/ai";
import "react-dates/initialize";
import "./style.css";

interface DatePickerProps {
  handleOnDataPicker: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  fromDate: Moment | null;
  toDate: Moment | null;
  required?: boolean;
  noBorder?: boolean
}

export default function DatePicker({
  fromDate,
  toDate,
  handleOnDataPicker,
  required,
  noBorder,
}: DatePickerProps) {
  const [focusInput, setFocusInput] = React.useState<FocusedInputShape | null>(
    null
  );

  return (
    <DateRangePicker
      noBorder={noBorder}
      required={required}
      small={true}
      startDate={fromDate}
      startDateId='your_unique_start_date_id'
      endDate={toDate}
      endDateId='your_unique_end_date_id'
      onDatesChange={handleOnDataPicker}
      focusedInput={focusInput}
      onFocusChange={(input) => setFocusInput(input)}
      numberOfMonths={1}
      customInputIcon={<AiOutlineCalendar fontSize='20px' color='#33333' />}
      displayFormat='MMM DD, YYYY'
      isOutsideRange={() => false}
    />
  );
}
