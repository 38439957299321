import React from "react";
import {
  Box,
  Flex,
  HStack,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Spinner,
  Select,
} from "@chakra-ui/react";
import moment, { Moment } from "moment";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { DatePicker, CInvoicesStats, CButton } from "..";
import { IInvoices, IInvoicesStats } from "../../interfaces";

const tableHeadings = {
  affiliate: [
    "Date",
    "InvoiceID",
    "Order Revenue",
    "Order Profit",
    "Email",
    "Invoice Link",
    "Order Type",
  ],
  direct: [
    "Date",
    "InvoiceID",
    "Order Revenue",
    "Order Profit",
    "Email",
    "Invoice Link",
    "Order Type",
  ],
  tracer: ["Date", "INVOICEID", "AMOUNT", "EMAIL", "INVOICE LINK"],
  "payout-skiptracer": ["Date", "INVOICEID", "AMOUNT", "Type", "INVOICE LINK"],
  "payout-affiliate": ["Date", "INVOICEID", "AMOUNT", "Type", "INVOICE LINK"],
  "": [],
};

interface InvoicesProps {
  invoicesStats: IInvoicesStats | undefined;
  invoicesData: IInvoices[] | undefined;
  isLoading: boolean;
  handleOnDataPicker: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  fromDate: Moment | null;
  toDate: Moment | null;
  onFilterClick: (fromData: Moment | null, toDate: Moment | null) => void;
  onFilterClearClick: () => void;
  handleDownload: () => void;
  isLoadingDownload: boolean;
  setSaleType: (value: string) => void;
  saleType:
    | "direct"
    | "affiliate"
    | "tracer"
    | "payout-affiliate"
    | "payout-skiptracer"
    | "";
}

export default function Invoices(props: InvoicesProps) {
  const {
    invoicesStats,
    invoicesData,
    isLoading,
    handleOnDataPicker,
    fromDate,
    toDate,
    onFilterClearClick,
    onFilterClick,
    handleDownload,
    isLoadingDownload,
    saleType,
    setSaleType,
  } = props;

  const handleFilterClick = () => {
    onFilterClick(fromDate, toDate);
  };

  const handleClear = () => {
    onFilterClearClick();
  };

  const handleDownloadInvoice = () => {
    handleDownload();
  };
  return (
    <>
      <Text fontWeight="500" fontStyle="normal" fontSize="30px" color="#393330">
        Invoices
      </Text>
      <Flex flexDirection="column" w="full">
        <Flex w="full" mt="30px" justifyContent="space-between">
          <CButton
            onClick={handleDownloadInvoice}
            id="downloadInvoiceButton"
            variant="solid"
            size="sm"
            leftIcon={<HiOutlineDocumentDownload />}
            fontSize="16px"
            bgColor="brand.primary.main"
            border="1px solid rgb(255, 130, 76)"
            color="#fff"
            _focus={{
              offset: "0",
            }}
            isLoading={isLoadingDownload}
          >
            <Text fontWeight="light">Download Csv</Text>
          </CButton>
          <HStack w="full" justifyContent="flex-end">
            <DatePicker
              handleOnDataPicker={handleOnDataPicker}
              fromDate={fromDate}
              toDate={toDate}
            />
            <Select
              maxW="40"
              size="sm"
              placeholder="Order Type"
              value={saleType}
              onChange={(e) => setSaleType(e.target.value)}
              border="1px solid rgb(255, 130, 76)"
              color="rgb(255, 130, 76)"
              _focus={{
                offset: "0",
              }}
              rounded="md"
            >
              <option value="direct">Direct</option>
              <option value="affiliate">Affiliate</option>
              <option value="tracer">Tracer</option>
              <option value="payout-affiliate">Payout Affiliate</option>
              <option value="payout-skiptracer">Payout Skiptracer</option>
            </Select>
            <CButton
              onClick={() => handleClear()}
              id="filterClearbutton"
              variant="solid"
              size="sm"
              w="28"
              fontSize="16px"
              bgColor="#fff"
              border="1px solid rgb(255, 130, 76)"
              color="rgb(255, 130, 76)"
              _focus={{
                offset: "0",
              }}
            >
              <Text fontWeight="light">Clear</Text>
            </CButton>
            <CButton
              onClick={handleFilterClick}
              id="filterbutton"
              variant="solid"
              size="sm"
              w="28"
              fontSize="16px"
              bgColor="brand.primary.main"
              border="1px solid rgb(255, 130, 76)"
              color="#fff"
              _focus={{
                offset: "0",
              }}
              isLoading={false}
            >
              <Text fontWeight="light">Filter</Text>
            </CButton>
          </HStack>
        </Flex>

        <Flex flexDirection="column">
          <Box overflowX="auto" overflowY="hidden">
            <Table mt="30px" mb="30px" variant="simple">
              <Thead>
                <Tr boxShadow="base">
                  {tableHeadings[saleType].map((heading, i) => (
                    <Th
                      fontSize="15px"
                      color="#2F3442"
                      fontWeight="600"
                      textAlign="center"
                      key={i}
                      minWidth="10px"
                    >
                      {heading}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody w="full">
                {invoicesData &&
                  invoicesData?.map((invoice) => (
                    <Tr fontSize="14px">
                      <Td minW="36" textAlign="center" color="#737373">
                        {moment(invoice?.createdAt).format("MM-DD-YYYY")}
                      </Td>
                      <Td textAlign="center" color="#737373">
                        {invoice?._id}
                      </Td>
                      {(saleType === "direct" || saleType === "affiliate") && (
                        <>
                          <Td minW="44" textAlign="center" color="#737373">
                            ${invoice?.orderRevenue}
                          </Td>
                          <Td minW="44" textAlign="center" color="#737373">
                            ${invoice?.orderProfit}
                          </Td>
                        </>
                      )}
                      {saleType !== "direct" && saleType !== "affiliate" && (
                        <Td minW="44" textAlign="center" color="#737373">
                          ${invoice?.amount.toFixed(2)}
                        </Td>
                      )}

                      {invoice?.type && (
                        <Td
                          maxW="72"
                          textTransform="capitalize"
                          textAlign="center"
                          color="#737373"
                        >
                          {invoice?.type}
                        </Td>
                      )}
                      {invoice?.email && (
                        <Td maxW="72" textAlign="center" color="#737373">
                          {invoice.email}
                        </Td>
                      )}
                      <Td textAlign="center">
                        <CButton
                          id="viewOutputFile"
                          variant="solid"
                          fontSize="14px"
                          bgColor="brand.primary.main"
                          border="1px solid rgb(255, 130, 76)"
                          color="#fff"
                          leftIcon={<HiOutlineDocumentDownload />}
                          _focus={{
                            offset: "0",
                          }}
                        >
                          <a
                            href={invoice?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Text fontWeight="light">Download Invoice</Text>
                          </a>
                        </CButton>
                      </Td>
                      <Td
                        textTransform="capitalize"
                        minW="40"
                        textAlign="center"
                        color="#737373"
                      >
                        {invoice?.saleType}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {isLoading && (
              <Flex w="full" justifyContent="center">
                <Spinner color="brand.primary.main" thickness="2px" size="md" />
              </Flex>
            )}

            {invoicesData?.length === 0 && (
              <Box
                mt="30px"
                w="100%"
                h="300px"
                color="#737373"
                fontWeight="400"
              >
                <Text textAlign="center">No Invoices available</Text>
              </Box>
            )}
          </Box>
        </Flex>
      </Flex>
      <VStack>
        <Box>
          <Text
            fontWeight="500"
            fontStyle="normal"
            fontSize="25px"
            color="#393330"
            alignSelf="flex-start"
          >
            Direct
          </Text>
          <CInvoicesStats stats={invoicesStats?.direct} />
        </Box>

        <Box>
          <Text
            mt="10"
            alignSelf="flex-start"
            fontWeight="500"
            fontStyle="normal"
            fontSize="25px"
            color="#393330"
          >
            Affiliate
          </Text>
          <CInvoicesStats stats={invoicesStats?.affiliate} />
        </Box>
      </VStack>
    </>
  );
}
