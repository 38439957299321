import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react";
import { CButton } from "..";

interface UpdatePasswordModalProps {
  onClose: () => void;
  isOpen: boolean;
  handleUpdatePassword: Function;
  id: string;
  isLoadingUpdatePassword: boolean;
  updatePasswordRegister: Function;
  updatePasswordErrors: any;
  resetUpdatePassword: () => void;
}

function UpdatePasswordModal(props: UpdatePasswordModalProps) {
  const {
    isOpen,
    onClose,
    handleUpdatePassword,
    id,
    isLoadingUpdatePassword,
    updatePasswordRegister,
    updatePasswordErrors,
    resetUpdatePassword,
  } = props;

  const handleClose = () => {
    onClose();
    resetUpdatePassword();
  };

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleUpdatePassword(id, onClose);
  };

  return (
    <>
      <Modal isOpen={isOpen} id="passwordUpdateModal" onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Password</ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb="25px">
            <form onSubmit={submit} id="passwordUpdateForm">
              <Stack spacing={4}>
                <FormControl
                  id="passwordInput"
                  isInvalid={updatePasswordErrors?.password}
                >
                  <FormLabel color="gray" fontSize="sm">
                    New Password
                  </FormLabel>
                  <Input
                    _focus={{
                      borderColor: "brand.primary.main",
                    }}
                    type="password"
                    {...updatePasswordRegister("password", {
                      required: true,
                      minLength: 8,
                    })}
                  />

                  <FormErrorMessage>
                    {" "}
                    {updatePasswordErrors?.password && (
                      <Text>Password is required</Text>
                    )}
                  </FormErrorMessage>
                </FormControl>
                <CButton
                  type="submit"
                  id="updatePasswordButton"
                  variant="solid"
                  p="10px"
                  fontSize="1rem"
                  bgColor="brand.primary.main"
                  border="1px solid #FF824C"
                  color="#fff"
                  isLoading={isLoadingUpdatePassword}
                  _focus={{
                    offset: "0",
                  }}
                >
                  <Text>Update Password</Text>
                </CButton>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpdatePasswordModal;
