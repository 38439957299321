import React, { useState } from "react";
import {
  VStack,
  Box,
  Text,
  Table,
  Tbody,
  Th,
  Tr,
  Thead,
  Td,
  Spinner,
  Flex,
  Button,
  HStack,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import moment, { Moment } from "moment";
import { MdDelete } from "react-icons/md";
import { RiAddFill } from "react-icons/ri";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { CButton, DeleteModal, DatePicker } from "../../components";
import { IPromoCodes } from "../../interfaces";
import AddModal from "./AddModal";

interface CPromoCodesProps {
  promoCodes: IPromoCodes[] | [];
  onSubmitHandler: (discountP: number, orderType: string) => void;
  isLoading: boolean;
  isDataLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  id: string;
  setId: Function;
  handleDelete: (id: string, onClose: () => void) => void;
  isLoadingDeleteData: boolean;
  handleOnDatePicker: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  fromDate: Moment | null;
  toDate: Moment | null;
  onFilterClick: (fromData: Moment | null, toDate: Moment | null) => void;
  onFilterClearClick: () => void;
  handleStatType: (type: string) => void;
  statType: "affiliate" | "direct";
  isFetching: boolean;
  mutatePromoCodesLoading: boolean;
  handleDownloadPromoCodes: () => void;
}

const tableHeadings = {
  affiliate: [
    "Date Created",
    "Affiliate Name",
    "Referral Code",
    "Number of signups",
    "Number Of Orders",
    "Revenue Generated",
  ],
  direct: [
    "Created At",
    "Promo Code",
    "Discounted Percentage",
    "Order Type",
    "Number Of Orders",
  ],
};

const CPromoCodes = ({
  onSubmitHandler,
  isLoading,
  onOpen,
  onClose,
  isOpen,
  isDataLoading,
  promoCodes,
  id,
  setId,
  handleDelete,
  isLoadingDeleteData,
  fromDate,
  toDate,
  handleOnDatePicker,
  onFilterClearClick,
  onFilterClick,
  handleStatType,
  statType,
  isFetching,
  handleDownloadPromoCodes,
  mutatePromoCodesLoading,
}: CPromoCodesProps) => {
  const [discountP, setDiscountedP] = useState(0);
  const [orderType, setOrderType] = useState("");
  const {
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
    isOpen: isDeleteModalOpen,
  } = useDisclosure();

  const onDiscountChange = (v: string) => {
    setDiscountedP(Number(v));
  };

  const onOrderTypeChange = (v: string) => {
    setOrderType(v);
  };

  const handleDeleteModal = (id: string) => {
    setId(id);
    onDeleteModalOpen();
  };

  const onSubmit = () => {
    onSubmitHandler(discountP, orderType);
  };

  const handleFilterClick = () => {
    onFilterClick(fromDate, toDate);
  };

  const handleClear = () => {
    onFilterClearClick();
  };

  return (
    <>
      <VStack alignItems="flex-start" spacing={6}>
        <AddModal
          onSubmit={onSubmit}
          onDiscountChange={onDiscountChange}
          onOrderTypeChange={onOrderTypeChange}
          isOpen={isOpen}
          onClose={onClose}
          loading={isLoading}
        />
        <Text
          fontWeight="500"
          fontStyle="normal"
          fontSize="30px"
          color="#393330"
        >
          {statType === "direct" ? "Promo Codes" : "Referral Codes"}
        </Text>
        <Flex justifyContent="space-between" w="full">
          <CButton
            onClick={handleDownloadPromoCodes}
            id="downloadInvoiceButton"
            variant="solid"
            size="sm"
            leftIcon={<HiOutlineDocumentDownload />}
            fontSize="16px"
            bgColor="brand.primary.main"
            border="1px solid rgb(255, 130, 76)"
            color="#fff"
            _focus={{
              offset: "0",
            }}
            isLoading={mutatePromoCodesLoading}
          >
            <Text fontWeight="light">Download Csv</Text>
          </CButton>
          <HStack>
            <Select
              rounded="md"
              w="60"
              color="gray.400"
              focusBorderColor="brand.primary.light"
              size="sm"
              height="34px"
              placeholder="Search by type"
              value={statType}
              onChange={(e) => {
                handleStatType(e?.target?.value);
              }}
            >
              <option value="affiliate">Affiliate</option>
              <option value="direct">Direct</option>
            </Select>
            <DatePicker
              handleOnDataPicker={handleOnDatePicker}
              fromDate={fromDate}
              toDate={toDate}
            />
            <CButton
              onClick={() => handleClear()}
              id="filterClearbutton"
              variant="solid"
              size="sm"
              w="28"
              fontSize="16px"
              bgColor="#fff"
              border="1px solid rgb(255, 130, 76)"
              color="rgb(255, 130, 76)"
              _focus={{
                offset: "0",
              }}
            >
              <Text fontWeight="light">Clear</Text>
            </CButton>
            <CButton
              onClick={handleFilterClick}
              id="filterbutton"
              variant="solid"
              size="sm"
              w="28"
              fontSize="16px"
              bgColor="brand.primary.main"
              border="1px solid rgb(255, 130, 76)"
              color="#fff"
              _focus={{
                offset: "0",
              }}
              isLoading={false}
            >
              <Text fontWeight="light">Filter</Text>
            </CButton>
            {statType === "direct" && (
              <CButton
                onClick={() => onOpen()}
                leftIcon={<RiAddFill size={18} />}
                id="filterbutton"
                variant="solid"
                size="sm"
                w="28"
                fontSize="16px"
                bgColor="brand.primary.main"
                border="1px solid rgb(255, 130, 76)"
                color="#fff"
                _focus={{
                  offset: "0",
                }}
                isLoading={false}
              >
                <Text fontWeight="light">Add</Text>
              </CButton>
            )}
          </HStack>
        </Flex>

        <Table
          overflow="auto"
          mx="auto"
          w="100%"
          mt="30px"
          mb="30px"
          variant="simple"
        >
          <Thead>
            <Tr boxShadow="base">
              {tableHeadings[statType].map((heading, i) => (
                <Th
                  fontSize="15px"
                  color="#2F3442"
                  fontWeight="600"
                  textAlign="center"
                  key={i}
                  minWidth="10px"
                >
                  {heading}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody w="full">
            {!isDataLoading &&
              !isFetching &&
              promoCodes.map(
                (
                  {
                    code,
                    createdAt,
                    date,
                    orderType,
                    discountedPercentage,
                    _id,
                    affiliateName,
                    referralCode,
                    customersAcquired,
                    revenueGenerated,
                    numberOfOrders,
                  },
                  i
                ) => (
                  <Tr fontSize="14px" key={i}>
                    <Td textAlign="center" color="#737373">
                      {moment(createdAt || date).format("MM-DD-YYYY")}
                    </Td>

                    <Td textAlign="center" color="#737373">
                      {affiliateName || code}
                    </Td>
                    <Td maxW="72" textAlign="center" color="#737373">
                      {referralCode || `${discountedPercentage}%`}
                    </Td>
                    <Td
                      textTransform="capitalize"
                      maxW="72"
                      textAlign="center"
                      color="#737373"
                    >
                      {customersAcquired || orderType}
                    </Td>
                    <Td
                      textTransform="capitalize"
                      maxW="72"
                      textAlign="center"
                      color="#737373"
                    >
                      {numberOfOrders}
                    </Td>

                    {!code && (
                      <Td
                        textTransform="capitalize"
                        maxW="72"
                        textAlign="center"
                        color="#737373"
                      >
                        ${revenueGenerated}
                      </Td>
                    )}

                    {code && (
                      <Td textAlign="center" color="#737373">
                        <Button
                          onClick={() => handleDeleteModal(_id)}
                          w="30"
                          id="deletePromo"
                          variant="outline"
                          colorScheme="red"
                          _hover={{
                            backgroundColor: "red",
                            color: "white",
                            transform: "scale(.97)",
                            transition: ".3s ease-in-out",
                          }}
                          leftIcon={<MdDelete />}
                          _focus={{
                            offset: "0",
                          }}
                        >
                          Delete
                        </Button>
                      </Td>
                    )}
                  </Tr>
                )
              )}
          </Tbody>
        </Table>
        {(isDataLoading || isFetching) && (
          <Flex w="full" justifyContent="center">
            <Spinner color="brand.primary.main" thickness="2px" size="md" />
          </Flex>
        )}

        {promoCodes.length === 0 && !isDataLoading && !isFetching && (
          <Box mt="30px" w="100%" h="300px" color="#737373" fontWeight="400">
            <Text textAlign="center">No Promo Code</Text>
          </Box>
        )}
      </VStack>

      <DeleteModal
        onClose={onDeleteModalClose}
        isOpen={isDeleteModalOpen}
        onDelete={handleDelete}
        id={id}
        loading={isLoadingDeleteData}
        modalTitle="Delete Promo"
      />
    </>
  );
};

export default CPromoCodes;
