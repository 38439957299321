import { useQuery, useMutation } from "react-query";
import { Moment } from "moment";
import { API } from "../constants";
import { apiClient } from "../services";

export const useAffiliateStats = (
  fromDate?: string,
  toDate?: string,
  id?: string
) => {
  const getAffiliateStats = async () =>
    await apiClient.get(
      `${API.ROUTES.AFFILIATE_GET_STATS}?&fromDate=${fromDate}&toDate=${toDate}&id=${id}`
    );
  return useQuery(
    ["getAffiliateStats", fromDate, toDate, id],
    getAffiliateStats
  );
};

export const useAffiliateTableStats = (fromDate?: string, toDate?: string) => {
  const getAffiliateTableStats = async () =>
    await apiClient.get(
      `${API.ROUTES.AFFILIATE_GET_TABLE_STATS}?&fromDate=${fromDate}&toDate=${toDate}`
    );
  return useQuery(
    ["getAffiliateTableStats", fromDate, toDate],
    getAffiliateTableStats
  );
};

export const useGetAffiliatesPayouts = (fromDate?: string, toDate?: string) => {
  const getAffiliatesPayouts = async () => {
    try {
      const res = await apiClient.get(
        `${API.ROUTES.ADMIN_AFFILIATES_PAYOUTS}?&toDate=${toDate}&fromDate=${fromDate}`
      );
      return res?.data?.affiliatesPayouts;
    } catch (error: any) {
      throw new Error(error);
    }
  };
  return useQuery(
    ["GetAffiliatesPayouts", fromDate, toDate],
    getAffiliatesPayouts
  );
};

export const useAddAffiliatePayouts = () => {
  const addAffiliatePayouts = async (body: {
    amount: number;
    uid: string;
    payPeriodStart: Moment | null;
    payPeriodEnd: Moment | null;
  }) => {
    try {
      const res = await apiClient.post(
        API.ROUTES.ADMIN_AFFILIATES_PAYOUTS,
        body
      );
      return res;
    } catch (e: any) {
      console.log(e);
      throw new Error(e.response.data.errorCode);
    }
  };

  return useMutation(addAffiliatePayouts);
};

export const useDeleteAffiliatePayout = () => {
  const deleteAffiliatePayout = async (body: { id: string }) => {
    try {
      const res = await apiClient.delete(
        `${API.ROUTES.ADMIN_AFFILIATES_PAYOUTS}?id=${body.id}`
      );
      return res;
    } catch (e: any) {
      console.log(e);
      throw new Error(e.response.data.errorCode);
    }
  };

  return useMutation(deleteAffiliatePayout);
};

export const useGetAffiliateCustomerOrders = (referralId: string) => {
  const getAffiliateCustomerOrders = async () => {
    try {
      const res = await apiClient.get(
        `${API.ROUTES.ADMIN_AFFILIATES_CUSTOMERS_ORDERS}?referralId=${referralId}`
      );
      return res.data;
    } catch (e: any) {
      console.log(e);
      throw new Error(e.response.data.errorCode);
    }
  };

  return useQuery(
    ["affiliate-customers-orders", referralId],
    getAffiliateCustomerOrders
  );
};
