import React from "react";
import {
  Box,
  Flex,
  VStack,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Spinner,
  Avatar,
  Container,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";
import { ICustomerLogsHistory } from "../../../interfaces";

const tableHeadings = ["Date/Time Logged In"];

interface CustomersProps {
  customerLogsData: ICustomerLogsHistory;
  isLoading: boolean;
}

export default function Customers(props: CustomersProps) {
  const { customerLogsData, isLoading } = props;

  const formatedData = customerLogsData?.logsHistory?.map((obj) => {
    return Object.values({
      timestamp: moment(obj.timestamp).format("MMMM Do YYYY, h:mm:ss a"),
    });
  });

  return (
    <>
      <Text
        fontWeight="500"
        fontStyle="normal"
        fontSize="30px"
        color="#393330"
        mb="16"
      >
        Customers Logged In History
      </Text>
      <Box>
        <Container maxW="6xl">
          <Flex alignContent="space-between">
            <Box w="30%">
              <VStack alignItems="center">
                <Avatar size="2xl" src="https://bit.ly/broken-link"></Avatar>
                <Text
                  fontSize="lg"
                  fontWeight="semibold"
                  my="2"
                  color="brand.primary.main"
                >
                  {customerLogsData?.name}
                </Text>
                <Text fontSize="md" color="gray">
                  {customerLogsData?.email}
                </Text>
              </VStack>
            </Box>
            <Box w="60%" alignContent="start">
              <Box alignSelf="self-start">
                {formatedData?.length ? (
                  <CSVLink
                    data={formatedData}
                    headers={tableHeadings}
                    filename={`login-history-${moment().format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}`}
                  >
                    <Button
                      size="sm"
                      rightIcon={<BsDownload />}
                      id="downloadBtn"
                      colorScheme="orange"
                      bgColor="brand.primary.main"
                    >
                      Download as CSV
                    </Button>
                  </CSVLink>
                ) : null}
                <Box overflow="auto" maxH="60vh" mt="30px">
                  <Table w="100%" mb="30px" px="16" variant="simple">
                    <Thead>
                      <Tr boxShadow="base">
                        {tableHeadings.map((heading, i) => (
                          <Th
                            fontSize="15px"
                            color="#2F3442"
                            fontWeight="600"
                            textAlign="center"
                            key={i}
                            minWidth="10px"
                          >
                            {heading}
                          </Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody w="full">
                      {customerLogsData &&
                        customerLogsData.logsHistory?.map((logs: any) => (
                          <Tr fontSize="lg">
                            <Td textAlign="center" color="#737373" minW="70">
                              {moment(logs?.timestamp).format(
                                "MM-DD-YYYY, h:mm:ss a"
                              )}
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
              </Box>

              {isLoading && (
                <Flex w="full" justifyContent="center">
                  <Spinner
                    color="brand.primary.main"
                    thickness="2px"
                    size="md"
                  />
                </Flex>
              )}

              {customerLogsData?.logsHistory?.length === 0 && (
                <Box
                  mt="30px"
                  w="100%"
                  h="300px"
                  color="#737373"
                  fontWeight="400"
                >
                  <Text textAlign="center">No records available</Text>
                </Box>
              )}
            </Box>
          </Flex>
        </Container>
      </Box>
    </>
  );
}
