import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  Heading,
} from "@chakra-ui/react";
import { CButton } from "..";

interface ConfirmProps {
  children: React.ReactNode;
  handleConfirm: () => void;
  handleClose: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  description: string;
}

const Confirm = ({
  children,
  handleConfirm,
  isOpen,
  handleClose,
  isLoading,
  description,
}: ConfirmProps) => {
  const initRef = React.useRef<any>();

  return (
    <Popover placement="left" isOpen={isOpen}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <Portal>
            <PopoverContent
              shadow="lg"
              _focus={{
                boxShadow: "none",
              }}
            >
              <PopoverArrow />
              <PopoverHeader>Confirm!</PopoverHeader>
              <PopoverCloseButton
                onClick={() => handleClose()}
                color="brand.primary.main"
                _focus={{ boxShadow: "none" }}
              />
              <PopoverBody>
                <Heading
                  fontSize="md"
                  fontWeight="normal"
                  color="brand.primary.main"
                >
                  {description}
                </Heading>
              </PopoverBody>
              <PopoverFooter display="flex" justifyContent="flex-end">
                <CButton
                  variant="outline"
                  borderColor="white"
                  color="brand.primary.main"
                  border="1px"
                  onClick={() => handleClose()}
                  ref={initRef}
                  _focus={{
                    offset: 0,
                  }}
                >
                  No
                </CButton>

                <CButton
                  isLoading={isLoading}
                  disabled={isLoading}
                  variant="solid"
                  ml="2"
                  bg="brand.primary.main"
                  color="white"
                  border="1px"
                  borderColor="brand.primary.main"
                  onClick={() => handleConfirm()}
                  ref={initRef}
                  _focus={{
                    offset: 0,
                  }}
                >
                  Yes
                </CButton>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default Confirm;
