import { extendTheme } from "@chakra-ui/react";
import styles from "./styles";
import borders from "./foundations/borders";
import Button from "./components/button";

const skipSpotAdminTheme = extendTheme({
  styles,
  borders,
  colors: {
    brand: {
      primary: {
        main: "#FF824C",
        light: "#FF9466",
      },
      orange: {
        50: "#FFEFDB",
        100: "#FFEFDB",
        200: "#FFDBB7",
        300: "#FFC293",
        400: "#FFAA78",
        500: "#FF824C",
        600: "#DB5E37",
        700: "#B73E26",
        800: "#932418",
        900: "#7A120E",
      },
    },
    success: { 500: "#51D55E" },
    loading: { 500: "#FF824C" },
  },
  fontSizes: {},
  fontWeights: {},
  fonts: {
    poppins: "Poppins",
  },
  components: {
    Button,
  },
});

export { skipSpotAdminTheme };
