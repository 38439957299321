import { useMutation, useQuery } from "react-query";
import { API } from "../constants";
import { apiClient } from "../services";

export const useCustomerPurchaseHistory = () => {
  const getCustomerPurchaseHistory = async (params: { id: string | null }) => {
    const res = await apiClient.get(
      `${API.ROUTES.ADMIN_CUSTOMER_PURCHASE_HISTORY}?id=${params.id}`
    );

    return res;
  };
  return useMutation(getCustomerPurchaseHistory);
};

export const useGetCustomerLogs = () => {
  const getCustomerLogs = async (params: { id: string | null }) => {
    const res = await apiClient.get(
      `${API.ROUTES.ADMIN_CUSTOMER_LOGS}?id=${params.id}`
    );

    return res;
  };
  return useMutation(getCustomerLogs);
};

export const useGetCustomers = (fromDate?: string, toDate?: string) => {
  const getCustomers = async () => {
    const { data } = await apiClient.get(
      `${API.ROUTES.ADMIN_CUSTOMERS}?&toDate=${toDate}&fromDate=${fromDate}`
    );
    return data?.customers;
  };
  return useQuery(["customers", fromDate, toDate], getCustomers);
};

export const useUpdateReferralCode = () => {
  const updateReferralCode = async (body: {
    userId: string;
    referralCode: string;
  }) => {
    const res = await apiClient.post(
      API.ROUTES.ADMIN_UPDATE_AFFILIATE_REFERRAL_CODE,
      body
    );
    return res;
  };
  return useMutation(updateReferralCode);
};

export const useUpdateCustomer = () => {
  const updateCustomer = async (body: {
    event: {
      name: string;
      payload: any;
    };
  }) => {
    const { data } = await apiClient.patch(API.ROUTES.ADMIN_CUSTOMERS, body);

    return data;
  };

  return useMutation(updateCustomer);
};
