import { useMutation } from "react-query";
import { API } from "../constants";
import { apiClient } from "../services";

export const useAllBulkFiles = () => {
  const getBulkfiles = async (body: { query?: string; status?: string }) => {
    try {
      const { query, status } = body;
      const { data } = await apiClient.get(
        `${API.ROUTES.ADMIN_FILES}?query=${query}&status=${status}`
      );
      return data;
    } catch (e: any) {
      throw new Error(e.response.data.errorCode);
    }
  };

  return useMutation(getBulkfiles);
};

export const useBulkSkiptraceOutput = () => {
  const uploadFile = async (body: {
    recordId: string;
    file: File;
    skiptracedRecords: number;
    type: string;
    reviewReason: string;
  }) => {
    try {
      const data = {
        recordId: body.recordId,
        skiptracedRecords: body.skiptracedRecords,
        type: body.type,
        reviewReason: body.reviewReason,
      };

      const formData = new FormData();

      formData.append("filename", body.file);
      formData.append("fileProps", JSON.stringify(data));
      const res = await apiClient.post(
        API.ROUTES.ADMIN_SKIPTRACE_OUTPUT,
        formData
      );
      return res;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };
  return useMutation(uploadFile);
};
