import React, { useRef } from "react";
import {
  Popover,
  ButtonGroup,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { CButton } from "../../../components";

interface ConfirmPopoverProps {
  isOpen: boolean;
  isLoading: boolean;
  handleConfirm: () => void;
  onClose: () => void;
}

function ConfirmPopover({
  isOpen,
  onClose,
  isLoading,
  handleConfirm,
}: ConfirmPopoverProps) {
  const firstFieldRef = useRef(null);

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement="left"
      closeOnBlur={false}
    >
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">Confirmation</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          Are you sure you want to continue with your action?
        </PopoverBody>
        <PopoverFooter d="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <CButton
              onClick={() => onClose()}
              id="filterbutton"
              variant="outline"
              size="sm"
              w="28"
              fontSize="16px"
              bgColor="brand.primary.main"
              border="1px solid rgb(255, 130, 76)"
              color="#fff"
              _focus={{
                offset: "0",
              }}
            >
              No
            </CButton>
            <CButton
              onClick={handleConfirm}
              id="filterbutton"
              variant="solid"
              size="sm"
              w="28"
              fontSize="16px"
              bgColor="brand.primary.main"
              border="1px solid rgb(255, 130, 76)"
              color="#fff"
              _focus={{
                offset: "0",
              }}
              isLoading={isLoading}
            >
              Yes
            </CButton>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export default ConfirmPopover;
