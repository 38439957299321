import {
  VStack,
  Box,
  Text,
  Table,
  Tbody,
  Th,
  Tr,
  Thead,
  Td,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { IAvgOrdersTime } from "../../interfaces";

interface COrdersProps {
  avgOrdersTimeData: IAvgOrdersTime[];
  isLoading: boolean;
}

const tableHeadings = [
  "User Name",
  "List Name",
  "Order Type",
  "Pending to Processing",
  "Processing to Completed",
];

const COrders = (props: COrdersProps) => {
  const { avgOrdersTimeData, isLoading } = props;

  return (
    <>
      <VStack alignItems='flex-start' spacing={6}>
        <Text
          fontWeight='500'
          fontStyle='normal'
          fontSize='30px'
          color='#393330'
          mb='16'>
          Orders
        </Text>

        <Table
          overflow='auto'
          mx='auto'
          w='100%'
          mt='30px'
          mb='30px'
          variant='simple'>
          <Thead>
            <Tr boxShadow='base'>
              {tableHeadings.map((heading, i) => (
                <Th
                  fontSize='15px'
                  color='#2F3442'
                  fontWeight='600'
                  textAlign='center'
                  key={i}
                  minWidth='10px'>
                  {heading}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody w='full'>
            {avgOrdersTimeData?.map((data: any) => (
              <Tr fontSize='14px' key={data?._id}>
                <Td textAlign='center' color='#737373'>
                  {data.name}
                </Td>
                <Td textAlign='center' color='#737373'>
                  {data?.listName}
                </Td>
                <Td maxW='72' textAlign='center' color='#737373'>
                  {data?.orderType}
                </Td>
                <Td maxW='72' textAlign='center' color='#737373'>
                  {Math.floor(data?.pendingToProcessingTime)} h
                </Td>
                <Td maxW='72' textAlign='center' color='#737373'>
                  {Math.floor(data?.processingToCompletedTime)} h
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {isLoading && (
          <Flex w='full' justifyContent='center'>
            <Spinner color='brand.primary.main' thickness='2px' size='md' />
          </Flex>
        )}

        {avgOrdersTimeData?.length === 0 && (
          <Box mt='30px' w='100%' h='300px' color='#737373' fontWeight='400'>
            <Text textAlign='center'>No Records available</Text>
          </Box>
        )}
      </VStack>
    </>
  );
};

export default COrders;
