import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../slices";
import { IFulfilledOrdersStat } from "../../interfaces";
import StatCard from "./StatCard";

interface SkiptraceStatsProps {
  stats: IFulfilledOrdersStat;
}

const SkiptraceStats = (props: SkiptraceStatsProps) => {
  const {
    totalSkiptracedRecords,
    totalFulfilledOrders,
    totalMoneyEarned,
    totalAverageRecordsPerOrder,
    totalMoneyPaidToSkipTracer,
    totalMoneyPaid,
    grandTotalAverageRecordsPerOrder,
    grandTotalFulfilledOrders,
    grandTotalMoneyEarned,
    grandTotalSkiptracedRecords,
    grandTotalRevenueGenerated,
    grandTotalProfitGenerated,
    grandTotalAvgOrderCompletionTime,
    grandTotalAffiliateOrders,
    grandTotalMoneyPaid,
    hitRate,
  } = props?.stats;

  const { currentUser } = useSelector((state: RootState) => state.admin);

  return (
    <>
      <Flex w="full" h="auto" mt="30px">
        <Flex direction="column" mx="4">
          {totalSkiptracedRecords &&
            totalSkiptracedRecords?.map((record, index) => (
              <Flex key={index} direction="column" my="4">
                <Text color="gray.600">
                  {record.skiptracerEmail ? record.skiptracerEmail : ""}
                </Text>
                <StatCard
                  title="Skip Traced Records"
                  value={
                    record?.skipTracedRecords ? record?.skipTracedRecords : 0
                  }
                />
              </Flex>
            ))}
        </Flex>
        <Flex direction="column" mx="4">
          {totalFulfilledOrders &&
            totalFulfilledOrders?.map((order, i) => (
              <Flex
                key={i}
                mt={totalFulfilledOrders.length > 1 ? (i !== 0 ? 14 : 10) : 4}>
                <StatCard
                  title="Fulfilled Orders"
                  value={order?.ordersFulfilled ? order?.ordersFulfilled : 0}
                />
              </Flex>
            ))}
        </Flex>
        <Flex direction="column" mx="4">
          {totalMoneyEarned &&
            totalMoneyEarned?.map((money, i) => (
              <Flex
                key={i}
                mt={totalMoneyEarned.length > 1 ? (i !== 0 ? 14 : 10) : 4}>
                <StatCard
                  title="Money Owed"
                  value={`$${
                    money?.moneyEarned ? money?.moneyEarned.toFixed(2) : 0
                  }`}
                />
              </Flex>
            ))}
        </Flex>
        <Flex direction="column" mx="4">
          {totalAverageRecordsPerOrder &&
            totalAverageRecordsPerOrder?.map((record, i) => (
              <Flex
                key={i}
                mt={
                  totalAverageRecordsPerOrder.length > 1
                    ? i !== 0
                      ? 14
                      : 10
                    : 4
                }>
                <StatCard
                  title="Average records/order"
                  value={`${
                    record?.avgRecords ? record?.avgRecords.toFixed(1) : 0
                  }`}
                />
              </Flex>
            ))}
        </Flex>
        <Flex direction="column" mx="4">
          {totalMoneyPaidToSkipTracer &&
            totalMoneyPaidToSkipTracer?.map((skiptracer, i) => (
              <Flex
                key={i}
                mt={
                  totalMoneyPaidToSkipTracer.length > 1
                    ? i !== 0
                      ? 14
                      : 10
                    : 4
                }>
                <StatCard
                  title="Money Paid"
                  value={`$${
                    skiptracer?.moneyPaid ? skiptracer?.moneyPaid.toFixed(1) : 0
                  }`}
                />
              </Flex>
            ))}
        </Flex>
        {totalMoneyPaid && (
          <Flex direction="column">
            {totalMoneyPaid &&
              totalMoneyPaid?.map((money, i) => (
                <Flex
                  key={i}
                  mt={totalMoneyPaid.length > 1 ? (i !== 0 ? 14 : 10) : 4}>
                  <Box
                    align="start"
                    w="auto"
                    h="auto"
                    border="1px"
                    borderColor="gray.100"
                    p="3"
                    minW="52"
                    rounded="xl"
                    shadow="sm">
                    <Flex flexDirection="column" pr="8">
                      <Text color="gray.400" fontSize="sm">
                        Money Paid
                      </Text>
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="brand.primary.main">
                        ${money?.moneyPaid ? money?.moneyPaid.toFixed(1) : 0}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              ))}
          </Flex>
        )}
        {currentUser?.role !== "Admin" && (
          <Flex direction="column" mx="4">
            <Flex mt="4">
              <StatCard
                title="Hit Rate"
                value={`${hitRate ? hitRate.toFixed(1) : 0}%`}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      {currentUser?.role === "Admin" && (
        <>
          <Flex w="full" h="auto" mt="30px">
            <Flex direction="column" mx="4">
              <Flex direction="column" my="4">
                <Box
                  align="start"
                  w="auto"
                  h="auto"
                  border="1px"
                  borderColor="gray.100"
                  shadow="sm"
                  rounded="xl"
                  p="15px">
                  <Flex flexDirection="column">
                    <Text color="gray.500">Total Skiptracer Records</Text>

                    <Text fontSize={"35px"} fontWeight="bold" color="#51D55E">
                      {grandTotalSkiptracedRecords}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
            <Flex direction="column" mx="4">
              <Flex mt={4}>
                <Box
                  align="start"
                  w="auto"
                  h="auto"
                  border="1px"
                  borderColor="gray.100"
                  shadow="sm"
                  rounded="xl"
                  p="15px">
                  <Flex flexDirection="column" pr="8">
                    <Text color="gray.500">Total Fulfilled Orders</Text>
                    <Text
                      fontSize={"35px"}
                      fontWeight="bold"
                      color="brand.primary.main">
                      {grandTotalFulfilledOrders}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
            <Flex direction="column" mx="4">
              <Flex mt={4}>
                <Box
                  align="start"
                  w="auto"
                  h="auto"
                  border="1px"
                  borderColor="gray.100"
                  shadow="sm"
                  rounded="xl"
                  p="15px">
                  <Flex flexDirection="column" pr="8">
                    <Text color="gray.500">Total Money Owed</Text>
                    <Text
                      fontSize={"35px"}
                      fontWeight="bold"
                      color="brand.primary.main">
                      ${grandTotalMoneyEarned?.toFixed(2)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
            <Flex direction="column" mx="4">
              <Flex mt={4}>
                <Box
                  align="start"
                  w="auto"
                  h="auto"
                  border="1px"
                  borderColor="gray.100"
                  shadow="sm"
                  rounded="xl"
                  p="15px">
                  <Flex flexDirection="column" pr="8">
                    <Text color="gray.500">Total Average Records</Text>
                    <Text
                      fontSize={"35px"}
                      fontWeight="bold"
                      color="brand.primary.main">
                      {grandTotalAverageRecordsPerOrder?.toFixed(1)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>

            {grandTotalMoneyPaid ? (
              <Flex direction="column" mx="4">
                <Flex direction="column" my="4">
                  <Text fontWeight="black"></Text>
                  <Box
                    align="start"
                    w="auto"
                    h="auto"
                    border="1px"
                    borderColor="gray.100"
                    shadow="sm"
                    rounded="xl"
                    p="15px">
                    <Flex flexDirection="column">
                      <Text color="gray.500" fontSize="18px">
                        Total Money Paid
                      </Text>

                      <Text fontSize={"35px"} fontWeight="bold" color="#51D55E">
                        ${grandTotalMoneyPaid}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            ) : (
              ""
            )}
          </Flex>

          <Flex w="full" h="auto" mt="30px">
            <Flex direction="column" mx="4">
              <Flex mt={4}>
                <Box
                  align="start"
                  w="auto"
                  h="auto"
                  border="1px"
                  borderColor="gray.100"
                  shadow="sm"
                  rounded="xl"
                  p="15px">
                  <Flex flexDirection="column" pr="8">
                    <Text color="gray.500">Total Revenue Generated</Text>
                    <Text
                      fontSize={"35px"}
                      fontWeight="bold"
                      color="brand.primary.main">
                      ${grandTotalRevenueGenerated?.toFixed(2)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
            <Flex direction="column" mx="4">
              <Flex mt={4}>
                <Box
                  align="start"
                  w="auto"
                  h="auto"
                  border="1px"
                  borderColor="gray.100"
                  shadow="sm"
                  rounded="xl"
                  p="15px">
                  <Flex flexDirection="column" pr="8">
                    <Text color="gray.500">Total Profit Generated</Text>
                    <Text
                      fontSize={"35px"}
                      fontWeight="bold"
                      color="brand.primary.main">
                      ${grandTotalProfitGenerated?.toFixed(2)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
            <Flex direction="column" mx="4">
              <Flex mt={4}>
                <Box
                  align="start"
                  w="auto"
                  h="auto"
                  border="1px"
                  borderColor="gray.100"
                  shadow="sm"
                  rounded="xl"
                  p="15px">
                  <Flex flexDirection="column" pr="8">
                    <Text color="gray.500">Total Affiliate Orders</Text>
                    <Text
                      fontSize={"35px"}
                      fontWeight="bold"
                      color="brand.primary.main">
                      {grandTotalAffiliateOrders}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
            <Flex direction="column" mx="4">
              <Flex mt={4}>
                <Box
                  align="start"
                  w="auto"
                  h="auto"
                  border="1px"
                  borderColor="gray.100"
                  shadow="sm"
                  rounded="xl"
                  p="15px">
                  <Flex flexDirection="column" pr="8">
                    <Text color="gray.500">
                      Total Average Order Completion Time
                    </Text>
                    <Text
                      fontSize={"35px"}
                      fontWeight="bold"
                      color="brand.primary.main">
                      {grandTotalAvgOrderCompletionTime?.toFixed(1)}h
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default SkiptraceStats;
