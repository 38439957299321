import React, { useState } from "react";
import moment from "moment";
import {
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Text,
  Box,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  VStack,
  Spinner,
  useDisclosure,
  Select,
} from "@chakra-ui/react";
import { ImFilesEmpty } from "react-icons/im";
import { BsSearch } from "react-icons/bs";
import { AiOutlineFolderView } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { Badge, DeleteModal, TakeOrderModal, CButton } from "../../components";
import FileUploadModal from "./FileUplaodModal";
import { ISingleSkiptrace, ICurrentUser } from "../../interfaces";
import { calculateDueInTime } from "../../utils";
import Confirm from "../Confirm";
import CustomerHistoryModal from "../CustomerHistoryModal";

interface SingleTraceTableProps {
  singleSkiptraceData: ISingleSkiptrace[] | undefined;
  onFilterClick: () => void;
  isLoading: boolean;
  onSingleModalSubmit: (
    recordId: string,
    file: File,
    skiptraced: boolean,
    reviewReason: string
  ) => void;
  loadingSingleSkiptraceOutput: boolean;
  onDeleteOutput: (recordId: string, onClose: () => void) => void;
  onApprovedSkipTrace: (recordId: string, onClose: () => void) => void;
  isLoadingApprovedSkiptrace: boolean;
  isLoadingDeleteOutput: boolean;
  isSingleLeadModalOpen: boolean;
  onSingleLeadModalClose: () => void;
  onSingleLeadModalOpen: () => void;
  status: string;
  query: string;
  recordId: string;
  disputeRecordId: string;
  setRecordId: (id: string) => void;
  setStatus: (status: string) => void;
  setQuery: (query: string) => void;
  handleClear: () => void;
  onTakeOrder: (recordId: string, onClose: () => void) => void;
  setDisputeRecordId: (recordId: string) => void;
  handleDisputeOrder: (_id: string, isResolved: boolean) => void;
  takeOrderLoading: boolean;
  currentUser: ICurrentUser | null;
  stateLoading: boolean;
  disputeLoading: boolean;
}

const tableHeading = [
  "Date",
  "User Name",
  "List Name",
  "Address",
  "Status",
  "Input File",
  "Due In",
  "Output File",
  "Sale Type",
  "SkipTracer Email",
  "Dispute Action",
];

export default function CSingleTrace({
  onFilterClick,
  singleSkiptraceData,
  isLoading,
  onSingleModalSubmit,
  loadingSingleSkiptraceOutput,
  onDeleteOutput,
  isLoadingDeleteOutput,
  isSingleLeadModalOpen,
  onSingleLeadModalClose,
  onSingleLeadModalOpen,
  query,
  recordId,
  setRecordId,
  setQuery,
  setStatus,
  status,
  handleClear,
  isLoadingApprovedSkiptrace,
  onApprovedSkipTrace,
  onTakeOrder,
  takeOrderLoading,
  currentUser,
  stateLoading,
  handleDisputeOrder,
  disputeLoading,
  disputeRecordId,
  setDisputeRecordId,
}: SingleTraceTableProps) {
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

  const {
    isOpen: isTakeOrderOpen,
    onClose: onTakeOrderClose,
    onOpen: onTakeOrderOpen,
  } = useDisclosure();

  const { isOpen: isDisputeOpen, onOpen: onDisputeOpen } = useDisclosure();

  const {
    isOpen: isHistoryModalOpen,
    onClose: onHistoryModalClose,
    onOpen: onHistoryModalOpen,
  } = useDisclosure();

  const [takeOrderId, setTakeOrderId] = useState<string>("");
  const [fileId, setFileId] = useState<string | null>(null);
  const [isResolved, setIsResolved] = useState(false);
  const [customerID, setCustomerId] = useState<string>("");

  const handleFilterClick = () => {
    onFilterClick();
  };

  const handleModal = (id: string) => {
    onSingleLeadModalOpen();
    setRecordId(id);
  };

  const handleTakeOrder = (id: string) => {
    setTakeOrderId(id);
    onTakeOrderOpen();
  };
  const handleDeleteModal = (id: string) => {
    setRecordId(id);
    onDeleteOpen();
  };

  const onDisputeOrder = () => {
    handleDisputeOrder(disputeRecordId, false);
  };

  const onDisputeOrderResolved = () => {
    handleDisputeOrder(disputeRecordId, isResolved);
  };

  const handleCustomerClick = (uid: string) => {
    setCustomerId(uid);
    onHistoryModalOpen();
  };

  return (
    <>
      <CustomerHistoryModal
        onClose={onHistoryModalClose}
        isOpen={isHistoryModalOpen}
        loading={false}
        id={customerID}
      />
      <Text fontWeight="500" fontStyle="normal" fontSize="30px" color="#393330">
        Single Skip Trace
      </Text>
      <Box>
        <VStack spacing="2" align="flex-end" shadow="sm" rounded="md" p="4">
          <HStack justify="flex-end" spacing="4">
            <Select
              w="xs"
              rounded="md"
              size="sm"
              placeholder="Select Status"
              color="#737373"
              _focus={{ borderColor: "brand.primary.main" }}
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="success">Completed</option>
              <option value="needsReview">Needs Review</option>
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
            </Select>
            <InputGroup size="sm" w="60">
              <InputLeftElement pointerEvents="none">
                <BsSearch color="#FF824C" />
              </InputLeftElement>
              <Input
                rounded="md"
                id="searchQuery"
                color="#737373"
                type="text"
                focusBorderColor="brand.primary.light"
                placeholder="Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </InputGroup>
            <CButton
              onClick={() => handleClear()}
              id="clearbutton"
              variant="outline"
              size="sm"
              w="28"
              fontSize="16px"
              bgColor="#fff"
              border="1px solid rgb(255, 130, 76)"
              color="rgb(255, 130, 76)"
              _focus={{
                offset: "0",
              }}
            >
              Clear
            </CButton>
            <CButton
              onClick={handleFilterClick}
              id="filterbutton"
              variant="solid"
              size="sm"
              w="28"
              fontSize="16px"
              bgColor="brand.primary.main"
              border="1px solid rgb(255, 130, 76)"
              color="#fff"
              _focus={{
                offset: "0",
              }}
              isLoading={isLoading && stateLoading}
            >
              Filter
            </CButton>
          </HStack>
        </VStack>
        <Box overflowX="auto">
          <Table mt="30px" mb="30px" variant="simple">
            <Thead>
              <Tr shadow="sm">
                {tableHeading.map((heading, i) => (
                  <Th
                    fontSize="15px"
                    color="#2F3442"
                    fontWeight="600"
                    textAlign="center"
                    key={i}
                  >
                    {heading}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody shadow="inner">
              {singleSkiptraceData?.map(
                (record: ISingleSkiptrace, index: number) => (
                  <Tr key={index} fontSize="14px">
                    <Td textAlign="center" color="#737373" minW="36">
                      {moment(record.createdAt).format("MM-DD-YYYY")}
                    </Td>
                    {currentUser?.role === "Admin" ? (
                      <Td
                        cursor="pointer"
                        onClick={() => handleCustomerClick(record.uid)}
                        minW="60"
                        textAlign="center"
                        color="brand.primary.main"
                        _hover={{
                          textDecor: "underline",
                        }}
                      >
                        {record.userName}
                      </Td>
                    ) : (
                      <Td textAlign="center" color="#737373" minW="60">
                        {record.userName}
                      </Td>
                    )}

                    <Td textAlign="center" color="#737373" minW="36">
                      {record.name}
                    </Td>
                    <Td textAlign="center" color="#737373" minW="52">
                      {record.propertyAddress.address
                        ? record.propertyAddress.address
                        : ""}
                    </Td>
                    <Td textAlign="center" minW="170px" color="#737373">
                      <Badge variant={record.status.toLowerCase()}>
                        {record?.status === "success"
                          ? "Completed"
                          : record?.status}
                      </Badge>
                      {record?.status === "pending" && (
                        <CButton
                          onClick={() => handleTakeOrder(record?._id)}
                          id="takeOrderbutton"
                          variant="solid"
                          size="sm"
                          mt="4"
                          w="28"
                          fontSize="16px"
                          bgColor="#fff"
                          border="1px solid rgb(255, 130, 76)"
                          color="rgb(255, 130, 76)"
                          _focus={{
                            offset: "0",
                          }}
                          isLoading={takeOrderLoading}
                        >
                          Take Order
                        </CButton>
                      )}
                    </Td>
                    <Td textAlign="center" color="#737373">
                      <CButton
                        id="viewFile"
                        variant="solid"
                        fontSize="14px"
                        bgColor="brand.primary.main"
                        border="1px solid rgb(255, 130, 76)"
                        color="#fff"
                        _focus={{
                          offset: "0",
                        }}
                      >
                        <a
                          href={record?.inputUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Text fontWeight="light">View File</Text>
                        </a>
                      </CButton>
                    </Td>
                    <Td textAlign="center" minW="170px" color="#737373">
                      {record.status === "processing" ||
                      record.status === "needsReview"
                        ? calculateDueInTime(record.due, "singleLead")
                        : null}
                    </Td>
                    <Td textAlign="center">
                      {record.outputUrl ? (
                        <>
                          <VStack>
                            <CButton
                              id="viewOutputFile"
                              variant="solid"
                              fontSize="14px"
                              bgColor="brand.primary.main"
                              border="1px solid rgb(255, 130, 76)"
                              color="#fff"
                              leftIcon={<AiOutlineFolderView />}
                              _focus={{
                                offset: "0",
                              }}
                            >
                              <a
                                href={record.outputUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Text fontWeight="light">View File</Text>
                              </a>
                            </CButton>
                            {currentUser?.role === "Admin" && (
                              <Button
                                onClick={() => {
                                  handleDeleteModal(record._id);
                                }}
                                id="SLDelete"
                                variant="outline"
                                backgroundColor="#fff"
                                p="10px 18px"
                                colorScheme="red"
                                leftIcon={<MdDelete />}
                                _focus={{
                                  offset: "0",
                                }}
                                _hover={{
                                  backgroundColor: "red",
                                  color: "white",
                                  transform: "scale(.97)",
                                  transition: ".3s ease-in-out",
                                }}
                              >
                                Delete
                              </Button>
                            )}
                          </VStack>
                        </>
                      ) : (
                        <CButton
                          onClick={() => handleModal(record._id)}
                          id="SLdownloadLink"
                          variant="solid"
                          bgColor="brand.primary.main"
                          border="1px solid #FF824C"
                          color="#fff"
                          fontSize="15px"
                          leftIcon={<ImFilesEmpty />}
                          _focus={{
                            offset: "0",
                          }}
                        >
                          <Text fontWeight="light">Upload File</Text>
                        </CButton>
                      )}
                    </Td>
                    {record.status === "needsReview" &&
                      currentUser?.role === "Admin" && (
                        <Td>
                          <Confirm
                            isLoading={isLoadingApprovedSkiptrace}
                            handleClose={() => setFileId(null)}
                            handleConfirm={() =>
                              onApprovedSkipTrace(record._id, () =>
                                setFileId(null)
                              )
                            }
                            isOpen={fileId === record._id ? true : false}
                            description="Are you sure you want to approve this order?"
                          >
                            <Button
                              onClick={() => {
                                setFileId(record._id);
                              }}
                              id="uploadFileBtn"
                              variant="outline"
                              fontSize="15px"
                              borderColor="#FF824C"
                              color="#FF824C"
                              leftIcon={<ImFilesEmpty />}
                              _focus={{
                                offset: "0",
                              }}
                              _active={{
                                backgroundColor: "brand.primary.main",
                                color: "#fff",
                              }}
                              _hover={{
                                backgroundColor: "brand.primary.main",
                                color: "#fff",
                                transform: "scale(.97)",
                                transition: ".3s ease-in-out",
                              }}
                            >
                              <Text fontWeight="light">Approved</Text>
                            </Button>
                          </Confirm>
                        </Td>
                      )}
                    {record.reviewReason && record.status === "needsReview" && (
                      <Td textAlign="center" color="#737373">
                        <Box w="64">{record.reviewReason}</Box>
                      </Td>
                    )}
                    <Td textAlign="center" color="#737373" minW="44">
                      <Text textTransform="capitalize">{record?.saleType}</Text>
                    </Td>
                    <Td textAlign="center" color="#737373" minW="56">
                      <Text>{record?.assigneeEmail}</Text>
                    </Td>
                    {currentUser?.role === "Admin" && (
                      <Td textAlign="center" color="#737373" minW="56">
                        {record.status === "disputed" ? (
                          <Confirm
                            handleClose={() => setDisputeRecordId("")}
                            handleConfirm={onDisputeOrderResolved}
                            description="Does order issue resolved?"
                            isOpen={
                              isDisputeOpen && disputeRecordId === record._id
                            }
                            isLoading={disputeLoading}
                          >
                            <Button
                              onClick={() => {
                                setDisputeRecordId(record._id);
                                onDisputeOpen();
                                setIsResolved(true);
                              }}
                              id="SLdownloadLink"
                              variant="solid"
                              bgColor="brand.primary.main"
                              border="1px solid #FF824C"
                              color="#fff"
                              fontSize="15px"
                              _focus={{
                                offset: "0",
                              }}
                              _active={{
                                backgroundColor: "brand.primary.main",
                                color: "#fff",
                              }}
                              _hover={{
                                backgroundColor: "brand.primary.main",
                                color: "#fff",
                                transform: "scale(.97)",
                                transition: ".3s ease-in-out",
                              }}
                            >
                              <Text fontWeight="light">Resolve Dispute</Text>
                            </Button>
                          </Confirm>
                        ) : (
                          <Confirm
                            handleClose={() => setDisputeRecordId("")}
                            handleConfirm={onDisputeOrder}
                            description="Are you sure to make this order disputed?"
                            isOpen={
                              isDisputeOpen && disputeRecordId === record._id
                            }
                            isLoading={disputeLoading}
                          >
                            <Button
                              onClick={() => {
                                setDisputeRecordId(record._id);
                                onDisputeOpen();
                              }}
                              id="SLdownloadLink"
                              variant="solid"
                              bgColor="brand.primary.main"
                              border="1px solid #FF824C"
                              color="#fff"
                              fontSize="15px"
                              _focus={{
                                offset: "0",
                              }}
                              _active={{
                                backgroundColor: "brand.primary.main",
                                color: "#fff",
                              }}
                              _hover={{
                                backgroundColor: "brand.primary.main",
                                color: "#fff",
                                transform: "scale(.97)",
                                transition: ".3s ease-in-out",
                              }}
                            >
                              <Text fontWeight="light">Dispute Order</Text>
                            </Button>
                          </Confirm>
                        )}
                      </Td>
                    )}
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </Box>

        {isLoading && (
          <Flex id="loadingSppiner" w="full" justifyContent="center">
            <Spinner color="brand.primary.main" thickness="2px" size="md" />
          </Flex>
        )}
        {singleSkiptraceData?.length === 0 && (
          <Box mt="30px" w="100%" h="300px" color="#737373" fontWeight="400">
            <Text textAlign="center" id="noRecords">
              No records available
            </Text>
          </Box>
        )}
      </Box>

      <FileUploadModal
        isOpen={isSingleLeadModalOpen}
        onClose={onSingleLeadModalClose}
        onSingleModalSubmit={onSingleModalSubmit}
        loadingSingleSkiptraceOutput={loadingSingleSkiptraceOutput}
        recordId={recordId}
      />

      <DeleteModal
        onClose={onDeleteClose}
        isOpen={isDeleteOpen}
        onDelete={onDeleteOutput}
        id={recordId}
        loading={isLoadingDeleteOutput}
      />

      <TakeOrderModal
        onClose={onTakeOrderClose}
        isOpen={isTakeOrderOpen}
        onTakeOrder={onTakeOrder}
        id={takeOrderId}
        loading={takeOrderLoading}
      />
    </>
  );
}
