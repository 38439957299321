import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Input,
  Box,
} from "@chakra-ui/react";

import CButton from "../../Shared/Button";

import { CreditsOptionsProps } from "../../../interfaces/customer.interface";

interface CreditsModalProps {
  onClose: () => void;
  onSubmit: () => void;
  onCreditsChange: (balance: CreditsOptionsProps["balance"]) => void;
  isOpen: boolean;
  isLoading: boolean;
}

export default function CreditsModal({
  isOpen,
  onClose,
  onSubmit,
  onCreditsChange,
  isLoading,
}: CreditsModalProps) {
  const closeModal = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} id="takeOrderModal" onClose={closeModal} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="brand.primary.main">Add Balance</ModalHeader>
        <ModalCloseButton
          _focus={{
            color: "brand.primary.main",
          }}
        />
        <ModalBody pb="25px" w="full">
          <VStack w="full" alignItems="flex-start" spacing="14">
            <Box w="full">
              <Input
                type="number"
                onChange={(e) =>
                  onCreditsChange({ value: parseFloat(e.target.value) })
                }
                defaultValue={0}
              />
            </Box>

            <HStack pt="4" w="full" justifyContent="flex-end">
              <CButton
                onClick={onSubmit}
                variant="solid"
                backgroundColor="brand.primary.main"
                border="1px solid #FF824C"
                color="#fff"
                _focus={{
                  offset: "0",
                }}
                isLoading={isLoading}
              >
                Update
              </CButton>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
