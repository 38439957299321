import React from "react";
import { Box, VStack, Skeleton } from "@chakra-ui/react";

const CardSkeleton = () => {
  return (
    <Box
      pos="relative"
      p="3.5"
      borderWidth="thin"
      borderColor="gray.50"
      textAlign="center"
      shadow="sm"
      rounded="md"
    >
      <VStack py="2.5">
        <Skeleton height="8" w="8" rounded="md" />
      </VStack>
      <Skeleton height="3" rounded="sm" />
    </Box>
  );
};

export default CardSkeleton;
