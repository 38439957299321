import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  HStack,
} from "@chakra-ui/react";

interface IActivateUserModal {
  onClose: () => void;
  isOpen: boolean;
  onConfirm: () => void;
  loading: boolean;
}

function ActivateUserModal(props: IActivateUserModal) {
  const { isOpen, onClose, onConfirm, loading } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} id="passwordUpdateModal" onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb="25px">
            <Text
              textAlign="center"
              mb="5"
              fontSize="2xl"
              fontWeight="semibold"
            >
              Are you sure?
            </Text>
            <HStack w="full" justifyContent="center">
              <Button
                id="confirmBtn"
                variant="solid"
                colorScheme="orange"
                bgColor="brand.primary.main"
                isLoading={loading}
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
              >
                Yes
              </Button>
              <Button
                id="noBtn"
                variant="outline"
                colorScheme="orange"
                bgColor="#fff"
                onClick={onClose}
              >
                No
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ActivateUserModal;
