import React, { useState } from "react";
import {
  Box,
  Flex,
  HStack,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Spinner,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  Select,
  Input,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";
import CustomerHistoryModal from "../CustomerHistoryModal";
import TagsModal from "./TagsModal";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { AiOutlineTags } from "react-icons/ai";
import { DatePicker, CButton, Badge } from "..";
import { ICustomersProps, TagsOptionsProps } from "../../interfaces";
import ReferralCodeUpdateModal from "./ReferralCodeUpdateModal";
import { CSVLink } from "react-csv";
import { FaUserSlash } from "react-icons/fa";
import { FiCreditCard, FiUserPlus } from "react-icons/fi";
import ActivateUserModal from "../Users/ActivateUserModal";
import CreditsModal from "./CreditsModal";
import { CreditsOptionsProps } from "../../interfaces/customer.interface";

const tableHeadings = [
  "Date",
  "Name",
  "Email",
  "Phone Number",
  "Business Name",
  "LoggedIn Count",
  "Orders Purchased Count",
  "Money Spent",
  "Balance",
  "Referral Code",
  "Affiliate Status",
  "Tags",
  "Actions",
];

export default function Customers(props: ICustomersProps) {
  const [tagsOptions, setTagsOptions] = useState<TagsOptionsProps["tags"]>([]);
  const [creditsOptions, setcreditOptions] = useState<
    CreditsOptionsProps["balance"]
  >({ value: 0 });

  const [cid, setCid] = useState<string>("");
  const [isAdd, setIsAdd] = useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    isOpen: isReferralCodeUpdateModalOpen,
    onClose: onReferralCodeUpdateModalClose,
    onOpen: onReferralCodeUpdateModalOpen,
  } = useDisclosure();

  const {
    isOpen: isActivateUserModalOpen,
    onClose: onActivateUserModalClose,
    onOpen: onActivateUserModalOpen,
  } = useDisclosure();

  const {
    customersData,
    isLoading,
    handleOnDataPicker,
    fromDate,
    toDate,
    onFilterClearClick,
    formatedDataForCsvDownload,
    handleAffiliateStatus,
    isLoadingAffiliateStatus,
    id,
    setId,
    affiliateStatus,
    onSetAffiliateStatus,
    isTagsOpen,
    isCreditsOpen,
    onCreditsOpen,
    oncreditsClose,
    onTagsClose,
    onTagsOpen,
    handleUpdateCustomerTags,
    handleUpdateCustomerCredits,
    tagsLoading,
    creditsLoading,

    onSetTag,
    tag,
    handleAddTag,
    tagLoading,
    tags,
    affiliateStatusModal,
    onSetAffiliateStatusModal,
    referralCode,
    setReferralCode,
    handleReferralCodeUpdate,
    isLoadingUpdateReferralCode,
    onChangeCustomerName,
    name,
    handleUpdateCustomer,
    setEvent,
  } = props;

  const handleClear = () => {
    onFilterClearClick();
  };

  const handleActivateUserModal = (event: { name: string; payload: any }) => {
    setEvent(event);
    onActivateUserModalOpen();
  };

  const handleAffiliateStatusClick = (id: string) => {
    handleAffiliateStatus(id);
    setCid(id);
  };

  const handleModal = (id: string, affiliateStatus: string) => {
    setId(id);
    onSetAffiliateStatusModal(affiliateStatus);
    onOpen();
  };

  const handleReferralCodeModal = (id: string, referralCode: string) => {
    setId(id);
    setReferralCode(referralCode);
    onReferralCodeUpdateModalOpen();
  };

  return (
    <>
      <Text fontWeight="500" fontStyle="normal" fontSize="30px" color="#393330">
        Customers
      </Text>

      <TagsModal
        tagLoading={tagLoading}
        isAdd={isAdd}
        defaultValue={tagsOptions}
        isOpen={isTagsOpen}
        onClose={onTagsClose}
        onSubmit={() => handleUpdateCustomerTags(cid, tagsOptions)}
        onTagsChange={(e) => setTagsOptions(e)}
        isLoading={tagsLoading}
        handleAddTag={handleAddTag}
        options={tags}
      />

      <CreditsModal
        isOpen={isCreditsOpen}
        onClose={oncreditsClose}
        onSubmit={() => handleUpdateCustomerCredits(cid, creditsOptions)}
        onCreditsChange={(e) => setcreditOptions(e)}
        isLoading={creditsLoading}
      />

      <Box>
        <Flex mt="30px" justifyContent="space-between">
          <HStack>
            <CButton
              id="downloadInvoiceButton"
              variant="solid"
              size="sm"
              leftIcon={<HiOutlineDocumentDownload />}
              fontSize="16px"
              bgColor="brand.primary.main"
              border="1px solid rgb(255, 130, 76)"
              color="#fff"
              _focus={{
                offset: "0",
              }}
            >
              <CSVLink
                data={formatedDataForCsvDownload()}
                filename={`customers-${moment().format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}`}
              >
                Download Stats
              </CSVLink>
            </CButton>
            <CButton
              onClick={() => {
                onTagsOpen();
                setIsAdd(true);
              }}
              size="sm"
              variant="solid"
              minW="40"
              leftIcon={<AiOutlineTags size={20} />}
              bgColor="brand.primary.main"
              border="1px solid rgb(255, 130, 76)"
              color="#fff"
              _focus={{
                offset: "0",
              }}
            >
              <Text fontWeight="light">Add Tag</Text>
            </CButton>
          </HStack>
          <HStack>
            <VStack>
              <Box>
                <DatePicker
                  handleOnDataPicker={handleOnDataPicker}
                  fromDate={fromDate}
                  toDate={toDate}
                />
              </Box>
              <FormControl w="250px" id="affiliate-status">
                <Select
                  size="sm"
                  color="gray.400"
                  focusBorderColor="brand.primary.light"
                  py="0.5"
                  placeholder="Select User"
                  onChange={(e) => {
                    onSetAffiliateStatus(e.target.value);
                  }}
                  value={affiliateStatus}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="requested">Requested</option>
                </Select>
                <FormErrorMessage>{null}</FormErrorMessage>
              </FormControl>
            </VStack>
            <VStack>
              <FormControl w="250px" id="affiliate-status">
                <Select
                  size="sm"
                  color="gray.400"
                  focusBorderColor="brand.primary.light"
                  py="0.5"
                  placeholder="Select Tag"
                  onChange={(e) => {
                    onSetTag(e.target.value);
                  }}
                  value={tag}
                >
                  {tags?.map((tag, i) => (
                    <option key={i} value={tag.value}>
                      {tag.label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{null}</FormErrorMessage>
              </FormControl>
              <FormControl w="250px">
                <Input
                  size="sm"
                  onChange={(e) => onChangeCustomerName(e.target.value)}
                  value={name}
                  placeholder="search by name"
                  focusBorderColor="brand.primary.light"
                />
              </FormControl>
            </VStack>
            <CButton
              onClick={() => handleClear()}
              id="filterClearbutton"
              variant="solid"
              size="sm"
              w="28"
              fontSize="16px"
              bgColor="#fff"
              border="1px solid rgb(255, 130, 76)"
              color="rgb(255, 130, 76)"
              _focus={{
                offset: "0",
              }}
            >
              <Text fontWeight="light">Clear</Text>
            </CButton>
          </HStack>
        </Flex>

        <Box overflowX="auto" overflowY="hidden">
          <Table mx="auto" w="100%" mt="30px" mb="30px" variant="simple">
            <Thead>
              <Tr boxShadow="base">
                {tableHeadings.map((heading, i) => (
                  <Th
                    fontSize="15px"
                    color="#2F3442"
                    fontWeight="600"
                    textAlign="center"
                    key={i}
                    minWidth="10px"
                  >
                    {heading}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody w="full">
              {!isLoading &&
                customersData &&
                customersData?.map((customerData) => (
                  <Tr fontSize="14px">
                    <Td textAlign="center" color="#737373" minW="44">
                      {moment(customerData?.createdAt).format("MM-DD-YYYY")}
                    </Td>
                    <Td
                      _hover={{
                        textDecor: "underline",
                      }}
                      minW="56"
                      cursor="pointer"
                      textAlign="center"
                      color="brand.primary.main"
                      onClick={() =>
                        handleModal(
                          customerData._id,
                          customerData.affiliateStatus
                        )
                      }
                    >
                      {customerData?.name}
                    </Td>
                    <Td minW="72" textAlign="center" color="#737373">
                      {customerData?.email}
                    </Td>
                    <Td minW="52" textAlign="center" color="#737373">
                      {customerData?.phoneNumber || "N/A"}
                    </Td>
                    <Td minW="44" maxW="80" textAlign="center" color="#737373">
                      {customerData?.businessName}
                    </Td>

                    <Td maxW="72" textAlign="center" color="#737373">
                      {customerData?.numberOfTimesLoggedIn}
                    </Td>
                    <Td textAlign="center" color="#737373">
                      {customerData?.numberOfOrdersPurchased}
                    </Td>
                    <Td maxW="72" textAlign="center" color="#737373">
                      {customerData?.moneySpent?.toFixed(2)}
                    </Td>
                    <Td maxW="72" textAlign="center" color="#737373">
                      {customerData?.balance?.toFixed(2)}
                    </Td>
                    <Td
                      maxW="72"
                      textAlign="center"
                      cursor="pointer"
                      color="brand.primary.main"
                      onClick={() =>
                        customerData?.referralCode &&
                        handleReferralCodeModal(
                          customerData._id,
                          customerData.referralCode
                        )
                      }
                    >
                      {customerData?.referralCode
                        ? customerData?.referralCode
                        : ""}
                    </Td>
                    <Td>
                      {customerData?.affiliateStatus === "requested" ||
                      customerData?.affiliateStatus === "inactive" ? (
                        <VStack>
                          <CButton
                            onClick={() =>
                              handleAffiliateStatusClick(customerData?._id)
                            }
                            id="affiliateButton"
                            variant="solid"
                            fontSize="14px"
                            bgColor="brand.primary.main"
                            border="1px solid rgb(255, 130, 76)"
                            color="#fff"
                            _focus={{
                              offset: "0",
                            }}
                            isLoading={
                              isLoadingAffiliateStatus &&
                              customerData?._id === cid
                            }
                          >
                            <Text fontWeight="light">Activate Affiliate</Text>
                          </CButton>
                          <Badge variant={customerData?.affiliateStatus}>
                            {customerData?.affiliateStatus}
                          </Badge>
                        </VStack>
                      ) : (
                        <Badge variant={customerData?.affiliateStatus}>
                          {customerData?.affiliateStatus}
                        </Badge>
                      )}
                    </Td>
                    <Td>
                      <HStack>
                        {customerData.tags?.map((tag) => (
                          <Box>
                            <Badge variant="tag">{tag.label}</Badge>
                          </Box>
                        ))}
                        <CButton
                          onClick={() => {
                            onTagsOpen();
                            setTagsOptions(customerData.tags);
                            setCid(customerData._id);
                            setIsAdd(false);
                          }}
                          size="sm"
                          variant="outline"
                          minW="36"
                          leftIcon={<AiOutlineTags size={20} />}
                          color="brand.primary.main"
                          bg="transparent"
                          border="1px solid rgb(255, 130, 76)"
                          _focus={{
                            offset: "0",
                            color: "#fff",
                            bg: "brand.primary.main",
                          }}
                        >
                          <Text fontWeight="light">Add/Remove</Text>
                        </CButton>
                      </HStack>
                    </Td>

                    <Td textAlign="center" color="#737373">
                      {false ? (
                        "N/A"
                      ) : (
                        <HStack mx="auto">
                          <Tooltip
                            label={
                              customerData.lockout
                                ? "Activate User"
                                : "Deactivate User"
                            }
                            bg={customerData.lockout ? "green.600" : "red.600"}
                            fontSize="xs"
                          >
                            <IconButton
                              onClick={() => {
                                handleActivateUserModal({
                                  name: customerData.lockout
                                    ? "activate"
                                    : "deactivate",
                                  payload: {
                                    id: customerData._id,
                                  },
                                });
                              }}
                              id="SLDelete"
                              icon={
                                customerData.lockout ? (
                                  <FiUserPlus />
                                ) : (
                                  <FaUserSlash />
                                )
                              }
                              _focus={{
                                offset: "0",
                              }}
                              colorScheme={
                                customerData.lockout ? "green" : "red"
                              }
                              aria-label="reset-icon"
                              fontSize="14px"
                              size="sm"
                            />
                          </Tooltip>
                          <Tooltip
                            label={"Add Balance"}
                            bg={"green.600"}
                            fontSize="xs"
                          >
                            <IconButton
                              onClick={() => {
                                onCreditsOpen();
                                setCid(customerData._id);
                              }}
                              id="SAdd"
                              icon={<FiCreditCard />}
                              _focus={{
                                offset: "0",
                              }}
                              colorScheme={"green"}
                              aria-label="addcredit-icon"
                              fontSize="14px"
                              size="sm"
                            />
                          </Tooltip>
                        </HStack>
                      )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          <ActivateUserModal
            isOpen={isActivateUserModalOpen}
            onClose={onActivateUserModalClose}
            loading={false}
            onConfirm={() => handleUpdateCustomer()}
          />
        </Box>
        {isLoading && (
          <Flex w="full" justifyContent="center">
            <Spinner color="brand.primary.main" thickness="2px" size="md" />
          </Flex>
        )}

        {!isLoading && customersData?.length === 0 && (
          <Box mt="30px" w="100%" h="300px" color="#737373" fontWeight="400">
            <Text textAlign="center">No records available</Text>
          </Box>
        )}
      </Box>

      <CustomerHistoryModal
        onClose={onClose}
        isOpen={isOpen}
        loading={false}
        id={id}
        affiliateStatusModal={affiliateStatusModal}
      />
      <ReferralCodeUpdateModal
        isOpen={isReferralCodeUpdateModalOpen}
        onClose={onReferralCodeUpdateModalClose}
        userId={id}
        referralCode={referralCode}
        updateReferralCode={handleReferralCodeUpdate}
        isLoadingUpdateReferralCode={isLoadingUpdateReferralCode}
      />
    </>
  );
}
