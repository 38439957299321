import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Spinner,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { CButton } from "../..";

interface FileUploadModalProps {
  onClose: () => void;
  isOpen: boolean;
}

function FileUploadModal(props: FileUploadModalProps) {
  const { isOpen, onClose } = props;

  return (
    <>
      <Modal isOpen={isOpen} id="bulkFileUploadModal" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload File</ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb="25px">
            <form>
              <Stack spacing={4}>
                <Box border="3px dashed gray" p="10px">
                  <Dropzone
                    disabled={false}
                    onDrop={(acceptedFiles) => console.log(acceptedFiles[0])}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {false ? (
                            <Flex justify="center" alignItems="center">
                              <Spinner
                                color="brand.primary.main"
                                thickness="2px"
                                size="md"
                              />
                            </Flex>
                          ) : (
                            <Text align="center">Click here to upload</Text>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Box>
                <FormControl id="records">
                  <FormLabel color="gray" fontSize="sm">
                    Number of records skiptraced
                  </FormLabel>
                  <Input
                    _focus={{
                      borderColor: "brand.primary.main",
                    }}
                    type="number"
                  />
                  <FormErrorMessage>Error</FormErrorMessage>
                </FormControl>
                <CButton
                  variant="solid"
                  p="10px"
                  fontSize="1rem"
                  bgColor="brand.primary.main"
                  border="1px solid #FF824C"
                  color="#fff"
                  _focus={{
                    offset: "0",
                  }}
                >
                  <Text>Fulfil Order</Text>
                </CButton>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FileUploadModal;
