import React from "react";
import { useRadioGroup, HStack, Box, useRadio } from "@chakra-ui/react";

interface RadioGroupProps {
  onChange: (value: string) => void;
  options: { key: string; value: string | number }[];
}

const RadioGroup = ({ onChange, options }: RadioGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "percentage",
    defaultValue: 25,
    onChange,
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {options.map(({ key, value }) => {
        const radio = getRadioProps({ key, value });
        return (
          <RadioCard key={key} {...radio}>
            {key}
          </RadioCard>
        );
      })}
    </HStack>
  );
};

export default RadioGroup;

function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        _checked={{
          bg: "brand.primary.main",
          color: "white",
          borderColor: "orange.100",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py="8px"
      >
        {props.children}
      </Box>
    </Box>
  );
}
