import { createSlice } from "@reduxjs/toolkit";
import type { IAffiliateSlice } from "../../interfaces";

const affiliatesSlice = createSlice({
  name: "admin",
  initialState: {
    formState: {
      startDate: null,
      endDate: null,
    },
  } as IAffiliateSlice,
  reducers: {
    setFromDate: (state, action) => {
      state.formState = { ...state.formState, ...action.payload };
    },
    clearFormState: (state, action) => {
      state.formState = {
        startDate: null,
        endDate: null,
      };
    },
  },
});

export const { setFromDate, clearFormState } = affiliatesSlice.actions;

export default affiliatesSlice.reducer;
