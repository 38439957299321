import React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

interface CButtonProps extends ButtonProps {
  onClick?: () => void;
  variant: "solid" | "outline";
  children: React.ReactNode;
  ref?: React.MutableRefObject<any>;
}

export default function CButton(props: CButtonProps) {
  const { onClick, variant, size, title, children, ...otherProps } = props;
  const buttonVariants = {
    solid: {
      backgroundColor: "#fff",
      color: "rgb(255, 130, 76)",
    },
    outline: {
      color: "#fff",
      backgroundColor: "rgb(255, 130, 76)",
    },
  };
  return (
    <Button
      onClick={onClick}
      _focus={{
        offset: "0",
      }}
      _active={buttonVariants[variant]}
      _hover={{
        ...buttonVariants[variant],
        transform: "scale(.97)",
        transition: ".3s ease-in-out",
      }}
      size={size}
      {...otherProps}
    >
      {children}
    </Button>
  );
}
