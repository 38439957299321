import { useMutation } from "react-query";
import { API } from "../constants";
import { apiClient } from "../services";

export const useGetSingleSkiptraceRecords = () => {
  const getSingleSkiptracerecords = async (body: {
    query?: string;
    status?: string;
  }) => {
    const { status, query } = body;
    const { data } = await apiClient.get(
      `${API.ROUTES.ADMIN_SINGLE_SKIPTRACE}?query=${query}&status=${status}`
    );
    return data.records;
  };

  return useMutation(getSingleSkiptracerecords);
};

export const useSingleSkiptraceOutput = () => {
  const uploadFile = async (body: {
    recordId: string;
    file: File;
    skiptraced: boolean;
    type: string;
    reviewReason: string;
  }) => {
    const data = {
      recordId: body.recordId,
      skiptraced: body.skiptraced,
      type: body.type,
      reviewReason: body.reviewReason,
    };

    const formData = new FormData();

    formData.append("filename", body.file);
    formData.append("fileProps", JSON.stringify(data));
    const res = await apiClient.post(
      API.ROUTES.ADMIN_SKIPTRACE_OUTPUT,
      formData
    );
    return res;
  };
  return useMutation(uploadFile);
};
