import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  HStack,
  Link,
} from "@chakra-ui/react";
import { CButton } from "../../components";

interface CustomerHistoryModalProps {
  onClose: () => void;
  isOpen: boolean;
  loading: boolean;
  id: string;
  affiliateStatusModal?: string;
}

export default function CustomerHistoryModal(props: CustomerHistoryModalProps) {
  const { isOpen, onClose, loading, id, affiliateStatusModal } = props;

  const closeModal = () => {
    onClose();
  };
  return (
    <>
      <Modal isOpen={isOpen} id="customerHistoryModal" onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="lg">
          <ModalHeader> Customer History Modal</ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody p="40px">
            <Stack spacing={4} alignItems="center">
              <HStack>
                <Link
                  href={`/customers/customer-purchase-history?id=${id}`}
                  _hover={{ textDecoration: "none" }}
                >
                  <CButton
                    variant="solid"
                    p="10px"
                    fontSize="14px"
                    backgroundColor="brand.primary.main"
                    border="1px solid #FF824C"
                    color="#fff"
                    _focus={{
                      offset: "0",
                    }}
                    isLoading={loading}
                  >
                    Purchase History
                  </CButton>
                </Link>
                <Link
                  href={`/customers/customer-log-history?id=${id}`}
                  _hover={{ textDecoration: "none" }}
                >
                  <CButton
                    variant="solid"
                    p="10px"
                    fontSize="14px"
                    backgroundColor="brand.primary.main"
                    border="1px solid #FF824C"
                    color="#fff"
                    _focus={{
                      offset: "0",
                    }}
                    isLoading={loading}
                  >
                    LoggedIn Timestamp
                  </CButton>
                </Link>
                {affiliateStatusModal === "active" ? (
                  <Link
                    href={`/affiliates?id=${id}`}
                    _hover={{ textDecoration: "none" }}
                  >
                    <CButton
                      variant="solid"
                      p="10px"
                      fontSize="14px"
                      backgroundColor="brand.primary.main"
                      border="1px solid #FF824C"
                      color="#fff"
                      _focus={{
                        offset: "0",
                      }}
                      isLoading={loading}
                    >
                      Affiliate Stats
                    </CButton>
                  </Link>
                ) : (
                  ""
                )}
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
