import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { CButton } from "../..";

interface DeleteModalProps {
  onClose: () => void;
  isOpen: boolean;
  onDelete: (id: string, onClose: () => void) => void;
  id: string;
  loading: boolean;
  modalTitle?: string;
}

export default function DeleteModal(props: DeleteModalProps) {
  const { isOpen, onClose, onDelete, id, loading, modalTitle } = props;

  const handleDelete = () => {
    onDelete(id, onClose);
  };

  const closeModal = () => {
    onClose();
  };
  return (
    <>
      <Modal isOpen={isOpen} id='deleteModal' onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle ? modalTitle : "Delete Modal"}</ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb='25px'>
            <Stack spacing={4} alignItems='center'>
              <Text> Are you sure to delete this?</Text>
              <HStack>
                <CButton
                  id='deleteButton'
                  onClick={handleDelete}
                  w='20'
                  variant='solid'
                  p='10px'
                  fontSize='14px'
                  backgroundColor='red'
                  border='1px solid #FF824C'
                  color='#fff'
                  _focus={{
                    offset: "0",
                  }}
                  isLoading={loading}>
                  Yes
                </CButton>
                <CButton
                  onClick={onClose}
                  w='20'
                  variant='outline'
                  p='10px'
                  fontSize='14px'
                  border='1px solid #FF824C'
                  color='brand.primary.main'
                  backgroundColor='#fff'
                  _focus={{
                    offset: "0",
                  }}>
                  No
                </CButton>
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
