import React from "react";
import moment, { Moment } from "moment";
import {
  Box,
  Flex,
  Text,
  VStack,
  Spinner,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { IPayment } from "../../interfaces";

interface PaymentsProps {
  paymentsData: IPayment[];
  isLoadingPayments: boolean;
}

const tableHeadings = ["Date", "Amount", "Pay Period"];

const Payments = (props: PaymentsProps) => {
  const payoutsSkiptracersData: any = [];
  const { paymentsData, isLoadingPayments } = props;
  return (
    <>
      <Text fontWeight='500' fontStyle='normal' fontSize='30px' color='#393330'>
        Payments
      </Text>

      <Box>
        <VStack w='100%' spacing={10}>
          <Table overflow='auto' my='30px' variant='simple'>
            <Thead>
              <Tr boxShadow='base'>
                {tableHeadings.map((heading, i) => (
                  <Th
                    fontSize='15px'
                    color='#2F3442'
                    fontWeight='600'
                    textAlign='center'
                    key={i}
                    minWidth='10px'>
                    {heading}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody w='full'>
              {paymentsData?.map((payment: IPayment) => (
                <Tr fontSize='14px'>
                  <Td textAlign='center' color='#737373'>
                    {moment(payment.date).format("MM-DD-YYYY")}
                  </Td>
                  <Td maxW='72' textAlign='center' color='#737373'>
                    ${payment.amount}
                  </Td>
                  <Td maxW='72' textAlign='center' color='#737373'>
                    {moment(payment.payPeriodStart).format("MM-DD-YYYY")} to{" "}
                    {moment(payment.payPeriodEnd).format("MM-DD-YYYY")}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {isLoadingPayments && (
            <Flex w='full' justifyContent='center'>
              <Spinner color='brand.primary.main' thickness='2px' size='md' />
            </Flex>
          )}

          {paymentsData?.length === 0 && (
            <Box w='100%' h='300px' color='#737373' fontWeight='400'>
              <Text textAlign='center'>No records available</Text>
            </Box>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default Payments;
