import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Stack,
  Checkbox,
} from "@chakra-ui/react";
import { CButton, Dropzone } from "..";
import { useForm } from "react-hook-form";
import { DropEvent, FileRejection } from "react-dropzone";
interface IBulkSkipTracedRecords {
  skiptracedRecords: number;
  reviewReason: string;
}
interface FileUploadModalProps {
  onClose: () => void;
  isOpen: boolean;
  onBulkModalSubmit: (
    recordId: string,
    file: File,
    skiptracedRecords: number,
    reviewReason: string
  ) => void;
  records: number | null;
  recordId: string;
  loadingBulkOutput: boolean;
}

function FileUploadModal(props: FileUploadModalProps) {
  const {
    isOpen,
    onClose,
    onBulkModalSubmit,
    records,
    recordId,
    loadingBulkOutput,
  } = props;
  const [file, setFile] = useState<File | null>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [fileError, setFileError] = useState({ file: "" });
  const [unacceptedFileError, setUnacceptedFileError] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();

  const submit = handleSubmit((data: IBulkSkipTracedRecords) => {
    if (file) {
      onBulkModalSubmit(
        recordId,
        file!,
        data.skiptracedRecords,
        data.reviewReason
      );
      setFileError({ ...errors, file: "" });
      reset();
      setFile(null);
    } else {
      setFileError({ ...errors, file: "Please attach a file!" });
    }
  });

  const isRecordsGreater = () => {
    if (parseInt(getValues("skiptracedRecords")) > records!) {
      return false;
    }

    return true;
  };

  const handleDrop = (
    acceptedFiles: File[],
    fileRejections?: FileRejection[],
    event?: DropEvent
  ) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      setUnacceptedFileError("");
    }
    if (fileRejections && fileRejections?.length > 0) {
      setUnacceptedFileError("Invalid File Type - File must be .xlsx or .csv");
      setFile(null);
    }
    setFileError({ ...errors, file: "" });
  };

  const closeModal = () => {
    onClose();
    reset();
    setFileError({ ...errors, file: "" });
    setFile(null);
    setToggle(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} id="bulkFileUploadModal" onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload File</ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb="25px">
            <form onSubmit={submit} id="bulk-upload-form">
              <Stack spacing={4}>
                <Box>
                  <Dropzone onDrop={handleDrop} />
                  <Text id="unacceptedFileError" color="red.400">
                    {unacceptedFileError}
                  </Text>
                  <Text color="brand.primary.main">{file?.name}</Text>
                </Box>
                <Text color="red"> {fileError.file}</Text>
                <FormControl id="records">
                  <FormLabel color="gray" fontSize="sm">
                    Number of records skiptraced
                  </FormLabel>
                  <Input
                    _focus={{
                      borderColor: "brand.primary.main",
                    }}
                    type="number"
                    {...register("skiptracedRecords", {
                      required: true,
                      validate: isRecordsGreater,
                    })}
                  />
                  {errors.skiptracedRecords &&
                    errors.skiptracedRecords.type === "validate" && (
                      <Text color="red">
                        Skiptraced records are greater than file records
                      </Text>
                    )}
                  {errors.skiptracedRecords &&
                    errors.skiptracedRecords.type === "required" && (
                      <Text color="red">Skiptraced record is required!</Text>
                    )}
                  <FormErrorMessage></FormErrorMessage>
                  <Checkbox
                    pt="2"
                    isChecked={toggle}
                    onChange={() => setToggle(!toggle)}
                    colorScheme="orange"
                    color="gray"
                  >
                    Does it need review?
                  </Checkbox>
                  {toggle && (
                    <>
                      <FormLabel color="gray" fontSize="sm" pt="1.5">
                        Why does it need review?
                      </FormLabel>
                      <Input
                        _focus={{
                          borderColor: "brand.primary.main",
                        }}
                        type="text"
                        {...register("reviewReason", {
                          required: true,
                        })}
                      />
                    </>
                  )}
                  {errors.reviewReason &&
                    errors.reviewReason.type === "required" && (
                      <Text color="red">Review Reason is required!</Text>
                    )}
                </FormControl>
                <CButton
                  type="submit"
                  id="BTdownloadLink"
                  variant="solid"
                  p="10px"
                  fontSize="1rem"
                  bgColor="brand.primary.main"
                  border="1px solid #FF824C"
                  color="#fff"
                  isLoading={loadingBulkOutput}
                  _focus={{
                    offset: "0",
                  }}
                >
                  <Text>Fulfil Order</Text>
                </CButton>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FileUploadModal;
