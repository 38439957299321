import React, { Suspense } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { Guarded, Spinner } from "./components";
import { routes } from "./routes";
import { useGetCurrentUser } from "./queries";
import { setCurrentUser } from "./slices/admin";
import { useAppDispatch } from "./slices";

function App() {
  const dispatch = useAppDispatch();
  const { data } = useGetCurrentUser();

  React.useEffect(() => {
    dispatch(setCurrentUser(data));
  }, [data, dispatch]);

  return (
    <Box fontFamily='poppins'>
      <Router>
        <Switch>
          {routes.map((Params, i) => {
            const comp = () => (
              <Suspense fallback={<Spinner />}>
                <Params.Comp />
              </Suspense>
            );
            return (
              <Route
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                exact={Params.exact}
                path={Params.path}
                render={
                  Params.guarded
                    ? () => (
                        <Guarded
                          redirectTo='/login'
                          role={data?.role}
                          permission={Params?.permission}>
                          {comp()}
                        </Guarded>
                      )
                    : () => comp()
                }
              />
            );
          })}
          <Redirect exact from='/' to='/single-lead' />
        </Switch>
      </Router>
    </Box>
  );
}

export default App;
