import { useMutation, useQuery } from "react-query";
import { API } from "../constants";
import { apiClient } from "../services";

export const usePromoCode = () => {
  const addPromoCode = async (body: {
    discountP: number;
    orderType: string;
  }) => {
    const res = await apiClient.post(API.ROUTES.ADMIN_ADD_PROMO_CODE, body);
    return res;
  };
  return useMutation(addPromoCode);
};

export const useGetPromoCodes = (
  type: string,
  fromDate?: string,
  toDate?: string
) => {
  const getPromoCodes = async () => {
    const res = await apiClient.get(
      `${API.ROUTES.ADMIN_GET_PROMO_CODES}?&toDate=${toDate}&fromDate=${fromDate}&statType=${type}`
    );
    return res;
  };
  return useQuery("getPromoCodes", getPromoCodes);
};

export const useDeletePromo = () => {
  const DeletePromo = async (params: { id: string }) => {
    const res = await apiClient.delete(
      `${API.ROUTES.ADMIN_DELETE_PROMO_CODE}?id=${params.id}`
    );

    return res;
  };
  return useMutation(DeletePromo);
};

export const useDownloadPromoCodes = () => {
  const useDownloadPromoCodes = async (body: { statType: string }) => {
    try {
      const res = await apiClient.post(
        API.ROUTES.ADMIN_DOWNLOAD_PROMO_CODES,
        body,
        {
          responseType: "blob",
        }
      );
      return res;
    } catch (e: any) {
      console.log(e);
      throw new Error(e.response.data.errorCode);
    }
  };

  return useMutation(useDownloadPromoCodes);
};
