import { useEffect } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Heading,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import { DeepMap, FieldError, useForm } from "react-hook-form";
import { CButton } from "../..";

interface FormType {
  email: string;
  password: string;
}

export interface LoginProps {
  onLoginSubmit: (email: string, password: string) => void;
  errors?: DeepMap<FormType, FieldError>;
  isLoading?: boolean;
}

export default function CLogin({
  onLoginSubmit,
  errors,
  isLoading,
}: LoginProps) {
  const { formState, handleSubmit, register, setError } = useForm<FormType>();
  console.log(errors);

  useEffect(() => {
    if (errors) {
      Object.entries(errors).forEach(([name, err]) => {
        if (err) setError(name as keyof FormType, err);
      });
    }
  }, [errors, setError]);

  const { email: emailErrors, password: passwordErrors } = formState.errors;

  const submit = handleSubmit(
    (data) => onLoginSubmit && onLoginSubmit(data.email, data.password)
  );

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} color="brand.primary.main">
            Sign In
          </Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <form>
              <FormControl id="email" isInvalid={emailErrors && true}>
                <FormLabel>Email address</FormLabel>
                <Input
                  _focus={{
                    border: "none",
                  }}
                  type="email"
                  placeholder="admin@skipspot.com"
                  {...register("email", {
                    required: "Email is required!",
                    validate: (v) =>
                      // eslint-disable-next-line no-useless-escape
                      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      "Please enter a valid Email!",
                  })}
                />
                <FormErrorMessage>{emailErrors?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={passwordErrors && true}>
                <FormLabel>Password</FormLabel>
                <Input
                  _focus={{
                    border: "none",
                  }}
                  id="password"
                  type="password"
                  placeholder="Min. 8 characters"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register("password", {
                    required: "Password is required!",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters",
                    },
                  })}
                />
                <FormErrorMessage>{passwordErrors?.message}</FormErrorMessage>
              </FormControl>
              <Stack pt="8px" spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Checkbox>Remember me</Checkbox>
                  <Link color={"brand.primary.main"}>Forgot password?</Link>
                </Stack>
                <CButton
                  onClick={submit}
                  isLoading={isLoading}
                  variant="solid"
                  bg={"brand.primary.main"}
                  color={"white"}
                  _active={{
                    bg: "brand.primary.main",
                  }}
                  _focus={{
                    border: "none",
                  }}
                >
                  Sign in
                </CButton>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
