import React, { useState } from "react";
import {
  Flex,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  HStack,
  Spinner,
  Select,
  Button,
  Input,
  InputLeftElement,
  InputGroup,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Moment } from "moment";
import { useSelector } from "react-redux";
import { BsDownload } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import { CSVLink } from "react-csv";
import moment from "moment";
import { CSkiptraceStats, DatePicker, Badge, CButton } from "..";
import {
  BulkTraceFiles,
  ISingleSkiptrace,
  IUser,
  IFulfilledOrdersStat,
} from "../../interfaces";
import { RootState } from "../../slices";
import SkiptracerCostUpdateModal from "./SkiptracerCostUpdateModal";
import {
  adminTableHeadings,
  skiptracerTableHeadings,
} from "../../constants/fullfilledOrders";
import CustomerHistoryModal from "../CustomerHistoryModal";

interface FulfilledOrdersProps {
  handleOnDataPicker: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  fromDate: Moment | null;
  toDate: Moment | null;
  isLoading: boolean;
  onFilterClick: (
    fromData: Moment | null,
    toDate: Moment | null,
    type: string,
    email: string,
    userName: string
  ) => void;
  onFilterClearClick: () => void;
  handleCsvData: (stats: IFulfilledOrdersStat) => any[];
  bulkData: BulkTraceFiles[] | undefined;
  singleSkiptraceData: ISingleSkiptrace[] | undefined;
  skiptracersData: IUser[] | undefined;
  stats: IFulfilledOrdersStat;
  skiptracerCost: number;
  setSkiptracerCost: (skiptracerCost: number) => void;
  updateSkiptracerCost: (
    recordId: string,
    type: string,
    skiptracerCost: number
  ) => void;
  recordId: string;
  setRecordId: (recordId: string) => void;
  isLoadingUpdateSkiptracerCost: boolean;
  saleType: string;
  setSaleType: (saleType: string) => void;
  userName: string;
  setUserName: (query: string) => void;
}

const FulfilledOrders = (props: FulfilledOrdersProps) => {
  const { currentUser } = useSelector((state: RootState) => state.admin);
  const [type, setType] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [customerID, setCustomerId] = useState<string>("");

  const {
    bulkData,
    singleSkiptraceData,
    fromDate,
    toDate,
    isLoading,
    handleOnDataPicker,
    onFilterClick,
    onFilterClearClick,
    handleCsvData,
    skiptracersData,
    stats,
    updateSkiptracerCost,
    skiptracerCost,
    setSkiptracerCost,
    recordId,
    setRecordId,
    isLoadingUpdateSkiptracerCost,
    saleType,
    setSaleType,
    setUserName,
    userName,
  } = props;

  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    isOpen: isHistoryModalOpen,
    onClose: onHistoryModalClose,
    onOpen: onHistoryModalOpen,
  } = useDisclosure();

  const formatCsvData = () => handleCsvData(stats);

  const handleFilterClick = () => {
    onFilterClick(fromDate, toDate, type, email, userName);
  };
  const finalTableHeadings =
    currentUser?.role === "Admin"
      ? adminTableHeadings
      : skiptracerTableHeadings;

  const handleClear = () => {
    setType("");
    setEmail("");
    setSaleType("");
    setUserName("");
    onFilterClearClick();
  };

  const handleUpdateSkiptracerModal = (
    recordId: string,
    fileType: string,
    skiptracedCost: number | null
  ) => {
    setType(fileType);
    skiptracedCost && setSkiptracerCost(skiptracedCost);
    setRecordId(recordId);
    onOpen();
  };

  const handleCustomerClick = (uid: string) => {
    setCustomerId(uid);
    onHistoryModalOpen();
  };

  return (
    <Box>
      <CustomerHistoryModal
        onClose={onHistoryModalClose}
        isOpen={isHistoryModalOpen}
        loading={false}
        id={customerID}
      />
      <Flex flexDirection="column">
        <Box>
          <Flex justify="space-between" mt="8">
            {currentUser?.role === "Admin" && stats !== undefined ? (
              <Button
                rightIcon={<BsDownload />}
                id="downloadBtn"
                variant="solid"
                size="sm"
                w="40"
                fontSize="14px"
                bgColor="brand.primary.main"
                border="1px solid rgb(255, 130, 76)"
                color="#fff"
                _focus={{
                  offset: "0",
                }}
                colorScheme="orange"
              >
                <CSVLink
                  data={formatCsvData()}
                  filename={`fulfilled-order-details-stats-${moment().format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}`}
                >
                  Download Stats
                </CSVLink>
              </Button>
            ) : (
              <Text visibility="hidden" fontWeight="light">
                View File
              </Text>
            )}

            <VStack maxW="4xl" w="full" alignItems="flex-end">
              <HStack justifyContent="space-between" w="full" align="center">
                {currentUser?.role === "Admin" && (
                  <Select
                    rounded="md"
                    w="full"
                    color="gray.400"
                    focusBorderColor="brand.primary.light"
                    size="sm"
                    py="0.5"
                    placeholder="Search by tracer"
                    value={email}
                    onChange={(e) => {
                      setEmail(e?.target?.value);
                    }}
                  >
                    {skiptracersData &&
                      skiptracersData?.map((skiptracer) => (
                        <option value={skiptracer?.email}>
                          {skiptracer?.email}
                        </option>
                      ))}
                  </Select>
                )}

                <Select
                  rounded="md"
                  size="sm"
                  placeholder="Order Type"
                  value={saleType}
                  onChange={(e) => setSaleType(e.target.value)}
                  border="1px solid rgb(255, 130, 76)"
                  color="rgb(255, 130, 76)"
                  focusBorderColor="brand.primary.light"
                >
                  <option value="direct">Direct</option>
                  <option value="affiliate">Affiliate</option>
                </Select>
                <Select
                  rounded="md"
                  color="gray.400"
                  focusBorderColor="brand.primary.light"
                  size="sm"
                  py="0.5"
                  placeholder="Search by type"
                  value={type}
                  onChange={(e) => {
                    setType(e?.target?.value);
                  }}
                >
                  <option value="single">Single Fulfilled</option>
                  <option value="bulk">Bulk Fulfilled</option>
                  <option value="all">All Fulfilled</option>
                </Select>
              </HStack>
              <HStack w="full" justifyContent="space-between">
                <DatePicker
                  handleOnDataPicker={handleOnDataPicker}
                  fromDate={fromDate}
                  toDate={toDate}
                />
                <InputGroup size="sm" maxW="sm">
                  <InputLeftElement pointerEvents="none">
                    <BsSearch color="#FF824C" />
                  </InputLeftElement>
                  <Input
                    rounded="md"
                    id="searchQuery"
                    color="#737373"
                    type="text"
                    focusBorderColor="brand.primary.light"
                    placeholder="Search..."
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </InputGroup>
                <CButton
                  onClick={() => handleClear()}
                  id="filterClearbutton"
                  variant="solid"
                  size="sm"
                  w="28"
                  fontSize="16px"
                  bgColor="#fff"
                  border="1px solid rgb(255, 130, 76)"
                  color="rgb(255, 130, 76)"
                  _focus={{
                    offset: "0",
                  }}
                >
                  <Text fontWeight="light">Clear</Text>
                </CButton>
                <CButton
                  onClick={handleFilterClick}
                  id="filterbutton"
                  variant="solid"
                  size="sm"
                  w="28"
                  fontSize="16px"
                  bgColor="brand.primary.main"
                  border="1px solid rgb(255, 130, 76)"
                  color="#fff"
                  _focus={{
                    offset: "0",
                  }}
                  isLoading={isLoading}
                >
                  <Text fontWeight="light">Filter</Text>
                </CButton>
              </HStack>
            </VStack>
          </Flex>
          <Box overflow="auto">
            <Table
              overflow="auto"
              w="full"
              mt="30px"
              mb="30px"
              variant="simple"
            >
              <Thead>
                <Tr boxShadow="base">
                  {finalTableHeadings &&
                    finalTableHeadings?.map((heading: string, i: number) => (
                      <Th
                        fontSize="15px"
                        color="#2F3442"
                        fontWeight="600"
                        textAlign="center"
                        key={i}
                        minWidth="10px"
                      >
                        {heading}
                      </Th>
                    ))}
                </Tr>
              </Thead>
              <Tbody w="full">
                {bulkData &&
                  bulkData?.map((data, i) => (
                    <Tr fontSize="14px" key={i}>
                      <Td textAlign="center" color="#737373" minW="36">
                        {moment(data?.createdAt).format("MM-DD-YYYY")}
                      </Td>
                      {currentUser?.role === "Admin" ? (
                        <Td
                          cursor="pointer"
                          onClick={() => handleCustomerClick(data.uid)}
                          minW="60"
                          textAlign="center"
                          color="brand.primary.main"
                          _hover={{
                            textDecor: "underline",
                          }}
                        >
                          {data.userName}
                        </Td>
                      ) : (
                        <Td
                          maxW="72"
                          textAlign="center"
                          color="#737373"
                          minW="60"
                        >
                          {data.userName}
                        </Td>
                      )}
                      <Td
                        maxW="72"
                        textAlign="center"
                        color="#737373"
                        minW="44"
                      >
                        {data.records}
                      </Td>

                      <Td
                        maxW="72"
                        textAlign="center"
                        color="#737373"
                        minW="44"
                      >
                        {data.skiptracedRecords}
                      </Td>

                      <Td textAlign="center" color="#737373" minW="44">
                        Bulk File
                      </Td>
                      <Td textAlign="center" color="#737373" minW="40">
                        <Badge variant={data.status.toLowerCase()}>
                          {data?.status === "success"
                            ? "Completed"
                            : data?.status}
                        </Badge>
                      </Td>
                      <Td textAlign="center" color="#737373">
                        <CButton
                          id="viewFile"
                          variant="solid"
                          fontSize="14px"
                          bgColor="brand.primary.main"
                          border="1px solid rgb(255, 130, 76)"
                          color="#fff"
                          _focus={{
                            offset: "0",
                          }}
                        >
                          <a href={data.url} target="_blank" rel="noreferrer">
                            <Text fontWeight="light">View File</Text>
                          </a>
                        </CButton>
                      </Td>
                      <Td textAlign="center" color="#737373">
                        {data.outputUrl && (
                          <CButton
                            id="viewFile"
                            variant="solid"
                            fontSize="14px"
                            bgColor="brand.primary.main"
                            border="1px solid rgb(255, 130, 76)"
                            color="#fff"
                            _focus={{
                              offset: "0",
                            }}
                          >
                            <a
                              href={data.outputUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Text fontWeight="light">View File</Text>
                            </a>
                          </CButton>
                        )}
                      </Td>
                      {currentUser?.role === "Admin" && (
                        <Td textAlign="center" color="#737373">
                          {data.cost ? `$${data.cost.toFixed(2)}` : "$0"}
                        </Td>
                      )}
                      <Td textAlign="center" color="#737373">
                        {data?.affiliateCommission
                          ? `$${Number(data?.affiliateCommission).toFixed(2)}`
                          : "$0"}
                      </Td>
                      <Td textAlign="center" color="#737373">
                        {data.affiliateName ? data.affiliateName : ""}
                      </Td>

                      <Td
                        textAlign="center"
                        color="brand.primary.main"
                        cursor="pointer"
                        onClick={() =>
                          handleUpdateSkiptracerModal(
                            data._id,
                            "Bulk File",
                            data.skiptracerCost
                          )
                        }
                      >
                        ${data?.skiptracerCost?.toFixed(2)}
                      </Td>

                      {currentUser?.role === "Admin" && (
                        <Td textAlign="center" color="#737373">
                          {data?.skiptracerEmail}
                        </Td>
                      )}
                      {currentUser?.role === "Admin" && (
                        <>
                          <Td textAlign="center" color="#737373">
                            {data?.profit ? `$${data.profit.toFixed(2)}` : ""}
                          </Td>
                          <Td textAlign="center" color="#737373">
                            {data.marginPercentage
                              ? `${data.marginPercentage}%`
                              : ""}
                          </Td>
                        </>
                      )}
                    </Tr>
                  ))}
                {singleSkiptraceData &&
                  singleSkiptraceData?.map((data, i) => (
                    <Tr fontSize="14px" key={i}>
                      <Td textAlign="center" color="#737373">
                        {moment(data?.createdAt).format("MM-DD-YYYY")}
                      </Td>
                      <Td
                        cursor="pointer"
                        onClick={() => handleCustomerClick(data.uid)}
                        minW="60"
                        textAlign="center"
                        color="brand.primary.main"
                        _hover={{
                          textDecor: "underline",
                        }}
                      >
                        {data.userName}
                      </Td>
                      <Td maxW="72" textAlign="center" color="#737373">
                        1
                      </Td>
                      <Td maxW="72" textAlign="center" color="#737373">
                        1
                      </Td>

                      <Td textAlign="center" color="#737373">
                        Single Lead
                      </Td>
                      <Td textAlign="center" color="#737373">
                        <Badge variant={data.status.toLowerCase()}>
                          {data?.status === "success"
                            ? "Completed"
                            : data?.status}
                        </Badge>
                      </Td>
                      <Td textAlign="center" color="#737373"></Td>
                      <Td textAlign="center" color="#737373">
                        {data.outputUrl && (
                          <CButton
                            id="viewFile"
                            variant="solid"
                            fontSize="14px"
                            bgColor="brand.primary.main"
                            border="1px solid rgb(255, 130, 76)"
                            color="#fff"
                            _focus={{
                              offset: "0",
                            }}
                          >
                            <a
                              href={data.outputUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Text fontWeight="light">View File</Text>
                            </a>
                          </CButton>
                        )}
                      </Td>
                      {currentUser?.role === "Admin" && (
                        <Td textAlign="center" color="#737373">
                          {data.cost ? `$${Number(data?.cost)}` : "$0"}
                        </Td>
                      )}
                      <Td minW="64" textAlign="center" color="#737373">
                        {data.affiliateCommission
                          ? `$${Number(data?.affiliateCommission).toFixed(2)}`
                          : "$0"}
                      </Td>
                      <Td minW="64" textAlign="center" color="#737373">
                        {data.affiliateName ? data.affiliateName : ""}
                      </Td>

                      <Td
                        textAlign="center"
                        color="brand.primary.main"
                        cursor="pointer"
                        onClick={() =>
                          handleUpdateSkiptracerModal(
                            data._id,
                            "Single Lead",
                            data.skiptracerCost
                          )
                        }
                      >
                        ${data?.skiptracerCost?.toFixed(2)}
                      </Td>

                      {currentUser?.role === "Admin" && (
                        <Td textAlign="center" color="#737373">
                          {data?.skiptracerEmail}
                        </Td>
                      )}
                      {currentUser?.role === "Admin" && (
                        <>
                          <Td textAlign="center" color="#737373">
                            {data?.profit ? `$${data?.profit?.toFixed(2)}` : ""}
                          </Td>
                          <Td textAlign="center" color="#737373">
                            {data?.marginPercentage
                              ? `$${data?.marginPercentage}%`
                              : ""}
                          </Td>
                        </>
                      )}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
          {isLoading && (
            <Flex w="full" justifyContent="center">
              <Spinner color="brand.primary.main" thickness="2px" size="md" />
            </Flex>
          )}

          {bulkData?.length === 0 && singleSkiptraceData?.length === 0 && (
            <Box mt="30px" w="100%" h="300px" color="#737373" fontWeight="400">
              <Text textAlign="center">No records available</Text>
            </Box>
          )}
        </Box>

        {isLoading ? "" : <CSkiptraceStats stats={stats} />}
      </Flex>

      {currentUser?.role === "Admin" && (
        <SkiptracerCostUpdateModal
          onClose={onClose}
          isOpen={isOpen}
          skiptracerCost={skiptracerCost}
          recordId={recordId}
          type={type}
          updateSkiptracerCost={updateSkiptracerCost}
          isLoadingUpdateSkiptracerCost={isLoadingUpdateSkiptracerCost}
        />
      )}
    </Box>
  );
};

export default FulfilledOrders;
