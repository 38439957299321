import { useMutation } from "react-query";
import { API } from "../constants";
import { apiClient } from "../services";

const useLogin = () => {
  const login = async (body: { email: string; password: string }) => {
    try {
      const res = await apiClient.post(API.ROUTES.ADMIN_LOGIN, body);
      return res;
    } catch (e) {
      throw new Error(e.response.data.errorCode);
    }
  };

  return useMutation(login);
};

export default useLogin;
