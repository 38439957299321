import { createSlice } from "@reduxjs/toolkit";
import { ICustomerFilterState } from "../../interfaces";

const customersSlice = createSlice({
  name: "admin",
  initialState: {
    formSate: {
      fromDate: null,
      toDate: null,
      affiliateStatus: "",
      tag: "",
      name: "",
    },
    affiliateStatusModal: "",
  } as {
    formSate: ICustomerFilterState;
    affiliateStatusModal: string;
  },
  reducers: {
    setFormState: (state, action) => {
      state.formSate = { ...state.formSate, ...action.payload };
    },
    clearFormState: (state, action) => {
      state.formSate = {
        fromDate: null,
        toDate: null,
        affiliateStatus: "",
        tag: "",
        name: "",
      };
    },
    setAffiliateStatusModal: (state, action) => {
      state.affiliateStatusModal = action.payload;
    },
  },
});

export const { clearFormState, setFormState, setAffiliateStatusModal } =
  customersSlice.actions;

export default customersSlice.reducer;
