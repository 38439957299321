import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";

interface StatCardProps {
  title: string;
  value: number | string;
}

function StatCard({ title, value }: StatCardProps) {
  return (
    <Box
      align="start"
      border="1px"
      borderColor="gray.100"
      p="3"
      rounded="xl"
      shadow="sm"
      minW="52"
    >
      <Flex flexDirection="column">
        <Text color="gray.400" fontSize="sm">
          {title}
        </Text>
        <Text fontSize="2xl" fontWeight="bold" color="brand.primary.main">
          {value}
        </Text>
      </Flex>
    </Box>
  );
}

export default StatCard;
