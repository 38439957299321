import React, { Component } from "react";
import { Box } from "@chakra-ui/react";
import Chart from "react-apexcharts";

class BarChart extends Component {
  render() {
    const data: any = this.props;

    const serires: number[] = Object.values(data);
    const seriresData = serires.map((data) => (data ? data.toFixed(0) : 0));

    return (
      <Box
        py="3"
        borderWidth="thin"
        borderColor="gray.50"
        shadow="sm"
        rounded="md"
      >
        <Chart
          options={{
            chart: {
              id: "chart-id",
            },
            xaxis: {
              categories: Object.keys(data),
              labels: {
                style: {
                  fontSize: "10px",
                },
              },
            },
            colors: ["#FF824C"],
          }}
          series={[
            {
              name: "Dashboard-Stats",
              data: seriresData,
            },
          ]}
          type="bar"
          width="100%"
          height="500"
        />
      </Box>
    );
  }
}

export default BarChart;
