import moment from "moment";
import { useLocation } from "react-router-dom";
import { BULK_FILE_DUE_HOURS, SINGLE_LEAD_DUE_HOURS } from "../constants";
export { csvFile } from "./csvDownloadHelper";

export const calculateDueInTime = (due: string | Date, type: string) => {
  const currentTime = moment(new Date());
  const diffInMinutes = moment(due).diff(currentTime, "minutes");

  const dueHours =
    type === "bulk" ? BULK_FILE_DUE_HOURS : SINGLE_LEAD_DUE_HOURS;

  const hours = Math.floor(diffInMinutes / 60);
  const mins = Math.floor(diffInMinutes % 60);

  if (hours.toString().startsWith("-") || mins.toString().startsWith("-")) {
    return `${hours.toString().startsWith("-") ? "LATE" : ""} ${
      -hours - dueHours
    }h ${-mins}m`;
  } else {
    return `${hours}h ${mins}m`;
  }
};

export const useQuery = () => new URLSearchParams(useLocation().search);


export const mapStatsData = (oldData: any, newData: any) => {
  const mapData: any = {};

  for (let obj of newData) {
    if (!mapData[obj.skiptracerEmail]) {
      mapData[obj.skiptracerEmail] = { ...obj };
    }
  }

  const mappedData = oldData?.map((obj: any) => {
    if (mapData[obj.skiptracerEmail]) {
      return { ...obj, ...mapData[obj.skiptracerEmail] };
    }
  });

  return mappedData;
};

