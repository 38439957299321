import React from "react";
import { Flex, Text, BadgeProps as Props } from "@chakra-ui/react";

interface BadgeProps extends Props {
  children: string;
  variant: string;
}

export default function Badge(props: BadgeProps) {
  const { children, variant } = props;

  const variantProps = () => {
    switch (variant) {
      case "pending":
        return {
          bgColor: "#FFE4D9",
          color: "brand.primary.main",
        };
      case "tag":
        return {
          bgColor: "brand.primary.main",
          color: "white",
        };
      case "success":
        return {
          bgColor: "#EFFFE3",
          color: "#36DE1B",
        };
      case "needsreview":
        return {
          bgColor: "#FEE6E6",
          color: "#FF4C4C",
        };
      case "processing":
        return {
          bgColor: "#FFFDD0",
          color: "#E1C16E",
        };
      case "active":
        return {
          bgColor: "#EFFFE3",
          color: "#36DE1B",
        };
      case "inactive":
        return {
          bgColor: "#FEE6E6",
          color: "#FF4C4C",
        };
      case "requested":
        return {
          bgColor: "#FFFDD0",
          color: "#E1C16E",
        };
      case "disputed":
        return {
          bgColor: "pink.100",
          color: "pink.400",
        };
      default: {
        return null;
      }
    }
  };

  return (
    <Flex
      bg="pink.300"
      px="4"
      h="30px"
      alignItems="center"
      justifyContent="center"
      w="full"
      fontWeight="600"
      {...variantProps()}
      borderRadius="5px"
    >
      <Text>
        {children === "needsReview"
          ? "Needs Review"
          : children.charAt(0).toUpperCase() + children.substring(1)}
      </Text>
    </Flex>
  );
}
