import React from "react";
import moment, { Moment } from "moment";
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spinner,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { ICustomers, IAffiliatesPayouts } from "../../../interfaces";
import { CButton, DatePicker } from "../..";
import { MdDelete } from "react-icons/md";

interface AffiliatesPayoutsProps {
  handleSubmit: Function;
  register: Function;
  errors: any;
  customersData: ICustomers[];
  affiliatesPayoutsData: IAffiliatesPayouts[];
  isLoadingAffiliatesPayouts: boolean;
  isLoadingAddAffiliatesPayouts: boolean;
  handleOnDataPicker: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  fromDate: Moment | null;
  toDate: Moment | null;
  onFilterClick: (fromData: Moment | null, toDate: Moment | null) => void;
  onFilterClearClick: () => void;
  payPeriodStart: Moment | null;
  payPeriodEnd: Moment | null;
  handleOnPayPeriodPicker: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  onSetId: (id: string) => void;
  deleteLoading: boolean;
  payoutId: string;
}

const tableHeadings = [
  "Date",
  "Pay Period",
  "Amount",
  "Affiliate",
  "Actions",
  "INVOICE LINK",
];

const AffiliatesPayouts = (props: AffiliatesPayoutsProps) => {
  const {
    handleSubmit,
    register,
    errors,
    customersData,
    affiliatesPayoutsData,
    isLoadingAffiliatesPayouts,
    isLoadingAddAffiliatesPayouts,
    handleOnDataPicker,
    fromDate,
    toDate,
    onFilterClick,
    onFilterClearClick,
    payPeriodStart,
    payPeriodEnd,
    handleOnPayPeriodPicker,
    onSetId,
    deleteLoading,
    payoutId,
  } = props;

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    handleSubmit();
  };

  const handleFilterClick = () => {
    onFilterClick(fromDate, toDate);
  };

  const handleClear = () => {
    onFilterClearClick();
  };
  return (
    <>
      <Text fontWeight="500" fontStyle="normal" fontSize="30px" color="#393330">
        Affiliates Payouts
      </Text>

      <Box>
        <HStack w="100%" spacing={4}>
          <VStack w="73%" alignSelf="start" mt="40px">
            <HStack alignSelf="end" spacing={2}>
              <DatePicker
                handleOnDataPicker={handleOnDataPicker}
                fromDate={fromDate}
                toDate={toDate}
              />
              <CButton
                onClick={() => handleClear()}
                id="filterClearbutton"
                variant="solid"
                size="sm"
                w="28"
                fontSize="16px"
                bgColor="#fff"
                border="1px solid rgb(255, 130, 76)"
                color="rgb(255, 130, 76)"
                _focus={{
                  offset: "0",
                }}>
                <Text fontWeight="light">Clear</Text>
              </CButton>
              <CButton
                onClick={handleFilterClick}
                id="filterbutton"
                variant="solid"
                size="sm"
                w="28"
                fontSize="16px"
                bgColor="brand.primary.main"
                border="1px solid rgb(255, 130, 76)"
                color="#fff"
                _focus={{
                  offset: "0",
                }}
                isLoading={false}>
                <Text fontWeight="light">Filter</Text>
              </CButton>
            </HStack>

            <Box w="100%">
              <Table overflow="auto" my="30px" variant="simple">
                <Thead>
                  <Tr boxShadow="base">
                    {tableHeadings.map((heading, i) => (
                      <Th
                        fontSize="15px"
                        color="#2F3442"
                        fontWeight="600"
                        textAlign="center"
                        key={i}
                        minWidth="10px">
                        {heading}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody w="full">
                  {affiliatesPayoutsData?.map((payouts) => (
                    <Tr fontSize="14px" key={payouts._id}>
                      <Td textAlign="center" color="#737373">
                        {moment(payouts?.createdAt).format("MM-DD-YYYY")}
                      </Td>
                      <Td textAlign="center" color="#737373">
                        {moment(payouts?.payPeriodStart).format("MM-DD-YYYY")}{" "}
                        to {moment(payouts?.payPeriodEnd).format("MM-DD-YYYY")}
                      </Td>
                      <Td maxW="72" textAlign="center" color="#737373">
                        ${payouts?.amount}
                      </Td>
                      <Td maxW="72" textAlign="center" color="#737373">
                        {payouts?.userName}
                      </Td>
                      <Td>
                        <HStack justifyContent="center">
                          <Tooltip
                            rounded="md"
                            hasArrow
                            label="Delete Payout"
                            bg="red.600"
                            fontSize="xs">
                            <IconButton
                              onClick={() => {
                                onSetId(payouts._id);
                              }}
                              isLoading={
                                deleteLoading && payouts._id === payoutId
                              }
                              id="deleteIcon"
                              icon={<MdDelete />}
                              _focus={{
                                offset: "0",
                              }}
                              colorScheme="red"
                              aria-label="reset-icon"
                              fontSize="14px"
                              size="sm"
                            />
                          </Tooltip>
                        </HStack>
                      </Td>
                      <Td textAlign="center">
                        {payouts?.invoiceUrl && (
                          <CButton
                            id="viewOutputFile"
                            variant="solid"
                            fontSize="14px"
                            bgColor="brand.primary.main"
                            border="1px solid rgb(255, 130, 76)"
                            color="#fff"
                            leftIcon={<HiOutlineDocumentDownload />}
                            _focus={{
                              offset: "0",
                            }}>
                            <a
                              href={payouts?.invoiceUrl}
                              target="_blank"
                              rel="noreferrer">
                              <Text fontWeight="light">Download</Text>
                            </a>
                          </CButton>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {isLoadingAffiliatesPayouts && (
                <Flex w="full" justifyContent="center">
                  <Spinner
                    color="brand.primary.main"
                    thickness="2px"
                    size="md"
                  />
                </Flex>
              )}

              {affiliatesPayoutsData?.length === 0 && (
                <Box w="100%" h="300px" color="#737373" fontWeight="400">
                  <Text textAlign="center">No records available</Text>
                </Box>
              )}
            </Box>
          </VStack>
          <HStack w="27%" alignSelf="start">
            <Box boxShadow="md" rounded="md" w="full">
              <form style={{ width: "100%" }} id="formSubmit" onSubmit={submit}>
                <VStack px="4" my="30px" spacing={6} w="full">
                  <HStack width="full">
                    <FormControl
                      w="full"
                      id="amount"
                      isInvalid={errors?.amount}>
                      <FormLabel>Amount</FormLabel>
                      <Input
                        w="full"
                        type="number"
                        placeholder="amount"
                        focusBorderColor="brand.primary.light"
                        step=".01"
                        {...register("amount", {
                          required: true,
                        })}
                      />
                      <FormErrorMessage>
                        {errors?.amount && <Text>Amount is required</Text>}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>

                  <HStack width="full">
                    <FormControl id="user" isInvalid={errors?.uid}>
                      <FormLabel>Users</FormLabel>
                      <Select
                        color="gray.400"
                        focusBorderColor="brand.primary.light"
                        py="0.5"
                        placeholder="Select User"
                        {...register("uid", {
                          required: true,
                        })}>
                        {customersData?.map((customer: ICustomers) => (
                          <option key={customer._id} value={customer._id}>
                            {customer.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors?.uid && <Text>Customer is required</Text>}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>

                  <FormControl width={{ lg: "full", sm: "200px" }}>
                    <FormLabel justifyItems="start">Pay Period</FormLabel>

                    <DatePicker
                      handleOnDataPicker={handleOnPayPeriodPicker}
                      fromDate={payPeriodStart}
                      toDate={payPeriodEnd}
                      required={true}
                      noBorder={true}
                    />
                  </FormControl>

                  <CButton
                    w="full"
                    variant="solid"
                    id="payUser"
                    background="brand.primary.main"
                    border="1px"
                    borderColor="brand.primary.main"
                    color="white"
                    width={{ lg: "full", sm: "200px" }}
                    mt="30px"
                    type="submit"
                    fontSize={14}
                    isLoading={isLoadingAddAffiliatesPayouts}>
                    Pay User
                  </CButton>
                </VStack>
              </form>
            </Box>
          </HStack>
        </HStack>
      </Box>
    </>
  );
};

export default AffiliatesPayouts;
