import React from "react";
import {
  Box,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Spinner,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";
import { ICustomerPurchaseHistory } from "../../../interfaces";

const tableHeadings = ["Date", "List Name", "Leads Traced", "Revenue of Order"];

interface CustomersProps {
  purchaseHistoryData: ICustomerPurchaseHistory[];
  isLoading: boolean;
}

export default function CustomersPurchaseHistory(props: CustomersProps) {
  const { purchaseHistoryData, isLoading } = props;

  const formatedData = purchaseHistoryData?.map((obj) => {
    const data = {
      ...obj,
      date: moment(obj.date).format("MM-DD-YYYY"),
    };
    return Object.values(data);
  });

  return (
    <>
      <Text
        fontWeight="500"
        fontStyle="normal"
        fontSize="30px"
        color="#393330"
        mb="16"
      >
        Customers Purchase History
      </Text>
      <Box>
        <Flex flexDirection="column">
          {formatedData?.length ? (
            <CSVLink
              data={formatedData}
              headers={tableHeadings}
              filename={`purchase-history-${moment().format(
                "MMMM Do YYYY, h:mm:ss a"
              )}`}
            >
              <Button
                size="sm"
                rightIcon={<BsDownload />}
                id="downloadBtn"
                colorScheme="orange"
                bgColor="brand.primary.main"
              >
                Download as CSV
              </Button>
            </CSVLink>
          ) : null}

          <Box>
            <Table
              overflow="auto"
              mx="auto"
              w="100%"
              mt="30px"
              mb="30px"
              variant="simple"
            >
              <Thead>
                <Tr boxShadow="base">
                  {tableHeadings.map((heading, i) => (
                    <Th
                      fontSize="15px"
                      color="#2F3442"
                      fontWeight="600"
                      textAlign="center"
                      key={i}
                      minWidth="10px"
                    >
                      {heading}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody w="full">
                {purchaseHistoryData &&
                  purchaseHistoryData?.map((purcahesData) => (
                    <Tr fontSize="14px">
                      <Td textAlign="center" color="#737373" minW="70">
                        {moment(purcahesData?.date).format("MM-DD-YYYY")}
                      </Td>
                      <Td textAlign="center" color="#737373">
                        {purcahesData?.listName}
                      </Td>
                      <Td maxW="72" textAlign="center" color="#737373">
                        {purcahesData?.leadsTraced}
                      </Td>
                      <Td textAlign="center" color="#737373">
                        {purcahesData?.revenueOfOrder}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {isLoading && (
              <Flex w="full" justifyContent="center">
                <Spinner color="brand.primary.main" thickness="2px" size="md" />
              </Flex>
            )}

            {purchaseHistoryData?.length === 0 && (
              <Box
                mt="30px"
                w="100%"
                h="300px"
                color="#737373"
                fontWeight="400"
              >
                <Text textAlign="center">No records available</Text>
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </>
  );
}
