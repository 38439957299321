import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

interface IUpdateReferralCodeForm {
  referralCode: string;
}

interface ReferralCodeUpdateModalProps {
  onClose: () => void;
  isOpen: boolean;
  userId: string;
  referralCode: string;
  updateReferralCode: (userId: string, referralCode: string) => void;
  isLoadingUpdateReferralCode: boolean;
}

function ReferralCodeUpdateModal(props: ReferralCodeUpdateModalProps) {
  const {
    isOpen,
    onClose,
    userId,
    referralCode,
    updateReferralCode,
    isLoadingUpdateReferralCode,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const submit = handleSubmit((data: IUpdateReferralCodeForm) => {
    updateReferralCode(userId, data.referralCode);
  });

  const closeModal = () => {
    onClose();
    reset();
  };

  return (
    <>
      <Modal isOpen={isOpen} id='updateReferralCodeModal' onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Referral Code </ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb='25px'>
            <form onSubmit={submit} id='bulk-upload-form'>
              <Stack spacing={4}>
                <FormControl id='skiptracerCost'>
                  <FormLabel color='gray' fontSize='sm'>
                    Referral Code
                  </FormLabel>
                  <Input
                    _focus={{
                      borderColor: "brand.primary.main",
                    }}
                    type='text'
                    defaultValue={referralCode}
                    {...register("referralCode", {
                      required: true,
                    })}
                  />

                  {errors.referralCode && (
                    <Text color='red'>Referral Code is required!</Text>
                  )}
                </FormControl>
                <Button
                  type='submit'
                  id='updateButton'
                  variant='solid'
                  p='10px'
                  fontSize='1rem'
                  bgColor='brand.primary.main'
                  border='1px solid #FF824C'
                  color='#fff'
                  _active={{
                    backgroundColor: "#fff",
                    color: "rgb(255, 130, 76)",
                  }}
                  _hover={{
                    backgroundColor: "#fff",
                    color: "rgb(255, 130, 76)",
                    transform: "scale(.97)",
                    transition: ".3s ease-in-out",
                  }}
                  isLoading={isLoadingUpdateReferralCode}
                  _focus={{
                    offset: "0",
                  }}>
                  <Text>Update Referral Code</Text>
                </Button>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ReferralCodeUpdateModal;
