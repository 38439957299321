import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  FormControl,
  FormLabel,
  Stack,
  Radio,
  RadioGroup,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { CButton, Dropzone } from "..";
import { FileRejection, DropEvent } from "react-dropzone";

interface IBulkSingleForm {
  reviewReason: string;
}
interface FileUploadModalProps {
  onClose: () => void;
  isOpen: boolean;
  onSingleModalSubmit: (
    recordId: string,
    file: File,
    skiptraced: boolean,
    reviewReason: string
  ) => void;
  loadingSingleSkiptraceOutput: boolean;
  recordId: string;
}

function FileUploadModal(props: FileUploadModalProps) {
  const {
    isOpen,
    onClose,
    onSingleModalSubmit,
    loadingSingleSkiptraceOutput,
    recordId,
  } = props;
  const [file, setFile] = useState<File | null>();
  const [toggle, setToggle] = useState<boolean>(false);
  const [valued, setValued] = useState("Yes");
  const [isSkiptraced, setIsSkiptraced] = useState<boolean>(true);
  const [errors, setErrors] = useState({ file: "" });
  const [unacceptedFileError, setUnacceptedFileError] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors: formError },
    reset,
  } = useForm();

  const submit = handleSubmit((data: IBulkSingleForm) => {
    if (file) {
      onSingleModalSubmit(recordId, file!, isSkiptraced, data.reviewReason);
      setErrors({ ...errors, file: "" });
      setFile(null);
      reset();
    } else {
      setErrors({ ...errors, file: "Please attach a file!" });
    }
  });

  const handleChange = (e: any) => {
    setIsSkiptraced(e.target.value === "Yes" ? true : false);
    setValued(e.target.value);
  };

  const closeModal = () => {
    onClose();
    setErrors({ ...errors, file: "" });
    setFile(null);
  };

  const handleDrop = (
    acceptedFiles: File[],
    fileRejections?: FileRejection[],
    event?: DropEvent
  ) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      setUnacceptedFileError("");
    }
    if (fileRejections && fileRejections?.length > 0) {
      setUnacceptedFileError("Invalid File Type - File must be .xlsx or .csv");
      setFile(null);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        id="singleSkiptraceUploadModal"
        onClose={closeModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload File</ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb="25px">
            <form onSubmit={submit} id="form">
              <Stack spacing={4}>
                <Box>
                  <Dropzone onDrop={handleDrop} />
                  <Text id="unacceptedFileError" color="red.400">
                    {unacceptedFileError}
                  </Text>
                  <Text color="brand.primary.main">{file?.name}</Text>
                </Box>
                <Text color="red"> {errors.file}</Text>

                <FormControl id="records">
                  <FormLabel color="gray" fontSize="sm">
                    Is it skiptraced?
                  </FormLabel>
                  <RadioGroup pl="2" colorScheme="orange" value={valued}>
                    <Stack direction="row">
                      <Radio
                        id="yesRadio"
                        _focus={{
                          offset: "0",
                        }}
                        value="Yes"
                        onChange={handleChange}
                      >
                        Yes
                      </Radio>
                      <Radio
                        id="noRadio"
                        _focus={{
                          offset: "0",
                        }}
                        value="No"
                        onChange={handleChange}
                      >
                        No
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  <Checkbox
                    pl="2"
                    pt="2"
                    isChecked={toggle}
                    onChange={() => setToggle(!toggle)}
                    colorScheme="orange"
                    color="gray"
                  >
                    Does it need review?
                  </Checkbox>
                  {toggle && (
                    <>
                      <FormLabel color="gray" fontSize="sm" pt="1.5">
                        Why does it need review?
                      </FormLabel>
                      <Input
                        _focus={{
                          borderColor: "brand.primary.main",
                        }}
                        type="text"
                        {...register("reviewReason", {
                          required: true,
                        })}
                      />
                    </>
                  )}
                  {formError.reviewReason &&
                    formError.reviewReason.type === "required" && (
                      <Text color="red">Review Reason is required!</Text>
                    )}
                </FormControl>
                <CButton
                  type="submit"
                  variant="solid"
                  p="10px"
                  fontSize="1rem"
                  bgColor="brand.primary.main"
                  border="1px solid #FF824C"
                  color="#fff"
                  _focus={{
                    offset: "0",
                  }}
                  isLoading={loadingSingleSkiptraceOutput}
                >
                  Fulfil Order
                </CButton>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FileUploadModal;
