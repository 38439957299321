import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { CButton } from "../..";

interface TakeOrderModalProps {
  onClose: () => void;
  isOpen: boolean;
  onTakeOrder: (id: string, onClose: () => void) => void;
  id: string;
  loading: boolean;
}

export default function TakeOrderModal(props: TakeOrderModalProps) {
  const { isOpen, onClose, onTakeOrder, id, loading } = props;

  const handleTakeOrder = () => {
    onTakeOrder(id, onClose);
  };

  const closeModal = () => {
    onClose();
  };
  return (
    <>
      <Modal isOpen={isOpen} id="takeOrderModal" onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Take Order</ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb="25px">
            <Stack spacing={4} alignItems="center">
              <Text> Are you sure you want to take this order?</Text>
              <HStack>
                <CButton
                  id="takeOrderButton"
                  onClick={handleTakeOrder}
                  w="20"
                  variant="solid"
                  p="10px"
                  fontSize="14px"
                  backgroundColor="brand.primary.main"
                  border="1px solid #FF824C"
                  color="#fff"
                  _focus={{
                    offset: "0",
                  }}
                  isLoading={loading}
                >
                  Yes
                </CButton>

                <CButton
                  onClick={onClose}
                  w="20"
                  variant="outline"
                  p="10px"
                  fontSize="14px"
                  border="1px solid #FF824C"
                  color="brand.primary.main"
                  backgroundColor="#fff"
                  _focus={{
                    offset: "0",
                  }}
                >
                  No
                </CButton>
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
