import React, { useMemo } from "react";
import { Text } from "@chakra-ui/react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#51D55E",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

interface DropzoneProps {
  onDrop: (
    acceptedFiles: File[],
    fileRejections?: FileRejection[],
    event?: DropEvent
  ) => void;
}

export default function Dropzone(props: DropzoneProps) {
  const { onDrop } = props;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "",
      ".csv",
      "text/csv",
    ],
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      {/*  @ts-ignore */}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Text textAlign="center">
          Drag 'n' drop some file here, or click to select file
        </Text>
      </div>
    </div>
  );
}
