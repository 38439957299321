import { combineReducers } from "@reduxjs/toolkit";
import adminReducer from "./admin";
import customersReducer from "./customers";
import affiliatesReducer from "./affiliates";

const rootReducer = combineReducers({
  customers: customersReducer,
  admin: adminReducer,
  affiliates: affiliatesReducer,
});

export default rootReducer;
