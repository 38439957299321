import React from "react";
import { SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { IInvoicesStatsData } from "../../interfaces";

const InvoicesStats = ({
  stats,
}: {
  stats: IInvoicesStatsData | undefined;
}) => {
  const bulkFileStats = [
    {
      id: 1,
      title: "Traced Records",
      value: stats?.totalTracedRecords ? stats?.totalTracedRecords : 0,
      color: "brand.primary.main",
    },
    {
      id: 2,
      title: "Fulfilled Orders",
      value: stats?.totalFulfilledOrders ? stats?.totalFulfilledOrders : 0,
      color: "brand.primary.main",
    },
    {
      id: 3,
      title: "Money Owed To Tracers",
      value: stats?.totalMoneyOwedToTracers
        ? stats?.totalMoneyOwedToTracers
        : 0,
      color: "brand.primary.main",
    },
    {
      id: 4,
      title: "Traced Records Per Customer",
      value: stats?.totalTracedRecordsPerCustomer
        ? `$${stats?.totalTracedRecordsPerCustomer?.toFixed(2)}`
        : 0,
      color: "#51D55E",
    },
    {
      id: 5,
      title: "Revenue Generated",
      value: stats?.totalRevenueGenerated
        ? `$${stats?.totalRevenueGenerated}`
        : 0,
      color: "#F85959",
    },
    {
      id: 6,
      title: "Profit Generated",
      value: stats?.totalProfitGenerated
        ? `$${stats?.totalProfitGenerated}`
        : 0,
      color: "#51D55E",
    },
    {
      id: 7,
      title: "Average Order Completion Time",
      value: stats?.totalAverageOrderCompletionTime
        ? `${stats?.totalAverageOrderCompletionTime}h`
        : 0,
      color: "#51D55E",
    },
  ];
  return (
    <SimpleGrid w='full' columns={4} mt='30px' gap='4'>
      {bulkFileStats.map((stat) => (
        <VStack
          align='start'
          key={stat.id}
          border='1px'
          borderColor='gray.200'
          borderRadius='md'
          p='4'
          spacing={0}
          w='full'>
          <Text color='gray.500' fontSize='18px'>
            {stat?.title}
          </Text>
          <Text fontSize='35px' fontWeight='bold' color={stat.color}>
            {stat?.value}
          </Text>
        </VStack>
      ))}
    </SimpleGrid>
  );
};

export default InvoicesStats;
