import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { API } from "../../../constants";

interface Ipermission {
  Admin: boolean;
  Skiptracer: boolean;
}

interface GuardedRouteProps {
  children: JSX.Element | React.ComponentType;
  redirectTo: string;
  role: string;
  permission: Ipermission | undefined;
}

export default function Guarded(props: GuardedRouteProps) {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>();
  const { children, redirectTo, role, permission } = props;

  React.useEffect(() => {
    axios
      .get(API.API_URL, {
        headers: {
          "x-auth": localStorage.getItem("authToken"),
        },
      })
      .then(() => setIsLoggedIn(true))
      .catch(() => setIsLoggedIn(false));
  }, []);

  const checkPermission = () => {
    if (role === "Skiptracer" && permission?.Skiptracer === false) {
      return false;
    }

    return true;
  };

  if (isLoggedIn !== undefined && isLoggedIn === false) {
    return (
      <div id='redirectComp'>
        <Redirect to={redirectTo} />
      </div>
    );
  }

  if (isLoggedIn !== undefined && isLoggedIn === true && checkPermission()) {
    return <div id='GChildren'> {children} </div>;
  }

  if (isLoggedIn !== undefined && isLoggedIn === true && !checkPermission()) {
    return (
      <div id='redirectComp'>
        <Redirect to='/single-lead' />
      </div>
    );
  }

  return null;
}
