import { useMutation, useQuery } from "react-query";
import { Moment } from "moment";
import { API } from "../constants";
import { apiClient } from "../services";

export const useGetPayoutsSkiptracers = (
  fromDate?: string,
  toDate?: string
) => {
  const getPayoutsSkiptracers = async () => {
    try {
      const res = await apiClient.get(
        `${API.ROUTES.ADMIN_PAYOUTS_SKIPTRACERS}?&toDate=${toDate}&fromDate=${fromDate}`
      );
      return res?.data?.payoutsSkiptracers;
    } catch (error: any) {
      throw new Error(error);
    }
  };
  return useQuery(
    ["GetPayoutsSkiptracers", fromDate, toDate],
    getPayoutsSkiptracers
  );
};

export const useAddPayoutsSkiptracers = () => {
  const addPayoutsSkiptracers = async (body: {
    amount: number;
    skiptracer: string;
    date: string;
    payPeriodStart: Moment | null;
    payPeriodEnd: Moment | null;
  }) => {
    try {
      const res = await apiClient.post(
        API.ROUTES.ADMIN_PAYOUTS_SKIPTRACERS,
        body
      );
      return res;
    } catch (e: any) {
      console.log(e);
      throw new Error(e.response.data.errorCode);
    }
  };

  return useMutation(addPayoutsSkiptracers);
};

export const useDeletePayoutsSkiptracers = () => {
  const deletePayoutsSkiptracers = async (body: { id: string }) => {
    try {
      const res = await apiClient.delete(
        `${API.ROUTES.ADMIN_PAYOUTS_SKIPTRACERS}?id=${body.id}`
      );
      return res;
    } catch (e: any) {
      console.log(e);
      throw new Error(e.response.data.errorCode);
    }
  };

  return useMutation(deletePayoutsSkiptracers);
};
