const API_URL =
  process.env.REACT_APP_API_URL ||
  "https://skipspot-dashboard-ed10201e3466.herokuapp.com/";

const ROUTES = {
  ADMIN_LOGIN: "admin/login",
  ADMIN_FILES: "admin/files",
  ADMIN_SINGLE_SKIPTRACE: "admin/single-leads",
  ADMIN_SKIPTRACE_OUTPUT: "admin/skiptrace/output",
  ADMIN_SKIPTRACE_APPROVED: "admin/skiptrace/approved",
  ADMIN_USERS: "/admin/users",
  ADMIN_FULFILLED_ORDERS: "admin/fulfilled-orders",
  ADMIN_SKIPTRACERS: "admin/skiptracers",
  ADMIN_ME: "admin/me",
  ADMIN_APP_METRICS: "admin/app-metrics",
  ADMIN_TAKE_ORDER: "admin/take-order",
  ADMIN_CUSTOMERS: "admin/customers",
  ADMIN_UPDATE_CUSTOMER_TAGS: "admin/update-customer-tags",
  ADMIN_UPDATE_CUSTOMER_CREDITS: "admin/update-customer-balance",

  ADMIN_ADD_TAG: "admin/add-tag",
  ADMIN_GET_TAGS: "admin/get-tags",
  ADMIN_INVOICES: "admin/invoices",
  ADMIN_DOWNLOAD_INVOICES: "admin/download-invoices",
  ADMIN_DOWNLOAD_CUSTOMERS: "admin/download-customers",
  ADMIN_ADD_PROMO_CODE: "admin/add-promo-code",
  ADMIN_GET_PROMO_CODES: "admin/get-promo-codes",
  ADMIN_DOWNLOAD_PROMO_CODES: "admin/download-promo-code",
  ADMIN_DELETE_PROMO_CODE: "admin/delete-promo-code",
  AFFILIATE_UPDATE_STATUS: "admin/affiliate-status",
  AFFILIATE_GET_STATS: "admin/affiliate-stats",
  AFFILIATE_GET_TABLE_STATS: "admin/affiliate-table-stats",
  ADMIN_PAYOUTS_SKIPTRACERS: "admin/payouts-skiptracers",
  ADMIN_AFFILIATES_PAYOUTS: "admin/affiliates-payouts",
  ADMIN_AFFILIATES_CUSTOMERS_ORDERS: "admin/affiliate/customers-orders",
  ADMIN_CUSTOMER_PURCHASE_HISTORY: "admin/customer-purchase-history",
  ADMIN_CUSTOMER_LOGS: "admin/customer-logs",
  ADMIN_AVG_ORDERS_TIME: "admin/average-order-time",
  ADMIN_UPDATE_SKIPTRACER_COST: "admin/update-skiptracer-cost",
  ADMIN_DISPUTE_ORDER: "admin/dispute-order",
  ADMIN_UPDATE_AFFILIATE_REFERRAL_CODE: "admin/update-referral-code",
};

export default { API_URL, ROUTES };
