import { lazy } from "react";

export const Login = lazy(() => import("./Login"));
export const BulkTrace = lazy(() => import("./BulkTrace"));
export const SingleTrace = lazy(() => import("./SingleTrace"));
export const FulfilledOrders = lazy(() => import("./FulfilledOrders"));
export const Users = lazy(() => import("./Users"));
export const Dashboard = lazy(() => import("./Dashboard"));
export const Customers = lazy(() => import("./Customers"));
export const Invoices = lazy(() => import("./Invoices"));
export const PromoCodes = lazy(() => import("./PromoCodes"));
export const Affiliates = lazy(() => import("./Affiliates"));
export const AffiliateStats = lazy(() => import("./Affiliates/AffiliateStats"));
export const AffiliateCustomerOrders = lazy(
  () => import("./Affiliates/CustomerOrders")
);
export const PayoutsSkiptracers = lazy(() => import("./PayoutsSkiptracers"));
export const Payments = lazy(() => import("./Payments"));
export const AffiliatesPayouts = lazy(
  () => import("./Affiliates/AffiliatesPayouts")
);
export const CustomerPurchaseHistory = lazy(
  () => import("./Customers/CustomerPurchaseHistory")
);
export const CustomerLoggedInHistory = lazy(
  () => import("./Customers/CustomerLoggedInHistory")
);
export const Orders = lazy(() => import("./Orders"));
