import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import { Box, VStack, Tooltip } from "@chakra-ui/react";
import { RiBillLine } from "react-icons/ri";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { BiHomeAlt } from "react-icons/bi";
import { VscFiles, VscFile } from "react-icons/vsc";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { GiTakeMyMoney } from "react-icons/gi";
import { FiUsers } from "react-icons/fi";
import { IoReceiptOutline, IoCashOutline } from "react-icons/io5";
import { RiCoupon2Line } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { RootState } from "../../../slices";
import "./index.css";

const adminIcons = [
  {
    id: 1,
    Icon: BiHomeAlt,
    name: "Dashboard",
    to: "/dashboard",
  },
  {
    id: 2,
    Icon: VscFiles,
    name: "Bulk Trace",
    to: "/bulk-trace",
  },
  {
    id: 3,
    Icon: VscFile,
    name: "Single Lead",
    to: "/single-lead",
  },
  {
    id: 4,
    Icon: BsFileEarmarkCheck,
    name: "Fulfilled Orders",
    to: "/fulfilled-orders",
  },
  {
    id: 5,
    Icon: AiOutlineUsergroupAdd,
    name: "users",
    to: "/users",
  },
  {
    id: 6,
    Icon: FiUsers,
    name: "customers",
    to: "/customers",
  },
  {
    id: 7,
    Icon: IoReceiptOutline,
    name: "invoices",
    to: "/invoices",
  },
  {
    id: 8,
    Icon: RiCoupon2Line,
    name: "Promo Codes",
    to: "/promo-codes",
  },
  {
    id: 9,
    Icon: HiUserGroup,
    name: "Affiliates Stats",
    to: "/affiliates",
  },
  {
    id: 10,
    Icon: IoCashOutline,
    name: "Payouts Skiptracers",
    to: "/payouts-skiptracers",
  },

  {
    id: 11,
    Icon: GiTakeMyMoney,
    name: "Affiliate Payouts",
    to: "/affiliates-payouts",
  },
];

const skiptracerIcons = [
  {
    id: 1,
    Icon: VscFiles,
    name: "Bulk Trace",
    to: "/bulk-trace",
  },
  {
    id: 2,
    Icon: VscFile,
    name: "Single Lead",
    to: "/single-lead",
  },
  {
    id: 3,
    Icon: BsFileEarmarkCheck,
    name: "Fulfilled Orders",
    to: "/fulfilled-orders",
  },
  {
    id: 4,
    Icon: RiBillLine,
    name: "Payments",
    to: "/payments",
  },
];

export default function Sidebar() {
  const { currentUser } = useSelector((state: RootState) => state.admin);
  const match = useRouteMatch();

  return (
    <VStack spacing="7" justifyContent="center" h="100vh">
      {currentUser?.role === "Admin"
        ? adminIcons.map(({ Icon, name, id, to }) => (
            <Box key={id} className="iconTranslate">
              <Tooltip
                className="iconTranslate"
                bgColor="brand.primary.main"
                label={name}
                placement="right"
              >
                <Link to={to}>
                  <Icon
                    color={to === match.url ? "#FF824C" : "#737373"}
                    fontSize="25px"
                  />
                </Link>
              </Tooltip>
            </Box>
          ))
        : skiptracerIcons.map(({ Icon, name, id, to }) => (
            <Box key={id}>
              <Tooltip
                hasArrow
                bgColor="brand.primary.main"
                label={name}
                placement="right"
              >
                <Link to={to}>
                  <Icon
                    color={to === match.url ? "#FF824C" : "#737373"}
                    fontSize="25px"
                  />
                </Link>
              </Tooltip>
            </Box>
          ))}
    </VStack>
  );
}
