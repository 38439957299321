import { Box, Flex, Text } from "@chakra-ui/react";

interface StatsCardProps {
  name: string;
  value: string | number;
}

const StatsCard = ({ name, value }: StatsCardProps) => {
  return (
    <Box
      w="full"
      align="start"
      border="1px"
      borderColor="gray.100"
      p="3"
      rounded="xl"
      shadow="sm"
      className="pattern"
    >
      <Flex flexDirection="column">
        <Text color="gray.400">{name}</Text>
        <Text
          fontSize="2xl"
          textAlign="center"
          fontWeight="bold"
          color="brand.primary.main"
        >
          {value}
        </Text>
      </Flex>
    </Box>
  );
};

export default StatsCard;
