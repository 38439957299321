export const csvFile = (data: any, fileName: string) => {
  const url = window.URL.createObjectURL(data);
  var a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}-${new Date().toDateString()}.csv`;
  document.body.append(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};
