import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  HStack,
  Avatar,
  Text,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  VStack,
} from "@chakra-ui/react";
import { RiArrowDownSLine } from "react-icons/ri";
import { IoMdPower } from "react-icons/io";
import { RootState } from "../../../slices";

export default function Header() {
  const { currentUser } = useSelector((state: RootState) => state.admin);

  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("authToken");
    history.push("/login");
  };
  return (
    <Box w="100%" p="15px">
      <HStack justify="flex-end">
        <Avatar name="Dan Abrahmov" src="https://bit.ly/dan-abramov" />

        <Menu id="dropdown-menu">
          <MenuButton
            id="dropdown-btn"
            // onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <VStack align="start" spacing={0}>
              <Text color="gray.400" fontSize="14px">
                user
              </Text>
              <HStack spacing={0}>
                <Text fontWeight="600" fontStyle="normal">
                  {currentUser && currentUser?.role}
                </Text>
                <RiArrowDownSLine fontSize="18px" fontWeight="600" />
              </HStack>
            </VStack>
          </MenuButton>
          <MenuList mt="10px" minWidth="140px">
            <MenuItem
              onClick={logout}
              icon={<IoMdPower fontSize="18px" />}
              color="#737373"
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Box>
  );
}
