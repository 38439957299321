import { useMutation, useQuery } from "react-query";
import { API } from "../constants";
import { apiClient } from "../services";

export const useDeleteSkiptraceOutput = () => {
  const uploadFile = async (body: { recordId: string; type: string }) => {
    const res = await apiClient.patch(API.ROUTES.ADMIN_SKIPTRACE_OUTPUT, body);
    return res;
  };
  return useMutation(uploadFile);
};

export const useApprvoedSkiptrace = () => {
  const apprvoedSkiptrace = async (body: {
    recordId: string;
    type: string;
  }) => {
    const res = await apiClient.patch(
      API.ROUTES.ADMIN_SKIPTRACE_APPROVED,
      body
    );
    return res;
  };
  return useMutation(apprvoedSkiptrace);
};

export const useGetFulfilledSkiptrace = (
  fromDate?: string,
  toDate?: string,
  type?: string,
  email?: string,
  saleType?: string,
  userName?: string
) => {
  const getFulfilledSkiptraceRecords = async () => {
    const { data } = await apiClient.get(
      `${API.ROUTES.ADMIN_FULFILLED_ORDERS}?&toDate=${toDate}&fromDate=${fromDate}&type=${type}&email=${email}&saleType=${saleType}&userName=${userName}`
    );
    return data;
  };

  return useQuery(
    ["FulfilledSkipTraceRecords", fromDate, toDate, type, email, saleType],
    getFulfilledSkiptraceRecords
  );
};

export const useGetSkiptracers = () => {
  const getSkiptracersData = async () => {
    const { data } = await apiClient.get(API.ROUTES.ADMIN_SKIPTRACERS);
    return data.skiptracers;
  };

  return useQuery("SkipTracersRecords", getSkiptracersData);
};

export const useTakeOrderSkiptrace = () => {
  const takeOrderSkiptrace = async (body: {
    recordId: string;
    type: string;
  }) => {
    const res = await apiClient.post(API.ROUTES.ADMIN_TAKE_ORDER, body);
    return res;
  };
  return useMutation(takeOrderSkiptrace);
};

export const useUpdateSkiptracerCost = () => {
  const updateSkiptracerCost = async (body: {
    recordId: string;
    type: string;
    skiptracerCost: number;
  }) => {
    const res = await apiClient.post(
      API.ROUTES.ADMIN_UPDATE_SKIPTRACER_COST,
      body
    );
    return res;
  };
  return useMutation(updateSkiptracerCost);
};
