import { Link } from "react-router-dom";
import {
  VStack,
  SimpleGrid,
  Spinner,
  Flex,
  Text,
  Button,
  HStack,
} from "@chakra-ui/react";
import moment, { Moment } from "moment";
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";
import StatsCard from "./StatsCard";
import { CButton, DatePicker } from "..";

interface CAffiliatesProps {
  statsData:
    | {
        to: string;
        name: string;
        value: string | number;
      }[]
    | [];
  isLoading: boolean;
  handleOnDataPicker: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  startDate: Moment | null;
  endDate: Moment | null;
  onClearFilters: () => void;
  userId: string | null;
}

const CAffiliates = ({
  statsData,
  isLoading,
  handleOnDataPicker,
  startDate,
  endDate,
  onClearFilters,
  userId,
}: CAffiliatesProps) => {
  const formatedData = statsData?.map((stat) => {
    return [stat.name, stat.value];
  });

  return (
    <VStack w="full" alignItems="flex-start">
      <Text fontWeight="500" fontStyle="normal" fontSize="30px" color="#393330">
        Affiliates Stats
      </Text>
      <HStack pt="5" pb="2" justifyContent="space-between" w="full">
        {formatedData?.length ? (
          <CSVLink
            headers={["Name", "Value"]}
            data={formatedData}
            filename={`affiliate-stats-${moment().format(
              "MMMM Do YYYY, h:mm:ss a"
            )}`}
          >
            <Button
              size="sm"
              rightIcon={<BsDownload />}
              id="downloadBtn"
              colorScheme="orange"
              bgColor="brand.primary.main"
            >
              Download as CSV
            </Button>
          </CSVLink>
        ) : null}
        <HStack justifyContent="flex-end" w="full">
          <DatePicker
            handleOnDataPicker={handleOnDataPicker}
            fromDate={startDate}
            toDate={endDate}
          />
          <CButton
            onClick={onClearFilters}
            id="clearbutton"
            variant="outline"
            size="sm"
            w="28"
            fontSize="16px"
            bgColor="#fff"
            border="1px solid rgb(255, 130, 76)"
            color="rgb(255, 130, 76)"
            _focus={{
              offset: "0",
            }}
          >
            Clear
          </CButton>
        </HStack>
      </HStack>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" minH="44" w="full">
          <Spinner thickness="3px" size="lg" color="brand.primary.main" />
        </Flex>
      ) : (
        <SimpleGrid columns={4} w="full" gap="4">
          {statsData?.map((stat) => (
            <Link
              to={
                userId && stat.name === "Sign Ups"
                  ? `/affiliate/${userId}/customers-orders`
                  : stat.to
              }
            >
              <StatsCard name={stat.name} value={stat.value} />
            </Link>
          ))}
        </SimpleGrid>
      )}
    </VStack>
  );
};

export default CAffiliates;
