import React from "react";
import { Spinner as CSpinner, Flex } from "@chakra-ui/react";

export default function Spinner() {
  return (
    <Flex justifyContent="center" alignItems="center" h="100vh" w="100vw">
      <CSpinner thickness="3px" size="xl" color="brand.primary.main" />
    </Flex>
  );
}
