import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import { CButton } from "..";
import { useForm } from "react-hook-form";

interface IUpdateSkiptraceCostForm {
  skiptracerCost: number;
}

interface SkiptracerCostUpdateModalProps {
  onClose: () => void;
  isOpen: boolean;
  type: string;
  recordId: string;
  skiptracerCost: number;
  updateSkiptracerCost: (
    recordId: string,
    type: string,
    skiptracerCost: number
  ) => void;
  isLoadingUpdateSkiptracerCost: boolean;
}

function SkiptracerCostUpdateModal(props: SkiptracerCostUpdateModalProps) {
  const {
    isOpen,
    onClose,
    recordId,
    type,
    skiptracerCost,
    updateSkiptracerCost,
    isLoadingUpdateSkiptracerCost,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const submit = handleSubmit((data: IUpdateSkiptraceCostForm) => {
    updateSkiptracerCost(recordId, type, data.skiptracerCost);
  });

  const closeModal = () => {
    onClose();
    reset();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        id='updateSkiptracerCostModal'
        onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Skiptracer Cost </ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb='25px'>
            <form onSubmit={submit} id='bulk-upload-form'>
              <Stack spacing={4}>
                <FormControl id='skiptracerCost'>
                  <FormLabel color='gray' fontSize='sm'>
                    Skiptracer Cost
                  </FormLabel>
                  <Input
                    _focus={{
                      borderColor: "brand.primary.main",
                    }}
                    type='text'
                    defaultValue={skiptracerCost}
                    {...register("skiptracerCost", {
                      required: true,
                    })}
                  />

                  {errors.skiptracerCost && (
                    <Text color='red'>Skiptracer cost is required!</Text>
                  )}
                </FormControl>
                <CButton
                  type='submit'
                  id='updateButton'
                  variant='solid'
                  p='10px'
                  fontSize='1rem'
                  bgColor='brand.primary.main'
                  border='1px solid #FF824C'
                  color='#fff'
                  isLoading={isLoadingUpdateSkiptracerCost}
                  _focus={{
                    offset: "0",
                  }}>
                  <Text>Update Skiptracer Cost</Text>
                </CButton>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SkiptracerCostUpdateModal;
