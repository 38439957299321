import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  Box,
  Input,
} from "@chakra-ui/react";
import { CButton } from "../../components";
import RadioGroup from "./RadioGroup";

interface TakeOrderModalProps {
  onDiscountChange: (value: string) => void;
  onOrderTypeChange: (value: string) => void;
  onClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
  loading: boolean;
}

const orderTypeOptions = [
  { key: "1st Order", value: "1st order" },
  { key: "Any Order", value: "any order" },
  { key: "2nd Order", value: "2nd order" },
];

export default function TakeOrderModal({
  isOpen,
  onClose,
  onSubmit,
  loading,
  onOrderTypeChange,
  onDiscountChange,
}: TakeOrderModalProps) {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} id="takeOrderModal" onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="brand.primary.main">
            Select Promo codes details
          </ModalHeader>
          <ModalCloseButton
            _focus={{
              color: "brand.primary.main",
            }}
          />
          <ModalBody pb="25px">
            <VStack spacing={4} alignItems="flex-start">
              <Text>Enter discounted percentage?</Text>
              <Box pl="4" w="full">
                <Input
                  w="full"
                  required={true}
                  onChange={(e) => onDiscountChange(e.target.value)}
                  _focus={{
                    boxShadow: "none",
                  }}
                  type="number"
                />
              </Box>

              <Text pt="3">Choose order type?</Text>
              <Box pl="4">
                <RadioGroup
                  onChange={onOrderTypeChange}
                  options={orderTypeOptions}
                />
              </Box>
              <HStack pt="4" w="full" justifyContent="flex-end">
                <CButton
                  onClick={onSubmit}
                  id="takeOrderButton"
                  w="20"
                  variant="solid"
                  p="10px"
                  fontSize="14px"
                  backgroundColor="brand.primary.main"
                  border="1px solid #FF824C"
                  color="#fff"
                  _focus={{
                    offset: "0",
                  }}
                  isLoading={loading}
                >
                  Save
                </CButton>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
