import React from "react";
import { Box, Text } from "@chakra-ui/react";

interface CardProps {
  value: number | string;
  title: string;
  icon: React.ReactElement;
}

const Card = ({ icon, title, value }: CardProps) => {
  return (
    <Box
      pos="relative"
      className="pattern"
      py="3"
      borderWidth="thin"
      borderColor="gray.50"
      textAlign="center"
      shadow="sm"
      rounded="md"
    >
      <Box pos="absolute" right="2" top="2" color="brand.primary.main">
        {icon}
      </Box>
      <Text
        fontWeight="500"
        fontStyle="normal"
        fontSize="30px"
        color="brand.primary.main"
      >
        {value ? value : 0}
      </Text>
      <Text fontWeight="500" fontStyle="normal" color="gray.900">
        {title}
      </Text>
    </Box>
  );
};

export default Card;
