import {
  Login,
  BulkTrace,
  SingleTrace,
  FulfilledOrders,
  Users,
  Dashboard,
  Customers,
  Invoices,
  PromoCodes,
  Affiliates,
  AffiliateStats,
  PayoutsSkiptracers,
  AffiliatesPayouts,
  CustomerPurchaseHistory,
  CustomerLoggedInHistory,
  Orders,
  Payments,
  AffiliateCustomerOrders,
} from "../containers";

export const routes = [
  {
    exact: false,
    path: "/login",
    Comp: Login,
    guarded: false,
  },
  {
    exact: false,
    path: "/dashboard",
    Comp: Dashboard,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },
  {
    exact: false,
    path: "/bulk-trace",
    Comp: BulkTrace,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: true,
    },
  },
  {
    exact: false,
    path: "/single-lead",
    Comp: SingleTrace,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: true,
    },
  },
  {
    exact: false,
    path: "/fulfilled-orders",
    Comp: FulfilledOrders,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: true,
    },
  },
  {
    exact: false,
    path: "/users",
    Comp: Users,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },
  {
    exact: true,
    path: "/customers",
    Comp: Customers,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },
  {
    exact: false,
    path: "/invoices",
    Comp: Invoices,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },
  {
    exact: false,
    path: "/promo-codes",
    Comp: PromoCodes,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },
  {
    exact: false,
    path: "/affiliates",
    Comp: Affiliates,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },
  {
    exact: false,
    path: "/affiliate-stats",
    Comp: AffiliateStats,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },
  {
    exact: false,
    path: "/affiliate/:affiliateId/customers-orders",
    Comp: AffiliateCustomerOrders,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },
  {
    exact: false,
    path: "/payouts-skiptracers",
    Comp: PayoutsSkiptracers,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },

  {
    exact: false,
    path: "/affiliates-payouts",
    Comp: AffiliatesPayouts,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: true,
    },
  },

  {
    exact: true,
    path: "/customers/customer-purchase-history",
    Comp: CustomerPurchaseHistory,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: true,
    },
  },
  {
    exact: true,
    path: "/customers/customer-log-history",
    Comp: CustomerLoggedInHistory,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: true,
    },
  },

  {
    exact: false,
    path: "/orders-detail",
    Comp: Orders,
    guarded: true,
    permission: {
      Admin: true,
      Skiptracer: false,
    },
  },

  {
    exact: false,
    path: "/payments",
    Comp: Payments,
    guarded: true,
    permission: {
      Admin: false,
      Skiptracer: true,
    },
  },
];
