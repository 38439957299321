export const adminTableHeadings = [
  "Date",
  "Customer Name",
  "Total Records",
  "SkipTraced Records",
  "Type",
  "Status",
  "Input File",
  "Output File",
  "Cost",
  "Affiliate Commission",
  "Affiliate Name",
  "Skiptracer Cost",
  "Fufilled By",
  "Profit",
  "Margin",
];
export const skiptracerTableHeadings = [
  "Date",
  "Customer Name",
  "Total Records",
  "SkipTraced Records",
  "Type",
  "Status",
  "Input File",
  "Output File",
  "Money Owed",
  "Affiliate Commission",
  "Affiliate Name",
];
