import React from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { CreatableSelect, OptionBase, GroupBase } from "chakra-react-select";
import { TagsOptionsProps } from "../../../interfaces";

interface TagsOption extends OptionBase {
  label: string;
  value: string;
}

type CSelectProps = {
  onChange: (options: any) => void;
  defaultValue: TagsOptionsProps["tags"];
  options: TagsOptionsProps["tags"];
};

const CSelect = ({ onChange, defaultValue, options }: CSelectProps) => (
  <FormControl>
    <FormLabel>Update Tags</FormLabel>
    <CreatableSelect<TagsOption, true, GroupBase<TagsOption>>
      isMulti
      options={options}
      placeholder="Select"
      closeMenuOnSelect={false}
      onChange={(e) => onChange(e)}
      defaultValue={defaultValue}
      focusBorderColor="brand.primary.light"
      colorScheme="brand.orange"
    />
  </FormControl>
);

export default CSelect;
